/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button = require('./Button');
const eventBus = require('../utils/EventBus');
const defaultInsights = require('../utils/defaultInsights');
const RestoreInsightsSVG = require('../../assets/restoreInsights.svg');
const AccessControlAPI = require('../services/api/accesscontrol');

const {
  FETCH_USER_GROUPS,
  UPDATE_SELECTED_CATEGORY,
  FETCH_INSIGHTS,
  UPDATE_INSIGHT,
  TOAST,
  SUCCESS,
  ERROR,
} = require('../events');

const accessControlService = new AccessControlAPI();

class Insights extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this.cache = null;
  }

  set selectedGroup(newSelectedGroup) {
    this.setAttribute('selectedGroup', newSelectedGroup);
  }

  get selectedGroup() {
    return this.getAttribute('selectedGroup');
  }

  set restoredGroup(newRestoredGroup) {
    this.setAttribute('restoredGroup', newRestoredGroup);
  }

  get restoredGroup() {
    return this.getAttribute('restoredGroup');
  }

  static get observedAttributes() {
    return ['selectedgroup', 'insights', 'restoredgroup'];
  }

  attachEvents() {
    const saveButton = this.shadowRoot.querySelector('.save');
    const restoreButton = this.shadowRoot.querySelector('.restore-button');

    if (saveButton) {
      saveButton.addEventListener('click', () => {
        if (this.cache) {
          const updatedGroups = Object.values(this.cache).filter(group => group.updated);

          if (updatedGroups.length) {
            try {
              updatedGroups.forEach(this.saveInsightsRequest);

              eventBus.fire(TOAST, {
                items: [
                  {
                    type: SUCCESS,
                    message: 'Update saved',
                  },
                ],
              });
            } catch (error) {
              eventBus.fire(TOAST, {
                items: [
                  {
                    type: ERROR,
                    message: error,
                  },
                ],
              });
            }
          }
        }
      });
    }

    if (restoreButton) {
      restoreButton.addEventListener('click', () => {
        try {
          if (this.cache && this.cache[this.selectedGroup].insights) {
            this.cache = {
              ...this.cache,
              [this.selectedGroup]: { ...this.cache[this.selectedGroup], insights: defaultInsights, updated: true },
            };
            this.restoredGroup = true;
          }
        } catch (error) {
          throw new Error(error);
        } finally {
          this.restoredGroup = false;
        }
      });
    }
  }

  async connectedCallback() {
    this.listenForEvents(
      [
        { event: FETCH_USER_GROUPS, key: 'ugroups' },
        { event: FETCH_INSIGHTS, key: 'insights' },
      ],
      eventBus,
    );

    eventBus.fire(FETCH_USER_GROUPS, {
      data: await accessControlService.userGroups().get({ parameters: { enabled: true } }),
    });

    eventBus.register(UPDATE_SELECTED_CATEGORY, async ({ detail }) => {
      this._data.insights = null;
      this.selectedGroup = detail.categoryId;

      eventBus.fire(FETCH_INSIGHTS, {
        data: await accessControlService.userGroups().getInsights({ id: this.selectedGroup }),
      });
    });

    eventBus.register(UPDATE_INSIGHT, ({ detail }) => {
      const { id, enabled } = detail;

      this.updateInsightsStore({ id, enabled });
    });
  }

  styles() {
    return `
      .header-container {
        display: flex;
        justify-content: space-between;
      }

      .save {
        width: 100px;
        height: 45px;
        background-color: #007AFF;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .restore-section {
        display: flex;
        align-items: center;
      }

      span {
        font-size: 14px;
        margin-right: 10px;
      }

      exo-button {
        position: relative;
        padding-top: 0;
      }

      svg {
        position: absolute;
        left: 4px;
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
        cursor: pointer;
      }

      .card-container {
        display: flex;
      }

      .card-content {
        height: 80vh;
        display: flex;
        flex-direction: column;
      }

      exo-card {
        width: 25%;
        margin-right: 2%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      ul {
        overflow: auto;
      }
    `;
  }

  storeData() {
    if (!this.cache) {
      this.storeGroups();
    } else if (this.selectedGroup && this._data.insights && !this.cache[this.selectedGroup].insights) {
      this.storeInsights();
    }
  }

  storeGroups() {
    if (this._data && this._data.ugroups) {
      this._data.ugroups.forEach(
        group =>
          (this.cache = {
            ...this.cache,
            [group.id]: { id: group.id, name: group.name, insights: null, updated: false },
          }),
      );
    }
  }

  storeInsights() {
    this.cache = { ...this.cache, [this.selectedGroup]: { ...this.cache[this.selectedGroup], insights: {} } };

    this._data.insights.forEach(insight => {
      this.cache = {
        ...this.cache,
        [this.selectedGroup]: {
          ...this.cache[this.selectedGroup],
          insights: { ...this.cache[this.selectedGroup].insights, [insight.id]: insight },
        },
      };
    });
  }

  updateInsightsStore({ id, enabled }) {
    this.cache = {
      ...this.cache,
      [this.selectedGroup]: {
        ...this.cache[this.selectedGroup],
        insights: {
          ...this.cache[this.selectedGroup].insights,
          [id]: { ...this.cache[this.selectedGroup].insights[id], enabled },
        },
        updated: true,
      },
    };
  }

  async saveInsightsRequest(group) {
    const insightsArray = Object.values(group.insights);
    const updatedInsights = insightsArray.map(insight => ({
      name: insight.name,
      enabled: insight.enabled,
    }));

    await accessControlService.userGroups().updateInsights({ id: group.id, payload: { insights: updatedInsights } });
  }

  renderExoCard({ card, title, items, selectedItem }) {
    return `
      <exo-card>
        <div class="card-content">
          <div class="head-section">
            <exo-card-title placeholderTitle="Select" title="${title}"></exo-card-title>
            ${
              card === 'insights'
                ? `
                    <div class="restore-section">
                      <span>Restore Default</span>
                      <exo-button class="restore-button">
                        <svg width="21" height="19">
                          <image xlink:href=${RestoreInsightsSVG} />
                        </svg>
                      </exo-button>
                    </div>
                  `
                : ''
            }
          </div>
          ${card === 'insights' ? `<div class="divider"><span>Enabled</span></div>` : ''}
          <ul class="items">
            ${items
              .map(
                item =>
                  `
                    <exo-card-list-item 
                      operation="view" 
                      ${card === 'ugroups' ? `clickable="true" viewOnly` : ''} 
                      card="${card}" 
                      id="${item.id}"
                      selectedItem="${selectedItem}"
                      page="insights"
                      ${card === 'insights' ? `enabled="${item.enabled}"` : ''}
                    >
                      ${item.name}
                    </exo-card-list-item>
                  `,
              )
              .join('')}
          </ul>
        </div>
      </exo-card>
    `;
  }

  render() {
    this.storeData();

    return `
      <div>
        <div class="header-container">
          <div class="title">Insights</div>
          <button class="save">Save</button>
        </div>
        <div class="card-container">
          ${
            this._data && this._data.ugroups
              ? this.renderExoCard({
                  card: 'ugroups',
                  title: 'User Group',
                  items: this._data.ugroups,
                  selectedItem: this.selectedGroup,
                })
              : ''
          }
          ${
            this._data && this._data.ugroups && this.selectedGroup && this._data.insights
              ? this.renderExoCard({
                  card: 'insights',
                  title: 'Insights',
                  items: Object.values(this.cache[this.selectedGroup].insights),
                })
              : ''
          }
        </div>
      </div>
    `;
  }
}

customElements.define('insights-page', Insights);
