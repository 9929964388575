const APIService = require('../APIService');
const formatError = require('../../../utils/formatError');

class UltrasoundSystems extends APIService {
  constructor() {
    super();
    this._url = `/clinic-definition/ultrasound-systems`;
  }

  async get({ id }) {
    try {
      const { data } = await this.httpClient.get(`${this._url}${id ? `/${id}` : ''}`);
      return data.results;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async patch({ id, payload }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/${id}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async create({ payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async delete({ id }) {
    try {
      const { data } = await this.httpClient.delete(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }
}

module.exports = UltrasoundSystems;
