const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');

const eventBus = require('../../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();

const { FETCH_TAGS, ERROR, TOAST, SUCCESS } = require('../../events');

module.exports = async ({ tag }) => {
  try {
    await clinicDefinitionService.globalTags().create({
      payload: {
        tag,
      },
    });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Tag Created',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_TAGS, {
    data: await clinicDefinitionService.globalTags().getAll(),
  });
};
