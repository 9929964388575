const AccessControlAPI = require('../../services/api/accesscontrol');
const eventBus = require('../../utils/EventBus');

const accessControlService = new AccessControlAPI();
const { FETCH_LDAP_GROUP_USERS, FETCH_ALL_USERS, ERROR, TOAST, SUCCESS } = require('../../events');

module.exports = async ({ email, password, username, groupId, lastName, firstName, specialtyId }) => {
  try {
    const user = await accessControlService
      .userGroups({
        groupId,
      })
      .users()
      .create({
        payload: {
          username,
          email,
          password,
          lastName,
          firstName,
        },
      });
    if (user) {
      await accessControlService
        .userGroups({ groupId })
        .users()
        .addToGroup({ specialtyId, id: user.id });
      eventBus.fire(FETCH_ALL_USERS, {
        data: await accessControlService
          .userGroups()
          .users()
          .getAll(),
      });
      eventBus.fire(FETCH_LDAP_GROUP_USERS, {
        data: await accessControlService
          .userGroups({ groupId })
          .users()
          .get({ parameters: { specialtyId } }),
      });
    }
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'User Created',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error,
        },
      ],
    });
  }
};
