const eventBus = require('../../utils/EventBus');
const { UPDATE_SELECTED_EXAM_TYPE, FETCH_BILLING_CODES } = require('../../events');
const { ITEMS_PER_PAGE } = require('../../utils/constants');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');

const clinicDefinitionAPI = new ClinicDefinitionAPI();

module.exports = async ({ id }) => {
  eventBus.fire(UPDATE_SELECTED_EXAM_TYPE, {
    examTypeId: id,
  });
  eventBus.fire(FETCH_BILLING_CODES, {
    data: await clinicDefinitionAPI
      .examTypes({ examTypeId: id })
      .billingCodes()
      .getAll({ parameters: { limit: ITEMS_PER_PAGE } }),
  });
};
