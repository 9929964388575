/* eslint max-lines: 0 */
const BaseService = require('./base');

class FacilitiesAPI {
  constructor() {
    this._baseService = new BaseService();
  }

  get baseService() {
    return this._baseService;
  }

  async getAccessControl({ id }) {
    return await this._baseService.httpClient({
      method: 'GET',
      url: `/access-control/users/${id}`,
    });
  }

  getModels() {
    return ['GE Venue 2100', 'GE Logiq e', 'Mindray TE 7', 'Zonare Z23', 'Philips Sparq', 'Exo Iris'];
  }

  getHours() {
    return [4, 6, 12, 24, 48];
  }
}

module.exports = FacilitiesAPI;
