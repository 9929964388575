const BaseComponent = require('./Base');
const { PAGINATE } = require('../events');
const { ITEMS_PER_PAGE } = require('../utils/constants');

const eventBus = require('../utils/EventBus');
class HorizontalCard extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
    this.paginationLimit = ITEMS_PER_PAGE;
  }

  get page() {
    return this.getAttribute('page');
  }

  set page(newPage) {
    this.setAttribute('page', newPage);
  }

  get pagination() {
    return this.getAttribute('pagination');
  }

  set pagination(newPagination) {
    this.setAttribute('pagination', newPagination);
  }

  get totalCount() {
    return this.getAttribute('totalCount');
  }

  set totalCount(newtotalCount) {
    this.setAttribute('totalCount', newtotalCount);
  }

  get itemsCount() {
    return this.getAttribute('itemsCount');
  }

  set itemsCount(newItemsCount) {
    this.setAttribute('itemsCount', newItemsCount);
  }

  connectedCallback() {
    if (this.pagination == '') {
      const totalCount = parseInt(this.totalCount);
      const itemsCount = parseInt(this.itemsCount);

      if (itemsCount < totalCount) {
        this.querySelector('.paginated').addEventListener(
          'scroll',
          e => {
            const paginatedList = e.target;
            if (paginatedList.offsetHeight + paginatedList.scrollTop >= paginatedList.scrollHeight) {
              eventBus.fire(PAGINATE, {
                limit: this.paginationLimit,
                listPosition: e.target.scrollTop,
              });
            }
          },
          true,
        );
      }
    }
  }

  getMaxWidth(page) {
    switch (page) {
      case 'qa':
        return '90';
      case 'interfaces':
        return '100';
      case 'cpt':
        return '60';
      case 'members':
        return '100';
      default:
        return '60';
    }
  }

  styles() {
    return `
      .card {
        background-color: white;
        box-shadow: 0px 0px 10px rgba(74, 74, 139, 0.100524);
        border-radius: 5px;
        max-width: ${this.getMaxWidth(this.page)}%;
        min-height: 100px;
        position: relative;
        height: ${this.page === 'cpt' ? '80vh' : 'auto'};
        padding: ${this.page === 'cpt' ? '0 0 10px 0' : '1% 0 1% 0'};
      }
    `;
  }

  render() {
    return `
      <div class="card">
        <slot></slot>
      <div>
    `;
  }
}

customElements.define('horizontal-card', HorizontalCard);
