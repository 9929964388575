const UserAPI = require('../../services/api/user');
const verifyUser = require('./verifyUser');

const userAPI = new UserAPI();

module.exports = {
  verifyUser: ({ token }) => {
    const bearerToken = `Bearer ${token}`;
    userAPI.baseService.setAuthToken({ token: bearerToken });
    return verifyUser({ APIClient: userAPI, token: bearerToken });
  },
};
