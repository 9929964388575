/* eslint-disable no-console */
/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button = require('./Button');

const {
  UPDATE_CRUD_OPERATION,
  TOGGLE_MODAL,
  TOGGLE_CONTEXTUAL_MENU,
  PAGINATE,
  PAGINATE_MEMBERS_USERS,
  PAGINATE_CREDENTIALS_USERS,
  PAGINATE_QA_USERS,
} = require('../events');
const eventBus = require('../utils/EventBus');
const TrashSVG = require('../../assets/trash.svg');
const EditSVG = require('../../assets/edit.svg');
const radioOffSVG = require('../../assets/radioOff.svg');
const radioOnSVG = require('../../assets/radioOn.svg');
const dropDownSVG = require('../../assets/dropdown.svg');
const blueArrow = require('../../assets/blueArrow.svg');
const whiteArrow = require('../../assets/whiteArrow.svg');
const clickCardItem = require('../operations/ui/clickCardItem');
const changeSelectBoxOnCard = require('../operations/ui/changeSelectBoxOnCard');
const permissionsMap = require('../utils/permissionsMap');
const isSafari = require('../utils/isSafari');
const { ITEMS_PER_PAGE } = require('../utils/constants');

class Card extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
    this.paginationLimit = ITEMS_PER_PAGE;
    this.scrollFn = this.scroll.bind(this);
  }

  get page() {
    return this.getAttribute('page');
  }

  set page(newPage) {
    this.setAttribute('page', newPage);
  }

  get pagination() {
    return this.getAttribute('pagination');
  }

  set pagination(newPagination) {
    this.setAttribute('pagination', newPagination);
  }

  get totalCount() {
    return this.getAttribute('totalCount');
  }

  set totalCount(newtotalCount) {
    this.setAttribute('totalCount', newtotalCount);
  }

  get itemsCount() {
    return this.getAttribute('itemsCount');
  }

  set itemsCount(newItemsCount) {
    this.setAttribute('itemsCount', newItemsCount);
  }

  getPaginateAction() {
    switch (this.page) {
      case 'members':
        return PAGINATE_MEMBERS_USERS;
      case 'credentials':
        return PAGINATE_CREDENTIALS_USERS;
      case 'QA':
        return PAGINATE_QA_USERS;
      default:
        return PAGINATE;
    }
  }

  connectedCallback() {
    if (this.pagination == '') {
      const totalCount = parseInt(this.totalCount);
      const itemsCount = parseInt(this.itemsCount);

      if (itemsCount < totalCount) {
        this.querySelector('.paginated').addEventListener('scroll', this.scrollFn, true);
      }
    }
  }

  disconnectedCallback() {
    if (this.querySelector('.paginated')) {
      this.querySelector('.paginated').removeEventListener('scroll', this.scrollFn, true);
    }
  }

  scroll(e) {
    if (e.target.offsetHeight + e.target.scrollTop + 5 >= e.target.scrollHeight) {
      eventBus.fire(this.getPaginateAction(), {
        limit: this.paginationLimit,
        listPosition: e.target.scrollTop,
      });
    }
  }

  getHeight() {
    switch (this.page) {
      case 'QA':
        return '530px';
      case 'credentials':
        return '530px';
      case 'members':
        return '70vh';
      default:
        return '100%';
    }
  }

  styles() {
    return `
      .card {
        background-color: white;
        box-shadow: 0px 0px 10px rgba(74, 74, 139, 0.100524);
        border-radius: 5px;
        height: ${this.page === 'tags' ? 'auto' : this.getHeight()};
        padding-bottom: ${this.page === 'groups' ? '' : '10px'};
      }
    `;
  }

  render() {
    return `
      <div class="card">
        <slot></slot>
      <div>
    `;
  }
}

class CardTitle extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
  }

  get title() {
    return this.getAttribute('title');
  }

  set title(newTitle) {
    this.setAttribute('title', newTitle);
  }

  get placeholderTitle() {
    return this.getAttribute('placeholderTitle') || 'Select your';
  }

  set placeholderTitle(newPlaceholderTitle) {
    this.setAttribute('placeholderTitle', newPlaceholderTitle);
  }

  styles() {
    return `
      .pre {
        color: grey;
        font-size: 16px;
      }

      .title {
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.266667px;
        font-weight: bold;
      }
    `;
  }

  render() {
    return `<div>
      <div class="pre"> ${this.placeholderTitle} </div>
      <div class="title">${this.title}</div>
    </div>`;
  }
}

class CardButton extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this.attachEvents();
  }

  styles() {
    return `
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: none;
        height: 30px;
        width: 30px;
      }

      button:last-child {
        margin-left: 2%;
      }

      button:hover {
        cursor: pointer;
      }

      .hover-menu {
        position: absolute;
        list-style: none;
        text-align: left;
        right: -19px;
        bottom: ${this.card === 'examTypeCPT' ? '70' : '40'}px;
        padding: 0;
      }

      .pill {
        background: #FFFFFF;
        padding: 15% 15%;
        margin-bottom: 9%;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.102601);
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: #0B0B0B;
      }
    `;
  }

  static get observedAttributes() {
    return ['open', 'isCPTEnabled'];
  }

  connectedCallback() {
    eventBus.register(TOGGLE_CONTEXTUAL_MENU, ({ detail }) => {
      if (this.settings && detail.card !== this.card) {
        this.open = false;
      }
    });
  }

  attachEvents() {
    this.shadowRoot.querySelector('button').addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      if (this.withMenu) {
        if (this.operation === 'view') {
          if (this.settings) {
            eventBus.fire(TOGGLE_CONTEXTUAL_MENU, {
              card: this.card,
            });
            this.open = !this.open;
          }
          if (this.open) {
            this.shadowRoot.querySelector('.pill.edit').addEventListener('click', () => {
              eventBus.fire(UPDATE_CRUD_OPERATION, {
                card: this.card,
                operation: 'edit',
              });
            });
            this.shadowRoot.querySelector('.pill.remove').addEventListener('click', () => {
              eventBus.fire(UPDATE_CRUD_OPERATION, {
                card: this.card,
                operation: 'remove',
              });
            });
          }
        } else {
          eventBus.fire(UPDATE_CRUD_OPERATION, {
            card: this.card,
            operation: 'view',
          });
        }
      } else {
        eventBus.fire(TOGGLE_MODAL, {
          operation: 'create',
          instance: {
            card: this.card,
            facilityId: this.card === 'facilities' ? this.id : this.facilityId,
            specialtyId: this.card === 'specialities' ? this.id : this.specialtyId,
            examTypeId: this.card === 'examTypeCPT' ? this.id : this.examTypeId,
            groupId: this.groupId,
            isCPTEnabled: this.isCPTEnabled,
          },
        });
      }
    });
  }

  get operation() {
    return this.getAttribute('operation');
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get isCPTEnabled() {
    return this.getAttribute('isCPTEnabled');
  }

  set isCPTEnabled(newIsCPTEnabled) {
    this.setAttribute('isCPTEnabled', newIsCPTEnabled);
  }

  get open() {
    return this.getAttribute('open') === 'true';
  }

  set open(newOpen) {
    this.setAttribute('open', newOpen);
  }

  get withMenu() {
    return this.getAttribute('withMenu') !== null;
  }

  set withMenu(newWithMenu) {
    this.setAttribute('withMenu', newWithMenu);
  }

  get settings() {
    return this.getAttribute('settings') !== null;
  }

  set settings(newSettings) {
    this.setAttribute('settings', newSettings);
  }

  get card() {
    return this.getAttribute('card');
  }

  set card(newCard) {
    this.setAttribute('card', newCard);
  }

  get facilityId() {
    return this.getAttribute('facilityId');
  }

  set facilityId(newFacilityId) {
    this.setAttribute('facilityId', newFacilityId);
  }

  get specialtyId() {
    return this.getAttribute('specialtyId');
  }

  set specialtyId(newSpecialtyId) {
    this.setAttribute('specialtyId', newSpecialtyId);
  }

  get examTypeId() {
    return this.getAttribute('examTypeId');
  }

  set examTypeId(newExamTypeId) {
    this.setAttribute('examTypeId', newExamTypeId);
  }

  get groupId() {
    return this.getAttribute('groupId');
  }

  set groupId(newGroupId) {
    this.setAttribute('groupId', newGroupId);
  }

  render() {
    return `<button>
      <slot></slot>
      ${
        this.withMenu && this.open
          ? `
      <ul class="hover-menu">
      <li class="pill edit"> edit </li>
      <li class="pill remove"> remove </li>
    </ul>
      `
          : ``
      }
    </button>`;
  }
}

class CardListItem extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this.attachEvents();
  }

  attachEvents() {
    const select = this.shadowRoot.querySelector('select');
    const removeButton = this.shadowRoot.querySelector('exo-button.remove');
    const editButton = this.shadowRoot.querySelector('exo-button.edit');

    if (select) {
      select.addEventListener('change', async () => {
        await changeSelectBoxOnCard({
          card: this.card,
          values: {
            groupId: this.parentId,
            payload: [
              {
                level: select.value,
                permissionsProfile: this.name.replace(/[^\w\s!?]/g, '').replace(/\s/g, ''),
              },
            ],
          },
        });
      });
    }

    if (editButton) {
      editButton.addEventListener('click', e => {
        e.stopPropagation();
        eventBus.fire(TOGGLE_MODAL, {
          operation: 'edit',
          instance: {
            card: this.card,
            id: this.id,
            name: this.name,
            tag: this.tag,
            model: this.model,
            enabled: this.enabled,
            description: this.description,
            technicalFee: this.technicalFee,
            category: this.category,
            subText: this.subText,
            professionalFee: this.professionalFee,
            facilityId: this.card === 'facilities' ? this.id : this.facilityId,
            specialtyId: this.card === 'specialities' ? this.id : this.specialtyId,
            examTypeId: this.card === 'examTypeCPT' ? this.id : this.examTypeId,
            email: this.email,
            groupId: this.groupId,
            dicomTag: this.dicomTag,
            username: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            timezone: this.timezone,
            abbreviation: this.abbreviation,
          },
        });
      });
    }

    if (removeButton) {
      removeButton.addEventListener('click', e => {
        e.stopPropagation();
        eventBus.fire(TOGGLE_MODAL, {
          operation: 'delete',
          instance: {
            card: this.card,
            id: this.id,
            name: this.username || this.name,
            facilityId: this.card === 'facilities' ? this.id : this.facilityId,
            specialtyId: this.card === 'specialities' ? this.id : this.specialtyId,
            examTypeId: this.card === 'examTypeCPT' ? this.id : this.examTypeId,
            groupId: this.groupId,
          },
        });
      });
    }

    const contentEl = this.shadowRoot.querySelector('.content');
    const radioEl = this.shadowRoot.querySelector('.radio-btn');

    [contentEl, radioEl].filter(Boolean).forEach(el => {
      el.addEventListener('click', async () => {
        await clickCardItem({
          id: this.id,
          card: this.card,
          enabled: this.enabled,
          parentId: this.facilityId,
          operation: this.operation,
          page: this.page,
          specialtyId: this.specialtyId,
          worksheetId: this.worksheetId,
        });
      });
    });
  }

  get operation() {
    return this.getAttribute('operation');
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get id() {
    return this.getAttribute('id');
  }

  set id(newId) {
    this.setAttribute('id', newId);
  }

  get card() {
    return this.getAttribute('card');
  }

  set card(newCard) {
    this.setAttribute('card', newCard);
  }

  get name() {
    return this.getAttribute('name');
  }

  set name(newName) {
    this.setAttribute('name', newName);
  }

  get tag() {
    return this.getAttribute('tag');
  }

  set tag(newTag) {
    this.setAttribute('tag', newTag);
  }

  get subText() {
    return this.getAttribute('subText');
  }

  set subText(newSubText) {
    this.setAttribute('subText', newSubText);
  }

  get model() {
    return this.getAttribute('model');
  }

  set model(newModel) {
    this.setAttribute('model', newModel);
  }

  get manufacturer() {
    return this.getAttribute('manufacturer');
  }

  set manufacturer(newManufacturer) {
    this.setAttribute('manufacturer', newManufacturer);
  }

  get timezone() {
    return this.getAttribute('timezone');
  }

  set timezone(newTimezone) {
    this.setAttribute('timezone', newTimezone);
  }

  get abbreviation() {
    return this.getAttribute('abbreviation');
  }

  set abbreviation(newAbbreviaiton) {
    this.setAttribute('abbreviation', newAbbreviaiton);
  }

  get dicomTag() {
    return this.getAttribute('dicomTag');
  }

  set dicomTag(newDicomTag) {
    this.setAttribute('dicomTag', newDicomTag);
  }

  get technicalFee() {
    return this.getAttribute('technicalFee');
  }

  set technicalFee(newTechnicalFee) {
    this.setAttribute('technicalFee', newTechnicalFee);
  }

  get professionalFee() {
    return this.getAttribute('professionalFee');
  }

  set professionalFee(newProfesionalFee) {
    this.setAttribute('professionalFee', newProfesionalFee);
  }

  get description() {
    return this.getAttribute('description');
  }

  set description(newDescription) {
    this.setAttribute('description', newDescription);
  }

  get email() {
    return this.getAttribute('email');
  }

  set email(newEmail) {
    this.setAttribute('email', newEmail);
  }

  get username() {
    return this.getAttribute('username');
  }

  set userName(newUsername) {
    this.setAttribute('username', newUsername);
  }

  get firstName() {
    return this.getAttribute('firstName');
  }

  set firstName(newFirstName) {
    this.setAttribute('firstName', newFirstName);
  }

  get lastName() {
    return this.getAttribute('lastName');
  }

  set lastName(newLastName) {
    this.setAttribute('lastName', newLastName);
  }

  get enabled() {
    return this.getAttribute('enabled');
  }

  set enabled(newEnabled) {
    this.setAttribute('enabled', newEnabled);
  }

  get disabled() {
    return this.getAttribute('disabled');
  }

  set disabled(newDisabled) {
    this.setAttribute('disabled', newDisabled);
  }

  get viewOnly() {
    return this.getAttribute('viewOnly') !== null;
  }

  set viewOnly(newViewOnly) {
    this.setAttribute('viewOnly', newViewOnly);
  }

  get noColor() {
    return this.getAttribute('noColor') !== null;
  }

  set noColor(newNoColor) {
    this.setAttribute('noColor', newNoColor);
  }

  get partialView() {
    return this.getAttribute('partialView') !== null;
  }

  set partialView(newPartialView) {
    this.setAttribute('partialView', newPartialView);
  }

  get radio() {
    return this.getAttribute('radio') !== null;
  }

  set radio(newRadio) {
    this.setAttribute('radio', newRadio);
  }

  get level() {
    return this.getAttribute('level');
  }

  set level(newLevel) {
    this.setAttribute('level', newLevel);
  }

  get category() {
    return this.getAttribute('category');
  }

  set category(newCategory) {
    this.setAttribute('category', newCategory);
  }

  get selectedItem() {
    return this.getAttribute('selectedItem');
  }

  set selectedItem(newSelectedItem) {
    this.setAttribute('selectedItem', newSelectedItem);
  }

  get highlight() {
    return this.getAttribute('highlight') !== null;
  }

  set highlight(newHighlight) {
    this.setAttribute('highlight', newHighlight);
  }

  // TODO: remove this once we have the API set up
  get facilityId() {
    return this.getAttribute('facilityid');
  }

  set facilityId(newFacilityId) {
    this.setAttribute('facilityid', newFacilityId);
  }

  get specialtyId() {
    return this.getAttribute('specialtyId');
  }

  set specialtyId(newSpecialtyId) {
    this.setAttribute('specialtyId', newSpecialtyId);
  }

  get examTypeId() {
    return this.getAttribute('examTypeId');
  }

  set examTypeId(newExamTypeId) {
    this.setAttribute('examTypeId', newExamTypeId);
  }

  get userId() {
    return this.getAttribute('userId');
  }

  set userId(newUserId) {
    this.setAttribute('userId', newUserId);
  }

  get page() {
    return this.getAttribute('page');
  }

  set page(newPage) {
    this.setAttribute('page', newPage);
  }

  get parentId() {
    return this.getAttribute('parentId');
  }

  set parentId(newParentId) {
    this.setAttribute('parentId', newParentId);
  }

  get groupId() {
    return this.getAttribute('groupId');
  }

  set groupId(newGroupId) {
    this.setAttribute('groupId', newGroupId);
  }

  get worksheetId() {
    return this.getAttribute('worksheetId');
  }

  set worksheetId(newWorksheetId) {
    return this.setAttribute('worksheetId', newWorksheetId);
  }

  get clickable() {
    return this.getAttribute('clickable');
  }

  set clickable(newClickable) {
    return this.setAttribute('clickable', newClickable);
  }

  styles() {
    return `
      li {
        list-style: none;
        width: 90%;
        background-color: #ffffff;
        ${this.clickable ? `border-radius: 10px;` : ''}
        margin: 0 auto;
        padding: 2%;
        margin-bottom: ${this.card === 'systems' ? '1%' : '2%'};
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: #2C2C2E;
        ${this.card !== 'systems' ? `justify-content: space-between;` : ''}
        align-items: center;
        ${this.clickable ? `border: 1px solid #007AFF;` : 'border-bottom: 1px solid #D9D9D9;'}
      }

      .no-color {
        background-color: white;
      }

      .systems {
        padding: 1% 0 1% 1% !important;
      }

      .view {
        padding: 3% 0 3% 2%;
      }

      span {
        width: ${this.radio ? '90' : '100'}%;
      }

      .radio-btn {
        padding: 2% 0%;
        margin-right: 3%;
      }

      .long-list {
        display: flex;
        align-items: center;
      }

      .level {
        width: 50%;
      }

      .permissions {
        position: relative;
        cursor: pointer;
      }

      .long-list > exo-button {
        margin-right: 2%;
      }
  
      .long-list > div {
        width: 33%;
        display: inline-block;
      }

      .select-wrapper {
        height: 15px;
        width: 100%;
        display: inline-block;
        position: relative;
      }

      .select-wrapper::after {
        content: url(${dropDownSVG});
        position: absolute;
        right: 10%;
        top: ${isSafari() ? '0' : '-2px'};
        pointer-events: none;
      }

      select {
        padding-right: 25px;
        position: absolute;
        width: 100%;
        right: 15px;
        top: 3px;
        border: none;
        background-color: #f7f7f7;
        border-radius: 5px;
        float: left;
        direction: rtl;
        text-align-last: right; 
        outline: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
      }

      .content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .selected {
        box-shadow: 0px 0px 2px #007AFF;
        background: #007AFF;
        color: white;
      }

      .training {
        margin: 0 !important;
        padding: 2% 0 0 0 !important;
      }

      .disabled {
        filter: grayscale(1);
      }

      .toggle-wrapper {
        width: 10% !important;
        text-align: center;
      }

      .side-btn-container {
        width: 10% !important;
        display: flex !important;
        justify-content: flex-end;
        margin-right: 15px;
      }

      .arrow-only {
        margin-right: 7px;
      }
    `;
  }

  renderSideButton({
    operation,
    card,
    id,
    facilityId,
    specialtyId,
    examTypeId,
    userId,
    enabled,
    radio,
    name,
    disabled,
  }) {
    const getIcon = operation => {
      switch (operation) {
        case 'remove':
          return TrashSVG;
        case 'edit':
          return EditSVG;
        default:
          return '';
      }
    };

    const renderRadioSideButton = () => `
      <svg width="15" height="15" class="radio-btn">
        <image class="${disabled === 'true' ? 'disabled' : ''}" xlink:href="${
      enabled === 'true' ? radioOnSVG : radioOffSVG
    }" width="15" height="15" />
      </svg>
    `;
    const renderIconSideButton = () => {
      switch (operation) {
        case 'view': {
          return this.partialView
            ? ''
            : `<exo-toggle
              card="${card}"
              id="${id}"
              facilityId="${facilityId}"
              enabled="${enabled}"
              userId="${userId}"
              groupId="${this.groupId}"
              examTypeId="${this.page === 'exams' ? this.id : examTypeId}"
              specialtyId="${specialtyId}"
              name="${name}"
              disabled="${disabled}"
            ></exo-toggle>`;
        }
        case 'disabled': {
          return '';
        }
        default:
          return `<exo-button class="${operation}">
          <svg width="15" height="15" class="button-icon">
            <image xlink:href="${getIcon(operation)}" width="15" height="15" />
          </svg>
        </exo-button>`;
      }
    };
    return radio ? renderRadioSideButton() : renderIconSideButton();
  }

  renderSideSelector({ name }) {
    const options = permissionsMap(name) || [];
    //Select disabled for release 1.0
    return `<div class="level">
        <div class="select-wrapper">
           <select>
             ${options
               .map(
                 option => `
               <option
                 value="${option.type === 'MINE' ? 'MY_EXAMS' : option.type}"
                 ${
                   option.type === this.level || (option.type === 'MINE' && this.level === 'MY_EXAMS') ? 'selected' : ''
                 }> ${option.type} </option>
             `,
               )
               .join('')}
              <option value="NONE" ${this.level === 'NONE' ? 'selected' : ''} >NONE</option>
           </select>
        </div>
      </div>`;
  }

  render() {
    const selected = this.id == this.selectedItem;
    return `<li class="${this.card} ${this.viewOnly ? 'view' : ''} ${selected || this.highlight ? 'selected' : ''} ${
      this.noColor ? 'no-color' : ''
    } ${this.page === 'QA' ? 'training' : ''}
    ">
      <span class="content">
        ${
          this.card === 'systems'
            ? `
            <div class="long-list">
              <slot name='aeTitle'></slot>
              <div class="toggle-wrapper">
                ${this.renderSideButton({
                  operation: 'view',
                  card: this.card,
                  id: this.id,
                  facilityId: this.facilityId,
                  specialtyId: this.specialtyId,
                  examTypeId: this.examTypeId,
                  userId: this.userId,
                  enabled: this.enabled,
                  radio: this.radio,
                  partialView: this.partialView,
                  name: this.name,
                  disabled: this.disabled,
                })}
              </div>
              <slot></slot>
              ${
                this.card === 'systems' && this.operation !== 'view'
                  ? `<div class="side-btn-container">
                      ${this.renderSideButton({
                        operation: this.operation,
                        card: this.card,
                        id: this.id,
                        specialtyId: this.specialtyId,
                        enabled: this.enabled,
                        name: this.name,
                      })}
                    </div>`
                  : ''
              }
            </div>
            `
            : `<slot></slot>`
        }
        <slot></slot>
      </span>
      ${this.card === 'permissions' ? this.renderSideSelector({ name: this.name.trim() }) : ''}
      ${
        !this.viewOnly && this.card !== 'systems'
          ? this.renderSideButton({
              operation: this.operation,
              card: this.card,
              id: this.id,
              facilityId: this.facilityId,
              userId: this.userId,
              specialtyId: this.specialtyId,
              examTypeId: this.examTypeId,
              enabled: this.enabled,
              radio: this.radio,
              partialView: this.partialView,
              name: this.name,
              disabled: this.disabled,
            })
          : ''
      }
      ${
        this.clickable
          ? `<svg width="10" height="12" class="${this.viewOnly ? 'arrow-only' : ''}">
        <image xlink:href="${this.selectedItem === this.id ? whiteArrow : blueArrow}" width="7" height="12" />
      </svg>`
          : ''
      }
    </li>`;
  }
}

customElements.define(`exo-card-list-item`, CardListItem);
customElements.define(`exo-card-button`, CardButton);
customElements.define(`exo-card-title`, CardTitle);
customElements.define(`exo-card`, Card);
