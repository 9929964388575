/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const AccessControlAPI = require('../services/api/accesscontrol');
const { UPDATE_CRUD_OPERATION, UPDATE_SELECTED_CATEGORY, FETCH_PERMISSIONS, FETCH_USER_GROUPS } = require('../events');
const eventBus = require('../utils/EventBus');
const PlusSVG = require('../../assets/plus.svg');
const ContextualSVG = require('../../assets/contextual.svg');
const CloseButtonSVG = require('../../assets/close-btn.svg');
const { ALWAYS_ENABLED_GROUPS } = require('./Members');
const setScrollPosition = require('../utils/setScrollPosition');
const sort = require('../utils/sort');

const accessControlService = new AccessControlAPI();

class Groups extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this._useScroll = true;
  }

  attachEvents() {
    const permissionsResetButton = this.shadowRoot.querySelector('#permissions-button');
    const userGroupResetButton = this.shadowRoot.querySelector('#user-group-button');
    if (permissionsResetButton) {
      permissionsResetButton.addEventListener('click', async () => {
        try {
          await accessControlService
            .userGroups({ groupId: this.selectedCategory })
            .permissions()
            .resetPermissions({ payload: { user_category_ids: [parseInt(this.selectedCategory)] } });
        } catch (e) {
          eventBus.fire(TOAST, {
            items: [
              {
                type: ERROR,
                message: message,
              },
            ],
          });
        } finally {
          setTimeout(async () => {
            eventBus.fire(FETCH_PERMISSIONS, {
              data: sort(
                await accessControlService
                  .userGroups({ groupId: this.selectedCategory })
                  .permissions()
                  .get(),
                'permissionsProfile',
              ),
            });
          }, 1000);
        }
      });
    }
    if (userGroupResetButton) {
      userGroupResetButton.addEventListener('click', async () => {
        try {
          await accessControlService
            .userGroups({ groupId: this.selectedCateogy })
            .permissions()
            .resetPermissions();
        } catch (e) {
          eventBus.fire(TOAST, {
            items: [
              {
                type: ERROR,
                message: message,
              },
            ],
          });
        }
      });
    }
  }

  set groupListPosition(newgroupListPosition) {
    this.setAttribute('groupListPosition', newgroupListPosition);
  }

  get groupListPosition() {
    return this.getAttribute('groupListPosition');
  }

  set permissionListPosition(newpermissionListPosition) {
    this.setAttribute('permissionListPosition', newpermissionListPosition);
  }

  get permissionListPosition() {
    return this.getAttribute('permissionListPosition');
  }

  handleScroll() {
    const groupList = this.shadowRoot.querySelector('.ugroups');

    if (groupList) {
      groupList.addEventListener('scroll', event => {
        this.groupListPosition = setScrollPosition(event);
      });

      groupList.scrollTo({ top: this.groupListPosition });
    }

    const permissionList = this.shadowRoot.querySelector('.permissions-list');

    if (permissionList) {
      permissionList.addEventListener('scroll', event => {
        this.permissionListPosition = setScrollPosition(event);
      });

      permissionList.scrollTo({ top: this.permissionListPosition });
    }
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .head-section {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .buttons {
        width: 30%;
      }

      .button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }

      .button-icon {
        margin: 30% 5%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .card-container {
        display: flex;
      }

      exo-card {
        width: 25%;
        margin-right: 2%;
      }

      exo-card.permissions-card {
        width: 50%;
      }

      exo-card-button {
        position: relative;
      }

      exo-card-list-item:hover {
        cursor: pointer;
      }

      .blur {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        pointer-events: none;
      }

      .permissions-list {
        height: 550px;
        overflow: auto;
      }

      .card-content {
        height: 80vh;
        display: flex;
        flex-direction: column;
      }

      .reset-all {
        width: 60px;
        margin-left: auto;
        margin-top: 10px;
        font-family: HelveticaNowDisplay, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        text-align: right;
        letter-spacing: 0.233333px;

        color: #007AFF;
        border: none;
        background: transparent;
        padding: 0px;
        display: block;
        cursor: pointer;
      }

      ul {
        overflow: auto;
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }
    `;
  }

  async connectedCallback() {
    eventBus.register(UPDATE_CRUD_OPERATION, ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    eventBus.register(UPDATE_SELECTED_CATEGORY, ({ detail }) => {
      this.selectedCategory = detail.categoryId;
      this.permissionListPosition = 0;
    });

    this.listenForEvents(
      [
        { event: FETCH_USER_GROUPS, key: 'ugroups' },
        { event: FETCH_PERMISSIONS, key: 'permissions' },
      ],
      eventBus,
    );

    eventBus.fire(FETCH_USER_GROUPS, {
      data: await accessControlService.userGroups().get(),
    });
  }

  static get observedAttributes() {
    return ['selectedcard', 'operation', 'selectedfacility'];
  }

  set selectedCard(newSelectedCard) {
    this.setAttribute('selectedCard', newSelectedCard);
  }

  get selectedCard() {
    return this.getAttribute('selectedCard') || 'facilities';
  }

  set selectedCategory(newSelectedCategory) {
    this.setAttribute('selectedCategory', newSelectedCategory);
  }

  get selectedCategory() {
    return this.getAttribute('selectedCategory');
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  renderExoCard({ title, divider, items, card, operation, nameKey, parentId, selectedItem }) {
    return `
      <exo-card class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''} ${
      card === 'permissions' ? 'permissions-card' : ''
    }" page="groups">
      <div class="card-content">
        <div class="head-section">
          <exo-card-title title="${title}"></exo-card-title>
          ${
            card !== 'permissions'
              ? `<div class="buttons">
              <div class="button-wrapper">
              <exo-card-button class="add" card="${card}" facilityId="${this.selectedFacility}">
              <svg width="15" height="15" class="button-icon">
                <image xlink:href="${PlusSVG}" width="15" height="15" />
              </svg>
            </exo-card-button>
            <exo-card-button card="${card}" withMenu settings operation="${operation}">
              <svg width="15" height="15" class="button-icon">
                <image xlink:href="${operation === 'view' ? ContextualSVG : CloseButtonSVG}" width="15" height="15" />
              </svg>
            </exo-card-button>
            </div>
          </div>`
              : ''
          }
        </div>

      
        <div class="divider">
          ${divider.map(d => `<span>${d}</span>`).join('')}
        </div>

        <ul class="items ${card === 'permissions' ? 'permissions-list' : card}">
          ${items
            .map(
              item =>
                `<exo-card-list-item
              name="${item[nameKey]}"
              operation="${ALWAYS_ENABLED_GROUPS.includes(item.name) && operation !== 'view' ? 'disabled' : operation}"
              id="${item.id}"
              card="${card}"
              ${card === 'permissions' && 'viewOnly'}
              enabled="${item.enabled}"
              level=${item.level}
              parentId="${parentId}"
              selectedItem="${selectedItem}"
              disabled=${item.disabled}
              page="permissions"
              ${card === 'ugroups' ? `clickable="true"` : ''}
            >
              ${item[nameKey].replace(/_/g, ' ')}
            </exo-card-list-item>`,
            )
            .join('')}
        </ul>

        ${
          operation === 'view' && items.length > 0 && card !== 'permissions'
            ? `<div class="enable-all">
               <span class="enable-all-label">${items.every(item => item.enabled) ? 'Disable All' : 'Enable All'}</span>
               <exo-toggle 
                 card="${card}"
                 facilityId="${this.selectedFacility}"
                 all
                 enabled="${items.every(item => item.enabled)}"
                ></exo-toggle>
              </div>`
            : ''
        }
        </div>
      </exo-card>
    `;
  }

  render() {
    return `
      <div>
        <div class="title">User Groups</div>
        <div class="card-container">
          ${
            this._data && this._data.ugroups
              ? this.renderExoCard({
                  card: 'ugroups',
                  title: 'User Groups',
                  divider: ['User Groups', 'Enabled'],
                  operation: this.operation,
                  selectedItem: this.selectedCategory,
                  items: this._data.ugroups.map(group => ({
                    ...group,
                    disabled: ALWAYS_ENABLED_GROUPS.some(item => item === group.name),
                  })),
                  nameKey: 'name',
                })
              : `<span> Loading... </span>`
          }
          ${
            this._data && this._data.permissions
              ? this.renderExoCard({
                  card: 'permissions',
                  title: 'Permissions',
                  divider: ['Profiles', 'Access Rights'],
                  operation: 'view',
                  selectedItem: null,
                  items: this._data.permissions,
                  nameKey: 'permissionsProfile',
                  parentId: this.selectedCategory,
                })
              : ''
          }
        </div>
      </div>
    `;
  }
}
customElements.define('groups-page', Groups);
