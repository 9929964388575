const AccessControlAPI = require('../../services/api/accesscontrol');
const eventBus = require('../../utils/EventBus');

const accessControlService = new AccessControlAPI();
const { FETCH_LDAP_GROUP_USERS, FETCH_ALL_USERS, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ email, password, username, groupId, id, lastName, firstName, specialtyId }) => {
  try {
    await accessControlService
      .userGroups({
        groupId,
      })
      .users({ id })
      .update({
        payload: {
          username,
          email,
          password,
          lastName,
          firstName,
        },
      });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Update saved',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error,
        },
      ],
    });
  }
  eventBus.fire(FETCH_ALL_USERS, {
    data: await accessControlService
      .userGroups()
      .users()
      .getAll(),
  });
  eventBus.fire(FETCH_LDAP_GROUP_USERS, {
    data: await accessControlService
      .userGroups({ groupId })
      .users()
      .get({ parameters: { specialtyId } }),
  });
};
