const APIService = require('../APIService');

class Licenses extends APIService {
  constructor() {
    super();
    this._url = '/it-setup/product-licenses';
  }

  async activate({ id }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}/${id}/activate`, {});
      return data.results;
    } catch (error) {
      throw new Error(error);
    }
  }

  async fetch() {
    try {
      const { data } = await this.httpClient.get(this._url);
      return data.results;
    } catch (error) {
      throw new Error(error);
    }
  }

  async patch({ id, thirdPartySystems, exoIris }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/${id}`, { thirdPartySystems, exoIris });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

module.exports = Licenses;
