const APIService = require('../APIService');
const FacilitiesService = require('./facilities');
const SpecialtiesService = require('./specialties');
const ExamTypesService = require('./examTypes');
const GlobalTagsService = require('./globalTags');
const MapTagsService = require('./mapTags');
const BillingCodesService = require('./billingCodes');
const UserSecuritySettingsService = require('./userSecuritySettings');
const UltrasoundSystemsService = require('./ultrasoundSystems');
const UserGroupsService = require('./userGroups');
const ExamWorksheetsService = require('./examWorksheets');
const { ITEMS_PER_PAGE } = require('../../../utils/constants');

class ClinicDefinition extends APIService {
  constructor() {
    super();
    this._url = '/clinic-definition';
  }

  facilities({ facilityId } = { facilityId: null }) {
    const facilitiesService = new FacilitiesService();
    return {
      get: async ({ parameters } = { parameters: {} }) => facilitiesService.get({ parameters }),
      patch: async ({ payload }) => facilitiesService.patch({ id: facilityId, payload }),
      delete: async () => facilitiesService.delete({ id: facilityId }),
      create: async ({ payload }) => facilitiesService.create({ payload }),
      enableAll: async () => facilitiesService.enableAll(),
      disableAll: async () => facilitiesService.disableAll(),
      specialties: this.specialties({ facilityId }),
    };
  }

  specialties({ facilityId } = { facilityId: null }) {
    const specialtiesService = new SpecialtiesService({
      facilityId,
    });
    return ({ specialtyId } = { specialtyId: null }) => ({
      get: async ({ parameters } = { parameters: {} }) => await specialtiesService.get({ parameters }),
      create: async ({ payload }) => await specialtiesService.create({ payload }),
      patch: async ({ payload }) =>
        await specialtiesService.patch({
          id: specialtyId,
          payload,
        }),
      delete: async () => await specialtiesService.delete({ id: specialtyId }),
      enableAll: async () => await specialtiesService.enableAll(),
      disableAll: async () => await specialtiesService.disableAll(),
      mapTags: this.mapTags({ facilityId, specialtyId }),
      ultrasoundSystems: this.ultrasoundSystems({ facilityId, specialtyId }),
      userGroups: this.userGroups({ facilityId, specialtyId }),
      examTypes: () => this.examTypes({ facilityId, specialtyId }),
    });
  }

  userGroups({ facilityId, specialtyId } = { facilityId: null, specialtyId: null }) {
    const userGroupsService = new UserGroupsService({
      facilityId,
      specialtyId,
    });

    return ({ id } = { id: null }) => ({
      get: async () => await userGroupsService.get(),
      create: async ({ payload }) => await userGroupsService.create({ payload }),
      delete: async ({ id }) => await userGroupsService.delete({ id }),
      enableAll: async () => await userGroupsService.enableAll(),
      disableAll: async () => await userGroupsService.disableAll(),
      qaSettings: ({ qaSettingsId } = { qaSettingsId: null }) =>
        userGroupsService.qaSettings({ groupId: id, qaSettingsId }),
    });
  }

  ultrasoundSystems() {
    const ultraSoundSystemsService = new UltrasoundSystemsService();

    return {
      get: async ({ id } = { id: null }) => await ultraSoundSystemsService.get({ id }),
      create: async ({ payload }) => await ultraSoundSystemsService.create({ payload }),
      patch: async ({ id, payload }) => await ultraSoundSystemsService.patch({ id, payload }),
      delete: async ({ id }) => await ultraSoundSystemsService.delete({ id }),
      enableAll: async () => await ultraSoundSystemsService.enableAll(),
      disableAll: async () => await ultraSoundSystemsService.disableAll(),
    };
  }

  mapTags({ facilityId, specialtyId } = { facilityId: null, specialtyId: null }) {
    const mapTagsService = new MapTagsService({
      facilityId,
      specialtyId,
    });
    return ({ tagId } = { tagId: null }) => ({
      getAll: async () => await mapTagsService.getAll(),
      get: async () => await mapTagsService.get({ id: tagId }),
      create: async ({ payload }) => await mapTagsService.create({ payload }),
      patch: async ({ payload }) =>
        await mapTagsService.patch({
          id: tagId,
          payload,
        }),
      delete: async () => await mapTagsService.delete({ id: tagId }),
      enableAll: async () => await mapTagsService.enableAll(),
      disableAll: async () => await mapTagsService.disableAll(),
    });
  }

  examTypes({ examTypeId, facilityId, specialtyId } = { examTypeId: null, specialtyId: null, facilityId: null }) {
    const examTypesService = new ExamTypesService({ facilityId, specialtyId, id: examTypeId });
    return {
      get: async ({ parameters } = { parameters: {} }) => await examTypesService.get({ parameters }),
      getEnabled: async () => await examTypesService.getEnabled(),
      create: async ({ payload }) => await examTypesService.create({ payload }),
      delete: async ({ examTypeId }) => await examTypesService.delete({ id: examTypeId }),
      patch: async ({ payload }) => await examTypesService.patch({ payload }),
      enableAll: async () => await examTypesService.enableAll(),
      disableAll: async () => await examTypesService.disableAll(),
      enable: async ({ examTypeId } = { examTypeId: null }) => await examTypesService.enable({ id: examTypeId }),
      disable: async ({ examTypeId } = { examTypeId: null }) => await examTypesService.disable({ id: examTypeId }),
      billingCodes: this.billingCodes({ examTypeId }),
    };
  }

  billingCodes({ examTypeId } = { examTypeId: null }) {
    const billingCodesService = new BillingCodesService({
      examTypeId,
    });
    return ({ billingCodeId } = { billingCodeId: null }) => ({
      getAll: async ({ parameters } = { parameters: {} }) => await billingCodesService.getAll({ parameters }),
      get: async () => await billingCodesService.get({ id: billingCodeId }),
      create: async ({ payload }) => await billingCodesService.create({ payload }),
      patch: async ({ payload }) =>
        await billingCodesService.patch({
          id: billingCodeId,
          payload,
        }),
      delete: async () => await billingCodesService.delete({ id: billingCodeId }),
      enableAll: async () => await billingCodesService.enableAll(),
      disableAll: async () => await billingCodesService.disableAll(),
      enable: async () => await billingCodesService.enable({ id: billingCodeId }),
      disable: async () => await billingCodesService.disable({ id: billingCodeId }),
    });
  }

  globalTags({ tagId } = { tagId: null }) {
    const tagsService = new GlobalTagsService();
    return {
      getAll: async ({ offset, limit } = { offset: 0, limit: ITEMS_PER_PAGE }) =>
        await tagsService.getAll({ offset, limit }),
      get: async () => await tagsService.get({ id: tagId }),
      patch: async ({ payload }) =>
        await tagsService.patch({
          id: tagId,
          payload,
        }),
      create: async ({ payload }) =>
        await tagsService.create({
          payload,
        }),
      delete: async () => await tagsService.delete({ id: tagId }),
    };
  }

  userSecuritySettings({ id } = { id: null }) {
    const userSecuritySettingsService = new UserSecuritySettingsService({ id });
    return {
      get: async () => await userSecuritySettingsService.get(),
      save: async ({ payload }) => await userSecuritySettingsService.save({ payload }),
    };
  }

  examWorksheets() {
    const examWorksheetsService = new ExamWorksheetsService();
    return {
      getAll: ({ parameters } = { parameters: {} }) => examWorksheetsService.getAll({ parameters }),
    };
  }
}

module.exports = ClinicDefinition;
