/* eslint-disable max-lines-per-function */
const deleteFacility = require('./deleteFacility');
const deleteSpeciality = require('./deleteSpecialty');
const deleteSystem = require('./deleteSystem');
const deleteExamType = require('./deleteExamType');
const deleteCPTCode = require('./deleteCPTCode');
const deleteUserGroup = require('./deleteUserGroup');
const deleteUser = require('./deleteUser');
const deleteTag = require('./deleteTag');

module.exports = ({ values, card }) => {
  switch (card) {
    case 'facilities': {
      deleteFacility({
        facilityId: values.facilityId,
      });
      break;
    }
    case 'specialities': {
      deleteSpeciality({
        facilityId: values.facilityId,
        specialtyId: values.specialtyId,
      });
      break;
    }
    case 'systems': {
      deleteSystem({
        systemId: values.systemId,
      });
      break;
    }
    case 'examTypeCPT': {
      deleteExamType({
        id: values.examTypeId,
      });
      break;
    }
    case 'cpt': {
      deleteCPTCode({
        examTypeId: values.examTypeId,
        id: values.id,
      });
      break;
    }
    case 'ugroups': {
      deleteUserGroup({
        id: values.id,
      });
      break;
    }
    case 'users': {
      deleteUser({
        groupId: values.groupId,
        id: values.id,
      });
      break;
    }
    case 'tags': {
      deleteTag({
        specialtyId: values.specialtyId,
        id: values.id,
      });
      break;
    }
  }
};
