const APIService = require('../APIService');

class AuthenticationMethods extends APIService {
  constructor() {
    super();
    this._url = '/it-setup/authentication-methods';
  }

  async multiPatch({ authenticationMethods }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/multi-patch`, authenticationMethods);
      return data.results;
    } catch (error) {
      throw new Error(error);
    }
  }

  async fetch() {
    try {
      const { data } = await this.httpClient.get(this._url);
      var new_data = data.results.filter(i => i.id != 3)
      return new_data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

module.exports = AuthenticationMethods;
