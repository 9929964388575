const eventBus = require('../../utils/EventBus');

const { FETCH_SYSTEMS, TOAST, ERROR, SUCCESS } = require('../../events');
const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const clinicDefinitionAPI = new ClinicDefinitionAPI();

module.exports = async ({ systemId, aeTitle, enabled, specialtyId, dicomTag }) => {
  try {
    await clinicDefinitionAPI.ultrasoundSystems().patch({
      id: systemId,
      payload: {
        aeTitle,
        isEnabled: enabled,
        ...(specialtyId && { specialtyId }),
        ...(dicomTag && { dicomTag }),
      },
    });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Update saved',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_SYSTEMS, {
    data: await clinicDefinitionAPI.ultrasoundSystems().get(),
  });
};
