const APIService = require('./APIService');

class AuthSettings extends APIService {
  constructor() {
    super();
  }

  async get() {
    try {
      const data = await this.httpClient.get('auth-settings');
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

module.exports = AuthSettings;
