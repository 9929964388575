const APIService = require('../APIService');
const UserGroupsService = require('./usergroups');
const PermissionsService = require('./permissions');
const CredentialedExamTypesService = require('./credentialedexamtypes');
const UsersService = require('./users');

class AccessControl extends APIService {
  constructor() {
    super();
    this._url = '/access-control';
  }

  permissions({ groupId, permissionId }) {
    const permissionsService = new PermissionsService({ groupId });
    return {
      get: async () => permissionsService.get(),
      patch: async ({ payload }) =>
        permissionsService.patch({
          id: permissionId,
          payload,
        }),
      resetPermissions: async ({ payload } = { payload: {} }) => await permissionsService.resetPermissions({ payload }),
    };
  }

  users({ groupId, id }) {
    const usersService = new UsersService({ groupId, id });
    return {
      get: async ({ parameters } = { parameters: {} }) => await usersService.get({ parameters }),
      getAll: async ({ parameters } = { parameters: {} }) => await usersService.getAll({ parameters }),
      create: async ({ payload }) => await usersService.create({ payload }),
      addToGroup: async ({ specialtyId, id } = { id: null }) => await usersService.addToGroup({ specialtyId, id }),
      update: async ({ payload }) => await usersService.update({ payload }),
      delete: async () => await usersService.delete({ id }),
      credentialedExamTypes: ({ examTypeId } = { examTypeId: null }) =>
        this.credentialedExamTypes({ userId: id, examTypeId }),
    };
  }

  credentialedExamTypes({ userId } = { userId: null }) {
    const credentialedExamTypesService = new CredentialedExamTypesService({ userId });
    return {
      get: async () => credentialedExamTypesService.get(),
      grant: async ({ examTypesIds }) => credentialedExamTypesService.grant({ examTypesIds }),
      grantAll: async () => credentialedExamTypesService.grantAll(),
      revokeAll: async () => credentialedExamTypesService.revokeAll(),
      revoke: async ({ examTypesIds }) => credentialedExamTypesService.revoke({ examTypesIds }),
    };
  }

  userGroups({ groupId } = { groupId: null }) {
    const userGroupsService = new UserGroupsService();
    return {
      get: async ({ parameters } = {}) => userGroupsService.get({ parameters }),
      patch: async ({ payload }) => userGroupsService.patch({ id: groupId, payload }),
      create: async ({ payload }) => userGroupsService.create({ payload }),
      delete: async () => userGroupsService.delete({ id: groupId }),
      enableAll: async () => userGroupsService.enableAll(),
      disableAll: async () => userGroupsService.disableAll(),
      permissions: ({ permissionId } = { permissionId: null }) => this.permissions({ groupId, permissionId }),
      users: ({ id } = { id: null }) => this.users({ groupId, id }),
      getInsights: ({ id }) => userGroupsService.getInsights({ id }),
      updateInsights: async ({ id, payload }) => userGroupsService.updateInsights({ id, payload }),
    };
  }
}

module.exports = AccessControl;
