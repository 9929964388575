/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HorizontalCard = require('./HorizontalCard');

const dropDownSVG = require('../../assets/dropdown.svg');
const PlusSVG = require('../../assets/plus.svg');
const ContextualSVG = require('../../assets/contextual.svg');
const CloseButtonSVG = require('../../assets/close-btn.svg');
const setScrollPosition = require('../utils/setScrollPosition');

const ClinicDefinitionAPI = require('../services/api/clinicdefinition');

const {
  FETCH_EXAM_TYPES,
  UPDATE_SELECTED_EXAM_TYPE,
  UPDATE_CRUD_OPERATION,
  FETCH_BILLING_CODES,
  PAGINATE,
} = require('../events');

const eventBus = require('../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();

class ExamTypesCPT extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
    this._useScroll = true;
    this.offset = 0;
  }

  static get observedAttributes() {
    return ['selectedexamtype', 'selectedcard', 'operation'];
  }

  async connectedCallback() {
    eventBus.register(UPDATE_CRUD_OPERATION, ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    eventBus.register(UPDATE_SELECTED_EXAM_TYPE, ({ detail }) => {
      this.selectedExamType = detail.examTypeId;
    });

    this.listenForEvents(
      [
        { event: FETCH_EXAM_TYPES, key: 'exams' },
        { event: FETCH_BILLING_CODES, key: 'billingCodes' },
      ],
      eventBus,
    );

    eventBus.fire(FETCH_EXAM_TYPES, {
      data: await clinicDefinitionService.examTypes().get(),
    });

    eventBus.registerOnce(PAGINATE, async ({ detail }) => {
      this.billingListPosition = detail.listPosition;
      const { results, count } = this._data.billingCodes;
      if (!this.fetching && results.length < count) {
        this.fetching = true;
        this.offset = results.length;

        const allUsersData = await clinicDefinitionService
          .examTypes({ examTypeId: this.selectedExamType })
          .billingCodes()
          .getAll({ parameters: { offset: this.offset, limit: detail.limit } });

        eventBus.fire(FETCH_BILLING_CODES, {
          data: {
            results: [...results, ...allUsersData.results],
            count: allUsersData.count,
          },
        });

        this.fetching = false;
      }
    });
  }

  get selectedExamType() {
    return this.getAttribute('selectedExamType');
  }

  set selectedExamType(newSelectedExamType) {
    this.billingListPosition = 0;
    this.setAttribute('selectedExamType', newSelectedExamType);
  }

  get selectedCard() {
    return this.getAttribute('selectedCard') || 'examTypeCPT';
  }

  set selectedCard(newSelectedCard) {
    this.setAttribute('selectedCard', newSelectedCard);
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  set examListPosition(newExamListPosition) {
    this.setAttribute('examListPosition', newExamListPosition);
  }

  get examListPosition() {
    return this.getAttribute('examListPosition');
  }

  set procedureListPosition(newProcedureListPosition) {
    this.setAttribute('procedureListPosition', newProcedureListPosition);
  }

  get procedureListPosition() {
    return this.getAttribute('procedureListPosition');
  }

  set billingListPosition(newbillingListPosition) {
    this.setAttribute('billingListPosition', newbillingListPosition);
  }

  get billingListPosition() {
    return this.getAttribute('billingListPosition');
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .select-box {
        width: 23%;
      }

      select {
        border: none;
        background-color: #f7f7f7;
        border-radius: 5px;
        width: 100%;
        float: left;
        padding: 15px 20px;
        outline: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-left: 5%;
      }

      .buttons {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .long-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .button-icon {
        margin: 30% 5%;
      }

      exo-card-button {
        position: relative;
      }

      .select-wrapper {
        width: 90%;
        margin-top: 3%;
        background-color: #FFF;
        display: inline-block;
        position: relative;
      }
      
      .select-wrapper::before {
        content: url(${dropDownSVG});
        position: absolute;
        right: 0%;
        top: 15%;
      }

      exo-card-title {
        margin-bottom: 10%;
      }

      .title-wrapper {
        position: relative;
        margin-left: 6%;
      }

      .long-title-wrapper {
        display: flex;
        justify-content: space-between;
        width: 90%;
        flex-direction: row;
        padding-left: 5%;
        padding-top: 1%;
      }

      .long-title-wrapper > exo-card-title {
        padding-left: 0%;
        margin: 0%;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin-left: 5%;
        width: 85%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .divider-last {
        margin-right: 10%;
      }

      .long-divider {
        margin-top: 3%;
        width: 79%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }

      .cpt {
        max-height: none !important;
      }

      .text-input {
        list-style: none;
        padding: 3% 0 3% 2%;
      }

      .text-input > input {
        border:none;
        margin-left: 5%;
        border-bottom: 1px solid #DBDBDB;
        padding: 5px 10px;
        width: 80%;
        outline: none;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .card-container {
        margin-top: 3%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
        padding-bottom: 5%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }
    
      exo-card-button:last-child {
        margin-left: 10%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .card-container {
        display: flex;
      }

      .large-card {
        display: flex;
        flex-direction: column;
        height: 80vh;
        width: 100%;
      }

      exo-card {
        width: 25%;
        margin-right: 2%;
      }

      horizontal-card.examTypes {
        width: 67%;
      }

      exo-card-list-item:hover {
        cursor: pointer;
      }

      .half {
        margin-top: 6%;
        display: inline-block;
        vertical-align: top;
        width: 45%;
        max-width: 50%;
      }

      .subtext {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: rgba(44, 44, 46, 0.5);
      }
      .input-list-item {
        display: flex;
        margin-top: 1%;
        height: 50px;
      }

      .items {
        max-height: 280px;
        overflow-y: scroll;
      }

      input {
        border:none;
        margin-left: 5%;
        border-bottom: 1px solid #DBDBDB;
        padding: 5px 10px;
        width: 80%;
        margin-bottom: 5%;
        outline: none;
      }

      .input-container {
        position: relative;
      }

      .floating-label {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.2px;
        color: rgba(44, 44, 46, 0.5);
        position: absolute;
        right: 12%;
      }
     
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
    }

      button {
        border: 2px solid black;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
      }

      
      .blur {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        pointer-events: none;
      }

      .confirm {
        border-color: #007AFF;
        float: right;
        margin-right: 3%;
        color: #007AFF;
        margin-left: 2%;
      }

      .card-content {
        height: 80vh;
        display: flex;
        flex-direction: column;
      }

      ul {
        overflow: auto;
      }

      .exams-divider {
        width: 80%;
      }

      .exams-container {
        display: flex;
        justify-content: center;
        width: 100%;
        max-height: 280px;
      }

      .exam-list {
        width: 95%;
        padding: 8px 0;
        border: 1px solid #CDCDCD;
        border-radius: 10px;
        overflow-y: scroll;
        scroll-padding-right: 100px;
      }
    `;
  }

  handleScroll() {
    const examList = this.shadowRoot.querySelector('#exam-list');

    examList.addEventListener('scroll', event => {
      this.examListPosition = setScrollPosition(event);
    });

    examList.scrollTo({ top: this.examListPosition });

    const procedureList = this.shadowRoot.querySelector('#procedure-list');

    procedureList.addEventListener('scroll', event => {
      this.procedureListPosition = setScrollPosition(event);
    });

    procedureList.scrollTo({ top: this.procedureListPosition });

    const billingList = this.shadowRoot.querySelector('#cpt-list');

    if (billingList) {
      billingList.addEventListener('scroll', event => {
        this.billingListPosition = setScrollPosition(event);
      });

      billingList.scrollTo({ top: this.billingListPosition });
    }
  }

  renderExoCard({ title, items, card, operation, nameKey, placeholderTitle, selectedItem }) {
    let exams = [];
    let procedures = [];

    if (card === 'examTypeCPT') {
      exams = items.filter(item => item.examTypeCategory === 'EXAM');
      procedures = items.filter(item => item.examTypeCategory === 'PROCEDURE');
    }

    return `
      <exo-card
        class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}">
        <div class='card-content'>
          <div class="head-section long-title-wrapper ${card}">
            <exo-card-title ${
              placeholderTitle ? `placeholderTitle="${placeholderTitle}"` : ''
            } title="${title}"></exo-card-title>

            <div class="buttons">
            <exo-card-button class="add" card="${card}" facilityId="${this.selectedFacility}">
              <svg width="15" height="15" class="button-icon">
                <image xlink:href="${PlusSVG}" width="15" height="15" />
              </svg>
            </exo-card-button>
            <exo-card-button card="${card}" withMenu settings operation="${operation}">
              <svg width="15" height="15" class="button-icon">
                <image xlink:href="${operation === 'view' ? ContextualSVG : CloseButtonSVG}" width="15" height="15" />
              </svg>
            </exo-card-button>
          </div>
          </div>

        
          <div class="divider exams-divider">
            <span> Exams </span>
            <span> Enabled </span>
          </div>

          ${(card === 'examTypeCPT' ? '<div class="exams-container">' : '')}
            <ul class="items exam-list" id="exam-list">
              ${(card === 'examTypeCPT' ? exams : items)
                .map(
                  item =>
                    `<exo-card-list-item
                      page="examTypesCPT"
                      name="${item.name}"
                      operation="${operation}"
                      id="${item.id}"
                      card="${card}"
                      category="${item.examTypeCategory}"
                      ${card === 'users' ? 'viewOnly' : ''}
                      model="${item.model}"
                      enabled="${item.isEnabled}"
                      facilityId="${this.selectedFacility}"
                      specialtyId="${this.selectedSpecialty}"
                      selectedItem="${selectedItem}"
                      userId="${this.selectedUser}"
                      abbreviation="${item.abbrev || ''}"
                      clickable="true"
                    >
                      <div class='exam-type'>${item[nameKey]}</div>
                    </exo-card-list-item>`,
                )
                .join('')}
            </ul>
          ${(card === 'examTypeCPT' ? '</div>' : '')}      
          ${
            card === 'examTypeCPT'
              ? `
            <div class="divider exams-divider">
              <span> Procedures </span>
              <span> Enabled </span>
            </div>
            <div class="exams-container">
              <ul class="items exam-list procedures" id="procedure-list">
                ${procedures
                  .map(
                    procedure => `
                    <exo-card-list-item
                      page="examTypesCPT"
                      name="${procedure.name}"
                      operation="${operation}"
                      id="${procedure.id}"
                      card="${card}"
                      category="${procedure.examTypeCategory}"
                      ${card === 'users' ? 'viewOnly' : ''}
                      model="${procedure.model}"
                      enabled="${procedure.isEnabled}"
                      facilityId="${this.selectedFacility}"
                      specialtyId="${this.selectedSpecialty}"
                      userId="${this.selectedUser}"
                      selectedItem="${selectedItem}"
                      abbreviation="${procedure.abbrev || ''}"
                      clickable="true"
                    >
                      <div class='exam-type'>${procedure[nameKey]}</div>
                    </exo-card-list-item>`,
                  )
                  .join('')}
              </ul>
            </div>
          `
              : ''
          }

          ${
            card !== 'users' && operation === 'view' && items.length > 0
              ? `<div class="enable-all">
                <span class="enable-all-label">${
                  items.every(item => item.isEnabled) ? 'Disable All' : 'Enable All'
                }</span>
                <exo-toggle
                  card="${card}"
                  facilityId="${this.selectedFacility}"
                  specialtyId="${this.selectedSpecialty}"
                  userId="${this.selectedUser}"
                  all
                  enabled="${items.every(item => item.isEnabled)}"
                  ></exo-toggle>
                </div>`
              : ''
          }
        </div>
      </exo-card>
    `;
  }

  render() {
    return `
      <div class="container">
        <div class="title">Exam Types & CPT Codes</div>
        <div class="card-container">
          ${this._data &&
            this._data.exams &&
            this.renderExoCard({
              card: 'examTypeCPT',
              title: 'Exam Type',
              nameKey: 'name',
              divider: ['Exam Type', 'Enable'],
              selectedItem: this.selectedExamType,
              operation: this.operation,
              items: this._data.exams,
            })}

          ${
            this._data && this._data.exams && this.selectedExamType && this._data.billingCodes
              ? `<horizontal-card page="cpt" class="examTypes" pagination totalCount=${
                  this._data.billingCodes.count
                } itemsCount=${this._data.billingCodes.results.length} offset=${this._data.billingCodes.results.length}
              >
                   <div class="select-box large-card ${
                     this.selectedCard !== 'cpt' && this.operation !== 'view' ? 'blur' : ''
                   } ">
                     <div class="long-title-wrapper">
                       <exo-card-title title="CPT Codes"></exo-card-title>
                       <div class="long-buttons">
                          <exo-card-button class="add" card="cpt" 
                            examTypeId="${this.selectedExamType}"
                            isCPTEnabled="${this._data.billingCodes.results.filter(x => x.isEnabled === true).length ===
                              1}">
                            <svg width="15" height="15" class="button-icon">
                              <image xlink:href="${PlusSVG}" width="15" height="15" />
                            </svg>
                          </exo-card-button>
                          <exo-card-button card="cpt" withMenu settings operation="${this.operation}">
                            <svg width="15" height="15" class="button-icon">
                              <image xlink:href="${
                                this.operation === 'view' ? ContextualSVG : CloseButtonSVG
                              }" width="15" height="15" />
                            </svg>
                          </exo-card-button>
                        </div>
                      </div>

                    <br />
                    <div class="divider">
                      <span> CPT Code </span>
                      <span> Enable </span>
                    </div>
                    <ul class="items cpt paginated" id="cpt-list">
                    ${
                      this._data && this._data.exams && this.selectedExamType
                        ? this._data.billingCodes.results
                            .map(
                              code =>
                                `<exo-card-list-item
                            page="cpt"
                            name="${code.code}"
                            examTypeId="${this.selectedExamType}"
                            description="${code.description}"
                            technicalFee="${code.technicalFee}"
                            subText="${code.description}"
                            professionalFee="${code.professionalFee}"
                            operation="${this.operation}"
                            id="${code.id}"
                            disabled="${this._data.billingCodes.results.filter(x => x.isEnabled === true).length ===
                              1 && !code.isEnabled}"
                            enabled="${code.isEnabled}"
                            card="cpt"
                          > 
                            <div> ${code.description} </div>
                            <div class="subtext"> ${code.code} </div>
                          </exo-card-list-item>`,
                            )
                            .join('')
                        : ''
                    }
                    </ul>
                  </div>
                </horizontal-card>`
              : ''
          }
        </div>
      </div>
    `;
  }
}
customElements.define('exam-types-cpt-page', ExamTypesCPT);
