const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const eventBus = require('../../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();
const { FETCH_FACILITIES, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ name, facilityId, enabled, timezone }) => {
  try {
    await clinicDefinitionService.facilities({ facilityId }).patch({
      payload: {
        name,
        isEnabled: enabled,
        timezone,
      },
    });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Update saved',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_FACILITIES, {
    data: await clinicDefinitionService.facilities().get(),
  });
};
