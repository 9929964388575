const eventBus = require('../../utils/EventBus');
const { FETCH_LICENSES } = require('../../events');

const ITServiceAPI = require('../../services/api/itsetup');
const itServiceAPI = new ITServiceAPI();

module.exports = async ({ id }) => {
  await itServiceAPI.licenses().activate({ id });
  eventBus.fire(FETCH_LICENSES, {
    data: await itServiceAPI.licenses().fetch(),
  });
};
