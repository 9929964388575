/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const eventBus = require('../utils/EventBus');
const PlusSVG = require('../../assets/plus.svg');
const dropDownSVG = require('../../assets/dropdown.svg');
const ContextualSVG = require('../../assets/contextual.svg');
const CloseButtonSVG = require('../../assets/close-btn.svg');
const saveSVG = require('../../assets/save.svg');
const setScrollPosition = require('../utils/setScrollPosition');
const { FETCH_FACILITIES, FETCH_SYSTEMS, CACHE_US_UPDATE } = require('../events');
const dicomOptions = require('../utils/dicomOptions');

const clinicDefinitionService = new ClinicDefinitionAPI();
var newSpecialtySelect = null;
var newEnableDisable = null;
var specialtyIdEnableDisable = null;
class Systems extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this._useScroll = true;
    this.cache = {};
  }

  initCache(usSystemId) {
    this.cache.usSystemId = usSystemId;
  }

  async connectedCallback() {
    eventBus.registerOnce('UPDATE_CRUD_OPERATION', ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    this.listenForEvents(
      [
        { event: FETCH_SYSTEMS, key: 'systems' },
        { event: FETCH_FACILITIES, key: 'facilities' },
      ],
      eventBus,
    );

    eventBus.register(CACHE_US_UPDATE, ({ detail }) => {
      newEnableDisable = detail.ultrasoundSystems.enabled;
      specialtyIdEnableDisable = detail.ultrasoundSystems.specialtyId;
      this.initCache(detail.ultrasoundSystems.usSystemId);
    });

    eventBus.fire(FETCH_SYSTEMS, {
      data: await clinicDefinitionService.ultrasoundSystems().get(),
    });

    eventBus.fire(FETCH_FACILITIES, {
      data: await clinicDefinitionService.facilities().get({
        parameters: {
          is_enabled: true,
        },
      }),
    });
  }

  async selectedSpecialtyCall(specialtySelected, usId, enableDisable) {
    try {
      await clinicDefinitionService.ultrasoundSystems().patch({
        id: usId,
        payload: {
          isEnabled: enableDisable,
          specialtyId: Number(specialtySelected),
        },
      });
      eventBus.fire('TOAST', {
        items: [
          {
            type: 'SUCCESS',
            message: 'U/S System successfully updated!',
          },
        ],
      });
    } catch (error) {
      eventBus.fire('TOAST', {
        items: [
          {
            type: 'ERROR',
            message: error.message,
          },
        ],
      });
    } finally {
      eventBus.fire(FETCH_SYSTEMS, {
        data: await clinicDefinitionService.ultrasoundSystems().get(),
      });
    }
  }

  static get observedAttributes() {
    return ['operation'];
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  set listPosition(listPosition) {
    this.setAttribute('listPosition', listPosition);
  }

  get listPosition() {
    return this.getAttribute('listPosition');
  }

  async attachEvents() {
    if (this._data.systems) {
      this._data.systems.forEach((system) => {
        const facilitySelect = this.shadowRoot.getElementById(`facility.${system.id}`);
        const specialtySelect = this.shadowRoot.getElementById(`specialty.${system.id}`);
        const saveBtn = this.shadowRoot.getElementById('save-' + system.id);
        if (facilitySelect) {
          facilitySelect.addEventListener('change', () => {
            const newSpecialties = this._data.facilities
              .find((facility) => facility.id === Number(facilitySelect.value))
              .specialties.filter((specialty) => specialty.isEnabled);
            const specialtySelect = this.shadowRoot.getElementById(`specialty.${system.id}`);
            if (specialtySelect) {
              specialtySelect.innerHTML = `
                <option value="" disabled selected >No Specialty</option>
                ${newSpecialties
                  .map((specialty) => `<option value=${specialty.id}>${specialty.name}</option>`)
                  .join('')}`;
            }
          });
        }
        let checkSpeciality = false;
        if (specialtySelect) {
          specialtySelect.addEventListener('change', async () => {
            checkSpeciality = true;
            newSpecialtySelect = specialtySelect.value;
            this.cache.usSystemId = system.id;
          });
        }

        if (saveBtn) {
          saveBtn.addEventListener('click', async () => {
            if (system.id == this.cache.usSystemId) {
              if (checkSpeciality && newEnableDisable !== null) {
                this.selectedSpecialtyCall(newSpecialtySelect, system.id, newEnableDisable);
              } else if (checkSpeciality) {
                this.selectedSpecialtyCall(newSpecialtySelect, system.id, newSpecialtySelect.isEnabled);
              } else if (newEnableDisable !== null) {
                this.selectedSpecialtyCall(specialtyIdEnableDisable, system.id, newEnableDisable);
              }
            } else {
              eventBus.fire(FETCH_SYSTEMS, {
                data: await clinicDefinitionService.ultrasoundSystems().get(),
              });
            }
          });
        }
      });
    }
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        margin-right: -14.5%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .save {
        cursor: pointer;

      }

      .card-content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .button-icon {
        margin: 30% 5%;
      }

      .buttons {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        padding: 0 0 0 2%;
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
        height: 460px;
        overflow: auto;
        flex-grow: 1;
      }

      .divide {
        width: 25%;
      }

      .system-text {
        width: 25%;
      }

      select {
        border: 1px solid #007aff;
        background: #FFFFFF;
        border-radius: 10px;
        width: 100%;
        float: left;
        padding: 15px 20px;
        outline: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      
      .Enable {
        width: 10%;
        text-align: center;
        margin-left: -5px;
      }

      .Facility {
        width: 25%;
      }

      .highlight {
        color: #FB5654;
      }

      exo-card-button {
        position: relative;
      }

      .aeTitle {
        width: 27%;
        display: flex;
        background: #FFFFFF;
        border-radius: 5px;
        height: 45px;
        align-items: center;
        padding-left: 10px;
      }

      .systemTitle {
        width: 27%;
      }

      .select-wrapper {
        width: 90%;
        display: inline-block;
        position: relative;
      }
      
      .select-wrapper::before {
        content: url(${dropDownSVG});
        position: absolute;
        right: 5%;
        top: 25%;
        pointer-events: none;
      }

      .select-container {
        width: 25%;
        display: flex;
      }
    `;
  }

  handleScroll() {
    const list = this.shadowRoot.querySelector('.items');

    if (list) {
      list.addEventListener('scroll', (event) => {
        this.listPosition = setScrollPosition(event);
      });

      list.scrollTo({ top: this.listPosition });
    }
  }

  getDicomTag(tag) {
    const options = dicomOptions();
    const dicomTag = options.find((option) => option.group === tag.group && option.element === tag.element);

    if (dicomTag) {
      return dicomTag.value;
    } else {
      return '';
    }
  }

  renderExoCard({ title, divider, items, card, operation, placeholderTitle }) {
    return `
      <exo-card>
        <div class="card-content">
          <div class="head-section">
            <exo-card-title ${
              placeholderTitle ? `placeholderTitle="${placeholderTitle}"` : ''
            }title=${title}></exo-card-title>
            <div class="buttons">
              <exo-card-button card="${card}" facilityId="${this.selectedFacility}">
                <svg width="15" height="15" class="button-icon">
                  <image xlink:href="${PlusSVG}" width="15" height="15" />
                </svg>
              </exo-card-button>
              <exo-card-button card="${card}" withMenu settings operation="${operation}">
                <svg width="15" height="15" class="button-icon">
                  <image xlink:href="${operation === 'view' ? ContextualSVG : CloseButtonSVG}" width="15" height="15" />
                </svg>
              </exo-card-button>
            </div>
          </div>
          <div class="divider">
            ${divider
              .map(
                (d) =>
                  `<span class="divide ${d === 'U/S System AE title' ? 'systemTitle' : d}
                  ">${d}</span>`,
              )
              .join('')}
          </div>
          <ul class="items">
            ${items
              .sort((a, b) => (a.aeTitle > b.aeTitle ? 1 : -1))
              .map(
                (item) =>
                  `<exo-card-list-item
                operation="${operation}"
                id="${item.id}"
                card="${card}"
                enabled="${item.isEnabled}"
                name="${item.aeTitle}"
                specialtyId="${item.examSource ? item.examSource.specialty.id : ''}"
                dicomTag="${item.tags.length > 0 ? this.getDicomTag(item.tags[0]) : ''}"
              >
                <div slot='aeTitle' class="system-text aeTitle ${
                  item.examSource &&
                  this._data.facilities &&
                  this._data.facilities.some((facility) => facility.id === item.examSource.facility.id)
                    ? ''
                    : 'highlight'
                }">
                    ${item.aeTitle}</div>
                
                ${this._data.facilities ? this.renderFacilities(item) : ''}
                <div class="select-container">
                  <div class="select-wrapper">
                    <select id="specialty.${item.id}" class="${!this.getCurrentFacility(item) ? `highlight` : ''}">
                      ${this.renderSpecialtyOptions(item)}
                    </select>
                  </div>
                </div>
                <div>
                      ${this.saveButton(item.id)}
                </div>
              </exo-card-list-item>`,
              )
              .join('')}
          </ul>
        </div>
      </exo-card>
    `;
  }

  renderFacilities(item) {
    const itemFacility = this.getCurrentFacility(item);
    return `
      <div class="select-container">
        <div class="select-wrapper">
          <select id="facility.${item.id}" class="${!itemFacility ? `highlight` : ''}">
            <option value="" disabled selected>No facility</option>
            ${this._data.facilities.map((facility) => {
              return `
                  <option value=${facility.id} ${itemFacility === facility ? 'selected' : ''}>
                    ${facility.name}
                  </option>
                `;
            })}
          </select>
        </div>
      </div>
    `;
  }

  renderSpecialtyOptions(item) {
    const defaultOption = `<option value="" disabled selected>No Specialty</option>`;

    if (item.examSource && item.examSource.specialty) {
      const selectedSpecialty = item.examSource.specialty ? item.examSource.specialty.id : null;
      const selectedFacility = this.getCurrentFacility(item);

      return `
          ${defaultOption}
          ${
            selectedFacility
              ? selectedFacility.specialties.map(
                  (specialty) =>
                    `<option value="${specialty.id}" ${
                      selectedSpecialty && selectedSpecialty === specialty.id ? 'selected' : ''
                    }>
                      ${specialty.name}
                    </option>`,
                )
              : ''
          }
      `;
    } else {
      return defaultOption;
    }
  }

  getCurrentFacility(item) {
    return this._data.facilities
      ? this._data.facilities.find((facility) => item.examSource && facility.name === item.examSource.facility.name)
      : null;
  }

  saveButton(id) {
    return `
    <svg class="save" id="save-${id}" x="150%" y="100%" height="47" width="110">
    <image xlink:href="${saveSVG}"/>
    </svg>`;
  }

  render() {
    return `
      <div>
        <div class="title">U/S Systems</div>
      </div>
      ${
        this._data.systems
          ? this.renderExoCard({
              card: 'systems',
              placeholderTitle: 'Input your',
              title: 'System',
              divider: ['U/S System AE title', 'Enable', 'Facility', 'Specialty'],
              operation: this.operation,
              items: this._data.systems,
            })
          : `Loading...`
      }

    `;
  }
}

customElements.define('systems-page', Systems);
