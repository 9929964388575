const APIService = require('../APIService');
const formatError = require('../../../utils/formatError');

class MapTags extends APIService {
  constructor({ facilityId, specialtyId }) {
    super();
    this._url = `/clinic-definition/facilities/${facilityId}/specialties/${specialtyId}/tags`;
  }

  async getAll() {
    try {
      const { data } = await this.httpClient.get(this._url);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async get({ id }) {
    try {
      const { data } = await this.httpClient.get(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async patch({ id, payload }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/${id}/patch-specialty-tag`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async create({ payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async delete({ id }) {
    try {
      const { data } = await this.httpClient.delete(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async enableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/enable-all`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async disableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/disable-all`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }
}

module.exports = MapTags;
