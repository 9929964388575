/* eslint max-lines-per-function: 0 */
/* eslint @typescript-eslint/camelcase: 0 */
const editFacility = require('./editFacility');
const editSpeciality = require('./editSpecialty');
const editSystem = require('./editSystem');
const editExamType = require('./editExamType');
const editCPTCode = require('./editCPTCode');
const editUserGroup = require('./editUserGroup');
const editUser = require('./editUser');
const editTag = require('./editTag');

module.exports = ({ values, card }) => {
  switch (card) {
    case 'facilities': {
      editFacility({
        facilityId: values.facilityId,
        name: values.name,
        enabled: values.enabled,
        timezone: values.timezone,
      });
      break;
    }
    case 'specialities': {
      editSpeciality({
        specialtyId: values.specialtyId,
        name: values.name,
        enabled: values.enabled,
        facilityId: values.facilityId,
      });
      break;
    }
    case 'systems': {
      editSystem({
        systemId: values.systemId,
        aeTitle: values.aeTitle,
        enabled: values.enabled,
        specialtyId: Number(values.specialty),
        dicomTag: values.systemDicomTag,
      });
      break;
    }
    case 'examTypeCPT': {
      editExamType({
        name: values.name,
        id: values.examTypeId,
        enabled: values.enabled,
        category: values.category,
        abbrev: values.abbreviation,
      });
      break;
    }
    case 'cpt': {
      editCPTCode({
        id: values.id,
        name: values.name,
        technicalFee: values.technicalFee,
        subText: values.description,
        professionalFee: values.professionalFee,
        enabled: values.enabled,
        examTypeId: values.examTypeId,
        description: values.description,
      });
      break;
    }
    case 'ugroups': {
      editUserGroup({
        id: values.id,
        name: values.name,
        enabled: values.enabled,
      });
      break;
    }
    case 'users': {
      editUser({
        id: values.id,
        email: values.email,
        password: values.password,
        username: values.userName,
        groupId: values.groupId,
        firstName: values.firstName,
        lastName: values.lastName,
        specialtyId: values.specialtyId,
      });
      break;
    }
    case 'tags': {
      editTag({
        id: values.id,
        tag: values.tag,
        specialtyId: values.specialtyId,
        enabled: values.enabled,
      });
      break;
    }
  }
};
