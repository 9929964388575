const eventBus = require('../../utils/EventBus');
const sort = require('../../utils/sort');
const { ERROR, SUCCESS, TOAST, FETCH_PERMISSIONS } = require('../../events');
const AccessControlAPI = require('../../services/api/accesscontrol');

const accessControlService = new AccessControlAPI();
module.exports = async ({ card, values }) => {
  if (card === 'permissions') {
    const { groupId, payload } = values;
    try {
      await accessControlService
        .userGroups({ groupId })
        .permissions()
        .patch({ payload });

      eventBus.fire(TOAST, {
        items: [
          {
            type: SUCCESS,
            message: 'Update saved',
          },
        ],
      });
    } catch (error) {
      eventBus.fire(TOAST, {
        items: [
          {
            type: ERROR,
            message: 'Error saving update',
          },
        ],
      });
    } finally {
      eventBus.fire(FETCH_PERMISSIONS, {
        data: sort(
          await accessControlService
            .userGroups({ groupId })
            .permissions()
            .get(),
          'permissionsProfile',
        ),
      });
    }
  }
};
