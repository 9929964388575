const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const eventBus = require('../../utils/EventBus');

const clinicDefinitionService = new ClinicDefinitionAPI();
const { FETCH_SPECIALITIES, ERROR, TOAST, SUCCESS } = require('../../events');

module.exports = async ({ name, enabled, specialtyId, facilityId }) => {
  try {
    await clinicDefinitionService
      .facilities({
        facilityId,
      })
      .specialties({
        specialtyId,
      })
      .patch({
        payload: {
          name,
          isEnabled: enabled,
        },
      });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Update saved',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_SPECIALITIES, {
    data: await clinicDefinitionService
      .facilities({
        facilityId,
      })
      .specialties()
      .get(),
  });
};
