const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const eventBus = require('../../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();

const { FETCH_TAGS, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ tagId }) => {
  try {
    await clinicDefinitionService.globalTags({ tagId }).delete();
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Tag Deleted',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_TAGS, {
    data: await clinicDefinitionService.globalTags().getAll(),
  });
};
