const eventBus = require('../../utils/EventBus');
const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const clinicDefinitionAPI = new ClinicDefinitionAPI();
const { FETCH_TAGS, ERROR, TOAST } = require('../../events');

module.exports = async ({ specialtyId, id, tag, enabled }) => {
  try {
    await clinicDefinitionAPI
      .facilities()
      .specialties({ specialtyId })
      .mapTags({ tagId: id })
      .patch({
        payload: {
          tag,
          enabled,
        },
      });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_TAGS, {
    data: await clinicDefinitionAPI
      .facilities()
      .specialties({ specialtyId })
      .mapTags()
      .getAll(),
  });
};
