const {
  getAuthMethod,
  getAdfsSettings,
  getAutoLogoffMinutes,
  getAdminLogin,
  removeAdminLogin,
} = require('./authStorage');
const SSO = 'ADFS_OAUTH2';
const SAML = 'SAML';
const instance = require('./axios');
const getWebUrl = require('./getWebUrl');
const adminLogin = getAdminLogin();

const getSSOLogoutURL = (server, token) =>
  `https://${server}/adfs/oauth2/logout?post_logout_redirect_uri=${getRedirectURI()}&id_token_hint=${token}`;

module.exports = () => {
  const autoLogoffMinutes = getAutoLogoffMinutes();
  const autoLogoffSeconds = autoLogoffMinutes * 60 * 1000;
  let time;
  resetTimer();
  // DOM Events
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;

  function logout() {
    const authMethod = getAuthMethod();
    const adfs = JSON.parse(getAdfsSettings());
    if (authMethod === SAML && !adminLogin) {
      window.location.href = `https://cloud-hcs.${process.env.REACT_APP_ENV}.${process.env.REACT_APP_URL_SUFFIX}/api/v1/auth/saml/slo`;
    } else if (authMethod === SSO && adfs && !adminLogin) {
      const token = localStorage.getItem('id_token');
      localStorage.removeItem('id_token');
      instance
        .post('auth/logout/')
        .then(res => {
          if (token) {
            window.location.href = getSSOLogoutURL(adfsSettings.server, token);
          }
          return res;
        })
        .catch(err => err);
    } else {
      removeAdminLogin();
      instance
        .post('auth/logout/')
        .then(res => {
          window.location.href = getWebUrl();
          return res;
        })
        .catch(err => err);
    }
  }

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, autoLogoffSeconds);
  }
};
