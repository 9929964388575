const APIService = require('./APIService');

class DownloadCSV extends APIService {
  constructor() {
    super();
    this._templateUrl = '/csv-download-template';
    this._usersUrl = '/csv-download';
  }

  users() {
    return {
      get: async (facilityId, specialityId) => {
        const { data } = await this.httpClient.get(
          `${this._usersUrl}?facilityId=${facilityId}&specialityId=${specialityId}`,
        );
        return data;
      },
    };
  }

  template() {
    return {
      get: async () => {
        const { data } = await this.httpClient.get(this._templateUrl);
        return data;
      },
    };
  }
}

module.exports = DownloadCSV;
