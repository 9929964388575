const eventBus = require('../../utils/EventBus');
const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const clinicDefinitionAPI = new ClinicDefinitionAPI();
const { FETCH_TAGS } = require('../../events');

module.exports = async ({ specialtyId, id }) => {
  try {
    await clinicDefinitionAPI
      .facilities()
      .specialties({ specialtyId })
      .mapTags({ tagId: id })
      .delete();
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Tag Deleted',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_TAGS, {
    data: await clinicDefinitionAPI
      .facilities()
      .specialties({ specialtyId })
      .mapTags()
      .getAll(),
  });
};
