class BaseComponent extends HTMLElement {
  constructor({ isAsync } = { isAsync: false }) {
    super();
    this._innerStyles = this.styles();
    this.attachShadow({ mode: 'open' });
    this._data = {};
    this._async = isAsync;

    if (isAsync) {
      this.shadowRoot.innerHTML = `
        loading...
      `;
    } else {
      this.shadowRoot.innerHTML = `
        <style>
          ${this._innerStyles}
        </style>
        ${this.render()}
      `;
    }
  }

  async listenForEvents(events, eventBus) {
    events.forEach(({ event, key }) => {
      eventBus.register(event, async ({ detail }) => {
        this._data[key] = detail.data;
        this.shadowRoot.innerHTML = `
          <style>
            ${this.styles()}
          </style>
          ${this._async ? await this.render() : this.render()}
        `;
        if (this._useScroll) {
          this.handleScroll();
        }
        if (this._useEvents) {
          this.attachEvents();
        }
      });
    });
  }

  async attributeChangedCallback(_, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.shadowRoot.innerHTML = `
        <style>
          ${this.styles()}
        </style>
        ${this._async ? await this.render() : this.render()}
      `;
      if (this._useScroll) {
        this.handleScroll();
      }
      if (this._useEvents) {
        this.attachEvents();
      }
    }
  }

  handleScroll() {
    throw new Error('handleScroll method not implemented');
  }

  attachEvents() {
    throw new Error('attachEvents not defined');
  }

  styles() {
    throw new Error('Styles method not defined');
  }

  render() {
    throw new Error('Render method not implemented.');
  }
}

module.exports = BaseComponent;
