const eventBus = require('../../utils/EventBus');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const clinicDefinitionService = new ClinicDefinitionAPI();
const { FETCH_EXAM_TYPES, ERROR, TOAST, SUCCESS } = require('../../events');

module.exports = async ({ name, id, enabled, category, abbrev }) => {
  try {
    await clinicDefinitionService.examTypes().patch({
      payload: {
        id,
        name,
        isEnabled: enabled,
        examTypeCategory: category,
        abbrev,
      },
    });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Update saved',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_EXAM_TYPES, {
    data: await clinicDefinitionService.examTypes().get(),
  });
};
