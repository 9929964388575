const APIService = require('../APIService');
const LicensesService = require('./licenses');
const AuthenticationMethods = require('./authenticationMethods');
const InterfacesService = require('./interfaces');
const ExoLinkSettings = require('./exoLinkSettings');

class ITSetup extends APIService {
  constructor() {
    super();
    this._url = '/it-setup';
  }

  authenticationMethods() {
    const authenticationMethodsService = new AuthenticationMethods();
    return {
      fetch: async () => authenticationMethodsService.fetch(),
      multiPatch: async ({ authenticationMethods }) =>
        authenticationMethodsService.multiPatch({ authenticationMethods }),
    };
  }

  licenses() {
    const licensesService = new LicensesService();
    return {
      fetch: async () => licensesService.fetch(),
      activate: async ({ id }) => licensesService.activate({ id }),
      patch: async ({ id, thirdPartySystems, exoIris }) => licensesService.patch({ id, thirdPartySystems, exoIris }),
    };
  }

  interfaces() {
    const interfacesService = new InterfacesService();
    return {
      ldap: (id = null) => interfacesService.ldap(id),
      emr: (id = null) => interfacesService.emr(id),
      exoLink: () => interfacesService.exoLink(),
      sso: (id = null) => interfacesService.sso(id),
    };
  }

  exoLinkSettings() {
    const exoLinkSettingsService = new ExoLinkSettings();
    return {
      fetch: async () => exoLinkSettingsService.fetch(),
    };
  }
}

module.exports = ITSetup;
