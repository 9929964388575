const { verifyUser } = require('../operations/user');
const sessionService = require('../services/sessionService');

class ValidateUser extends HTMLElement {
  constructor() {
    super();
    const html = `
      <div class='validate'>
        Loading...
      </div>
    `;
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `
      ${html}
    `;
  }

  async connectedCallback() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('t')) {
      const result = await verifyUser({ token: params.get('t') });
      if (result.data) {
        sessionService.saveCurrentSession({
          currentUser: {
            user: result.data,
            token: params.get('t'),
          },
        });
        window.location = '/';
      }
    } else {
      window.history.back();
    }
  }
}

customElements.define('validate-user', ValidateUser);
