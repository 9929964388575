const secondsToMonths = seconds => {
  const minutes = seconds / 60;
  const days = Math.floor(minutes / 1440);
  const months = Math.ceil(days / 30);
  return months;
};

const monthsToSeconds = months => {
  const days = Math.ceil(months * 30);
  const minutes = Math.floor(days * 1440);
  return minutes * 60;
};

module.exports = {
  monthsToSeconds,
  secondsToMonths,
};
