const { getLocalUser, setLocalUser } = require('../utils/storage');

class SessionService {
  getCurrentSession() {
    const session = getLocalUser();
    return Object.keys(session).length > 0 && session;
  }

  saveCurrentSession({ currentUser }) {
    if (!currentUser.user || !currentUser.token) {
      throw new Error('currentUser needs to have a user and a token key');
    }
    return setLocalUser(currentUser);
  }
}

const sessionService = new SessionService();

module.exports = sessionService;
