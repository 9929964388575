module.exports = (parameters = {}) => {
  let queryParams = '';
  const paramKeys = Object.keys(parameters);
  if (paramKeys.length) {
    queryParams = '?';
    paramKeys.forEach((key, index) => {
      queryParams = `${queryParams}${key}=${parameters[key]}${paramKeys.length !== index + 1 ? '&' : ''}`;
    });
  }
  return queryParams;
};
