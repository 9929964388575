/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const {
  UPDATE_CRUD_OPERATION,
  UPDATE_SELECTED_FACILITY,
  FETCH_FACILITIES,
  FETCH_SPECIALITIES,
  FETCH_FACILITY_TIMEZONES,
} = require('../events');
const eventBus = require('../utils/EventBus');
const PlusSVG = require('../../assets/plus.svg');
const ContextualSVG = require('../../assets/contextual.svg');
const CloseButtonSVG = require('../../assets/close-btn.svg');
const timezones = require('../utils/timezones');
const clinicDefinitionService = new ClinicDefinitionAPI();

class Facilities extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .buttons {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }

      .button-icon {
        margin: 30% 5%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .card-container {
        display: flex;
      }

      exo-card {
        width: 25%;
        margin-right: 2%;
      }

      exo-card-button {
        position: relative;
      }

      exo-card-list-item:hover {
        cursor: pointer;
      }

      .facility-item {
        display: flex;
        flex-direction: column;
      }

      .timezone {
        font-family: HelveticaNowDisplay, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.5);
      }

      .selected.timezone {
        color: white;
      }

      .blur {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        pointer-events: none;
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }
    `;
  }

  async connectedCallback() {
    eventBus.register(UPDATE_CRUD_OPERATION, ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    eventBus.registerOnce(UPDATE_SELECTED_FACILITY, ({ detail }) => {
      this.selectedFacility = detail.facilityId;
    });

    this.listenForEvents(
      [
        { event: FETCH_FACILITIES, key: 'facilities' },
        { event: FETCH_SPECIALITIES, key: 'specialities' },
        { event: FETCH_FACILITY_TIMEZONES, key: 'timezones' },
      ],
      eventBus,
    );

    eventBus.fire(FETCH_FACILITIES, {
      data: await clinicDefinitionService.facilities().get(),
    });

    eventBus.fire(FETCH_FACILITY_TIMEZONES, {
      data: timezones,
    });
  }

  static get observedAttributes() {
    return ['selectedcard', 'operation', 'selectedfacility'];
  }

  set selectedCard(newSelectedCard) {
    this.setAttribute('selectedCard', newSelectedCard);
  }

  get selectedCard() {
    return this.getAttribute('selectedCard') || 'facilities';
  }

  set selectedFacility(newSelectedFacility) {
    this.setAttribute('selectedFacility', newSelectedFacility);
  }

  get selectedFacility() {
    return this.getAttribute('selectedFacility');
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  renderExoCard({ title, divider, items, card, operation }) {
    return `
      <exo-card class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}">
        <div class="head-section">
          <exo-card-title title=${title}></exo-card-title>
          <div class="buttons">
          <exo-card-button class="add" card="${card}" facilityId="${this.selectedFacility}">
            <svg width="15" height="15" class="button-icon">
              <image xlink:href="${PlusSVG}" width="15" height="15" />
            </svg>
          </exo-card-button>
          <exo-card-button card="${card}" withMenu settings operation="${operation}">
            <svg width="15" height="15" class="button-icon">
              <image xlink:href="${operation === 'view' ? ContextualSVG : CloseButtonSVG}" width="15" height="15" />
            </svg>
          </exo-card-button>
        </div>
        </div>

      
        <div class="divider">
          ${divider.map(d => `<span>${d}</span>`).join('')}
        </div>

        <ul class="items">
          ${items
            .map(
              item =>
                `<exo-card-list-item
              name="${item.name}
              "operation="${operation}"
              id="${item.id}"
              card="${card}"
              enabled="${item.hasOwnProperty('enabled') ? item.enabled : item.isEnabled}"
              selectedItem=${this.selectedFacility}
              facilityId="${this.selectedFacility}"
              ${card === 'facilities' ? `timezone="${item.timezone.timezone}"` : ''}
              ${card === 'facilities' ? `clickable="true"` : ''}
            >
            <div class="facility-item">
              <span>${item.name}</span>
              ${
                card === 'facilities'
                  ? `<span class="timezone ${this.selectedFacility === String(item.id) ? 'selected' : ''}">${
                      item.timezone ? item.timezone.timezone : ''
                    }</span>`
                  : ''
              }
            </div>
            </exo-card-list-item>`,
            )
            .join('')}
        </ul>

        ${
          operation === 'view' && items.length > 0
            ? `<div class="enable-all">
               <span class="enable-all-label">${
                 items.every(item => item.isEnabled || item.enabled) ? 'Disable All' : 'Enable All'
               }</span>
               <exo-toggle 
                 card="${card}"
                 facilityId="${this.selectedFacility}"
                 all
                 enabled="${items.every(item => item.isEnabled || item.enabled)}"
                ></exo-toggle>
              </div>`
            : ''
        }
      </exo-card>
    `;
  }

  render() {
    return `
      <div>
        <div class="title">Facilities & Specialities</div>
        <div class="card-container">
          ${
            this._data && this._data.facilities
              ? this.renderExoCard({
                  card: 'facilities',
                  title: 'Facility',
                  divider: ['Facility', 'Enabled'],
                  operation: this.selectedCard === 'facilities' ? this.operation : 'view',
                  items: this._data.facilities,
                })
              : `<span> Loading... </span>`
          }
          ${
            this._data && this._data.specialities
              ? this.renderExoCard({
                  card: 'specialities',
                  title: 'Specialities',
                  divider: ['Specialities', 'Enabled'],
                  operation: this.selectedCard === 'specialities' ? this.operation : 'view',
                  items: this._data.specialities,
                })
              : ''
          }
        </div>
      </div>
    `;
  }
}
customElements.define('facilities-page', Facilities);
