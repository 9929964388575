const AccessControlAPI = require('../../services/api/accesscontrol');
const eventBus = require('../../utils/EventBus');

const accessControlService = new AccessControlAPI();
const { FETCH_USER_GROUPS, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ name, enabled, id }) => {
  try {
    await accessControlService
      .userGroups({
        groupId: id,
      })
      .patch({
        payload: {
          name,
          enabled,
        },
      });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Update saved',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_USER_GROUPS, {
    data: await accessControlService.userGroups().get(),
  });
};
