const eventBus = require('../../utils/EventBus');
const { FETCH_LDAP_GROUP_USERS } = require('../../events');

const FacilitiesAPI = require('../../services/api/facilities');
const facilitiesAPI = new FacilitiesAPI();

module.exports = ({ id }) => {
  eventBus.fire(FETCH_LDAP_GROUP_USERS, {
    data: facilitiesAPI.getLdapGroupUsers(id),
  });
};
