import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@webcomponents/webcomponentsjs';
import '@webcomponents/custom-elements';
const routes = require('./routes');
const getWebUrl = require('./utils/getWebUrl');

// The DOMContentLoaded event is exclusive to when the entire HTML page loads. Therefore, this event is only fired once and only once, throughout the entirety of the web page's lifetime.
document.addEventListener('DOMContentLoaded', async function () {
  const rootDiv = document.getElementById('root');
  rootDiv.innerHTML = (await routes[window.location.pathname]()) || routes['/']();
  const closeButton = document.querySelector('#close');
  if (closeButton) {
    closeButton.addEventListener('click', () => {
      localStorage.clear();
      window.location.href = getWebUrl(); // TODO: once we have all our buckets update this
    });
  }
});
