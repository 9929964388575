const eventBus = require('../../utils/EventBus');
const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const { FETCH_BILLING_CODES, TOAST, ERROR, SUCCESS } = require('../../events');

const clinicDefinitionAPI = new ClinicDefinitionAPI();

module.exports = async ({ name, examTypeId, description, enabled, isCPTEnabled }) => {
  try {
    await clinicDefinitionAPI
      .examTypes({ examTypeId })
      .billingCodes()
      .create({
        payload: {
          code: name,
          description,
          isEnabled: enabled,
        },
      });
    // this should only be hit when there's a enabled CPT code
    if (isCPTEnabled === 'true') {
      const allBillingCodes = await clinicDefinitionAPI
        .examTypes({ examTypeId })
        .billingCodes()
        .getAll();
      // if new cpt code has enabled we disable all and enable the new cpt code
      if (enabled) {
        const billingCodeToEnable = allBillingCodes.results.find(x => x.code === name && x.description === description);
        if (billingCodeToEnable) {
          await clinicDefinitionAPI
            .examTypes({ examTypeId })
            .billingCodes()
            .disableAll();
          await clinicDefinitionAPI
            .examTypes({ examTypeId })
            .billingCodes({ billingCodeId: billingCodeToEnable.id })
            .enable();
        }
      }
    }
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Billing Code Created',
        },
      ],
    });
  } catch (error) {
    let { message } = error;

    if (error.message.includes('500')) {
      message = 'There has been an error, please make sure the code is unique.';
    }

    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_BILLING_CODES, {
    data: await clinicDefinitionAPI
      .examTypes({ examTypeId })
      .billingCodes()
      .getAll(),
  });
};
