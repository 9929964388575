const USER_KEY = 'Exo';

const get = key => localStorage[key];

const set = (key, value) => {
  localStorage[key] = value;
};

const getObject = key => (localStorage[key] ? JSON.parse(localStorage[key]) : null);

const setObject = (key, value) => {
  localStorage[key] = JSON.stringify(value);
};

const clearKey = () => {
  localStorage.clear();
};

const clear = () => {
  localStorage.clear();
};

const getLocalUser = () => getObject(USER_KEY) || {};

const getMenu = () =>
  getObject('MENU') || {
    menu: 'Institution',
    option: 'Facilities & Specialities',
  };

const setMenu = ({ menu, option }) => {
  setObject('MENU', {
    menu,
    option,
  });
};

const setLocalUser = async user => {
  await setObject(USER_KEY, user);
};

module.exports = {
  get,
  set,
  getObject,
  setObject,
  clearKey,
  clear,
  getLocalUser,
  setLocalUser,
  setMenu,
  getMenu,
};
