const returnProp = (obj, chain) => {
  if (chain.length) {
    return returnProp(obj[chain[0]], chain.slice(1));
  }

  return obj;
};

module.exports = (arr, fields) =>
  arr.sort((a, b) =>
    returnProp(a, fields)
      .toLowerCase()
      .localeCompare(returnProp(b, fields).toLowerCase()),
  );
