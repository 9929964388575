const eventBus = require('../../utils/EventBus');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const { FETCH_BILLING_CODES, TOAST, ERROR, SUCCESS } = require('../../events');

const clinicDefinitionAPI = new ClinicDefinitionAPI();

module.exports = async ({ name, enabled, technicalFee, professionalFee, examTypeId, id, description }) => {
  try {
    await clinicDefinitionAPI
      .examTypes({ examTypeId })
      .billingCodes({ billingCodeId: id })
      .patch({
        payload: {
          code: name,
          isEnabled: enabled,
          technicalFee,
          professionalFee,
          specialty: examTypeId,
          id,
          description,
        },
      });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Code Updated',
        },
      ],
    });
  } catch (error) {
    let { message } = error;

    if (error.message.includes('500')) {
      message = 'There has been an error, please make sure the code is unique.';
    }

    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_BILLING_CODES, {
    data: await clinicDefinitionAPI
      .examTypes({ examTypeId })
      .billingCodes()
      .getAll(),
  });
};
