let instance = null;
class EventBus {
  constructor() {
    if (!instance) {
      this._bus = document.createElement('div');
      this._events = [];
      instance = this;
    }
    return instance;
  }

  register(event, callback) {
    this._bus.addEventListener(event, callback);
    this._events = [...this._events, { event, callback }];
  }

  remove(event, callback) {
    this._bus.removeEventListener(event, callback);
    this._events = this._events.filter(evt => evt.event === event);
  }

  registerOnce(event, callback) {
    this.removeByName(event);
    this.register(event, callback);
  }

  removeByName(event) {
    this._events.filter(evt => {
      const isEventToRemove = evt.event === event;
      if (isEventToRemove) {
        this._bus.removeEventListener(evt.event, evt.callback);
      }
      return !isEventToRemove;
    });
  }

  fire(event, detail = {}) {
    this._bus.dispatchEvent(new CustomEvent(event, { detail }));
  }
}

module.exports = new EventBus();
