/* eslint max-lines-per-function: 0 */
const createFacility = require('./createFacility');
const createSpeciality = require('./createSpecialty');
const createSystem = require('./createSystem');
const createExamType = require('./createExamType');
const createCPTCode = require('./createCPTCode');
const createUserGroup = require('./createUserGroup');
const createUser = require('./createUser');
const createMapTag = require('./createMapTag');

module.exports = ({ values, card }) => {
  switch (card) {
    case 'facilities': {
      createFacility({
        name: values.name,
        timezone: values.timezone,
        enabled: values.enabled,
      });
      break;
    }
    case 'specialities': {
      createSpeciality({
        name: values.name,
        enabled: values.enabled,
        facilityId: values.facilityId,
      });
      break;
    }
    case 'systems': {
      createSystem({
        aeTitle: values.aeTitle,
        specialtyId: Number(values.specialty),
        dicomTag: values.systemDicomTag,
        enabled: values.enabled,
      });
      break;
    }
    case 'examTypeCPT': {
      createExamType({
        name: values.name,
        enabled: values.enabled,
        abbreviation: values.abbreviation,
        category: values.category,
      });
      break;
    }
    case 'cpt': {
      createCPTCode({
        name: values.name,
        technicalFee: values.technicalFee,
        professionalFee: values.professionalFee,
        enabled: values.enabled,
        examTypeId: values.examTypeId,
        description: values.description,
        isCPTEnabled: values.isCPTEnabled,
      });
      break;
    }
    case 'ugroups': {
      createUserGroup({
        name: values.name,
        enabled: values.enabled,
      });
      break;
    }
    case 'users': {
      createUser({
        email: values.email,
        password: values.password,
        username: values.userName,
        groupId: values.groupId,
        firstName: values.firstName,
        lastName: values.lastName,
        specialtyId: values.specialtyId,
        enabled: values.enabled,
      });
      break;
    }
    case 'tags': {
      createMapTag({
        tag: values.name,
        specialtyId: values.specialtyId,
        isEnabled: values.enabled,
      });
      break;
    }
  }
};
