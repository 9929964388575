const BaseComponent = require('./Base');
class ExoButton extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
  }

  styles() {
    return `
      button {
        display: flex;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, .1);
        border-radius: 5px;
        border: none;
        height: 30px;
        width: 30px;
        justify-content: center;
        ${this.card !== 'systems' ? 'padding-top: 20%;' : ''}
      }

      button:hover {
        cursor: pointer;
      }
    `;
  }

  get card() {
    return this.getAttribute('card');
  }

  set card(newCard) {
    this.setAttribute('card', newCard);
  }

  render() {
    return `
      <button><slot></slot></button>
    `;
  }
}

customElements.define('exo-button', ExoButton);
