import { Box } from '@chakra-ui/react';
import React from 'react';
import { TITLE_GREY, ERROR_RED } from './styles';

type UserFormHeaderProps = {
  isEdit?: boolean;
};

const UserFormHeader = ({ isEdit = false }: UserFormHeaderProps) => (
  <>
    <Box color={TITLE_GREY}>{isEdit ? 'Edit' : 'Create'}</Box>
    <Box fontSize={20} fontWeight={700}>
      {!isEdit && 'New'} User
    </Box>
    <Box fontSize={12} color={TITLE_GREY} fontWeight={500}>
      Fields marked with <span style={{ color: ERROR_RED }}>*</span> are required
    </Box>
  </>
);

export default UserFormHeader;
