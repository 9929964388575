/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Flex,
  Tag,
  TagCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  CheckboxGroup,
  Checkbox,
  Button,
  Image,
  Spacer,
} from '@chakra-ui/react';
import UserAPI from '../../services/api/user';
import Paper from '../Paper';
import { TITLE_GREY, BG_GREY } from './styles';
import SearchIcon from '../../../assets/search.svg';

const styles = {
  input: {
    border: 0,
    outline: 0,
    margin: '5px',
    fontWeight: 500,
    fontSize: '14px',
    background: 'aliceblue',
    cursor: 'pointer',
  },
  item_text: {
    margin: '5px',
    background: 'white',
    border: `1px solid ${TITLE_GREY}`,
  },
};

const UserListSearchAndFilters = ({
  setUsers,
  setIsFiltered,
  setTotalCount,
  currentPage,
  itemsPerPage = 12,
  setCurrentPage,
  isFiltered,
  showSuggestions,
  setShowSuggestions,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [dataInput, setDataInput] = useState(() => []);
  const [sizeInput, setSizeInput] = useState(() => 1);
  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([]);
  const [userSpecialtiesOptions, setUserSpecialtiesOptions] = useState([]);
  const [userRoleOptions, setUserRoleOptions] = useState([]);
  const [specialtyFilters, setSpecialtyFilters] = useState([]);
  const [roleFilters, setRoleFilters] = useState([]);
  const refInput = useRef(null);
  const userApi = new UserAPI();

  const suggestionItemStyles = {
    cursor: isHovered ? 'wait' : 'pointer',
  };

  const getRefInputComponent = (): HTMLInputElement | null => {
    if (refInput && refInput.current) {
      const refCurrent: HTMLInputElement = refInput.current;
      return refCurrent;
    }
    return null;
  };
  const getRefInputValue = () => {
    const refCurrent: HTMLInputElement | null = getRefInputComponent();
    return refCurrent ? refCurrent.value : '';
  };

  const resetRefInput = () => {
    const refCurrent: HTMLInputElement | null = getRefInputComponent();
    if (refCurrent) refCurrent.value = '';
  };

  const focusRefInput = () => {
    const refCurrent: HTMLInputElement | null = getRefInputComponent();
    if (refCurrent) refCurrent.focus();
  };

  const handleChangeInput = (e) => {
    const value = e.target.value;
    if (value.trim().length > 0) {
      setSizeInput(value.length);
    } else {
      resetRefInput();
    }
  };

  const handleDelItem = (index) => {
    const dataInputTemp = [...dataInput];
    dataInputTemp.splice(index, 1);
    setDataInput(() => [...dataInputTemp]);
  };

  const fetchAutoCompleteSuggestions = async (searchParam = '') => {
    try {
      if (searchParam.length > 2) {
        const { success = false, data = [] } = await userApi.getAutoCompleteSearchResults(searchParam);
        success && setAutoCompleteSuggestions(data);
        setShowSuggestions(true);
      } else {
        setAutoCompleteSuggestions([]);
        setShowSuggestions(false);
      }
    } catch (error) {}
  };

  const handleSuggestionClick = (suggestion = '') => {
    const updatedSuggestions = [...dataInput, suggestion];
    setDataInput(updatedSuggestions);
    resetRefInput();
  };

  const updateUsers = (users = [], count = 0) => {
    setUsers(users);
    setTotalCount(count);
  };

  const fetchFilteredUserList = async () => {
    if (dataInput.length >= 0) {
      const updatedSpecialtyFilters = specialtyFilters.map((specialtyFilter) => parseInt(specialtyFilter));
      const updatedRoleFilters = roleFilters.map((roleFilter) => parseInt(roleFilter));
      const { success = false, data: { count = 0, results = [] } = {} } = await userApi.getFilteredUserList(
        itemsPerPage,
        (currentPage - 1) * itemsPerPage,
        dataInput,
        updatedSpecialtyFilters,
        updatedRoleFilters,
      );
      success && updateUsers(results, count);
    }
  };

  const fetchUserCategories = async () => {
    try {
      const { results = [] } = await userApi.getUserCategories();
      return results;
    } catch (error) {
      return [];
    }
  };

  const fetchSpecialties = async () => {
    try {
      const { results = [] } = await userApi.getSpecialties();
      return results;
    } catch (error) {
      return [];
    }
  };

  const fetchInitialData = async () => {
    const [userCategories = [], specialties = []] = await Promise.all([fetchUserCategories(), fetchSpecialties()]);
    setUserRoleOptions(userCategories);
    setUserSpecialtiesOptions(specialties);
  };

  const handleReset = () => {
    setDataInput([]);
    setSizeInput(1);
    setAutoCompleteSuggestions([]);
    setShowSuggestions(false);
    setSpecialtyFilters([]);
    setRoleFilters([]);
    resetRefInput();
    setIsFiltered(false);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    fetchAutoCompleteSuggestions(getRefInputComponent()?.value);
  }, [getRefInputComponent()?.value]);

  useEffect(() => {
    if (dataInput.length === 0 && specialtyFilters.length === 0 && roleFilters.length === 0) {
      setIsFiltered(false);
    } else {
      setIsFiltered(true);
      currentPage === 1 && fetchFilteredUserList();
    }
    setCurrentPage(1);
  }, [dataInput, specialtyFilters, roleFilters]);

  useEffect(() => {
    if (isFiltered) fetchFilteredUserList();
  }, [currentPage]);

  useEffect(() => {
    focusRefInput();
    function handleKeyUp(event) {
      const newText = refInput?.current?.value.trim().replace(',', '');
      switch (event.key) {
        case ',':
          if (newText.length > 0) {
            const dataInputTemp = [...dataInput];
            dataInputTemp.push(newText);
            setDataInput(() => [...dataInputTemp]);
            resetRefInput();
          } else {
            resetRefInput();
          }
          break;
        case 'Enter':
          if (newText.length > 0) {
            const dataInputTemp = [...dataInput];
            dataInputTemp.push(newText);
            setDataInput(() => [...dataInputTemp]);
            resetRefInput();
          }
          break;
        case 'Backspace':
          if (dataInput.length > 0 && newText.length === 0) {
            const dataInputTemp = [...dataInput];
            dataInputTemp.pop();
            setDataInput([...dataInputTemp]);
          }
          break;
        default:
          break;
      }
    }
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [sizeInput, dataInput]);

  return (
    <Flex background={'white'} padding={'10px'} mb={'20px'} borderRadius={'5px'} gap={6} mt={5}>
      <Box width={'75%'}>
        <Flex minHeight={'36px'} border={`1px solid ${TITLE_GREY}`} borderRadius="5px" onClick={() => focusRefInput()}>
          <Image src={SearchIcon} w="13px" h="12px" ml={'10px'} mt={'10px'} />
          <Box>
            {dataInput.map((text, i) => (
              <Tag key={i + '_' + text} sx={styles.item_text}>
                {text}
                <TagCloseButton onClick={() => handleDelItem(i)} />
              </Tag>
            ))}
            <input ref={refInput} onChange={handleChangeInput} style={styles.input} size={sizeInput} />
          </Box>
          {dataInput.length > 0 && (
            <>
              <Spacer />
              <Button color={TITLE_GREY} height={'34px'} variant={'ghost'} onClick={() => setDataInput([])}>
                Clear All
              </Button>
            </>
          )}
        </Flex>
        <Box mt={1} fontSize={12} color={TITLE_GREY} fontWeight={500}>
          To perform your search press{' '}
          <div
            style={{
              display: 'inline-block',
              background: BG_GREY,
              height: '20px',
              padding: '0 10px',
              borderRadius: '4px',
              gap: '10px',
            }}
          >
            Enter
          </div>
          . Utilize a minimum of 3 characters to search.
        </Box>
        {autoCompleteSuggestions.length > 0 && showSuggestions && refInput?.current?.value?.length > 1 && (
          <Paper zIndex={'9999'} position={'absolute'} background={'white'} mt={'-25px'} overflowY="auto" height="33vh">
            {autoCompleteSuggestions.map((item, index) => (
              <ul style={suggestionItemStyles} onClick={() => handleSuggestionClick(item)} key={index}>
                {item}
              </ul>
            ))}
          </Paper>
        )}
      </Box>
      <Accordion width={'290px'} allowToggle>
        <AccordionItem border={'none'} background={'white'}>
          <h2 style={{ border: `1px solid ${TITLE_GREY}`, borderRadius: '5px', height: '36px' }}>
            <AccordionButton>
              <Box color={TITLE_GREY} fontSize={'12px'} fontWeight={500} as="span" flex="1" textAlign="left">
                Filter
              </Box>
              <AccordionIcon color={TITLE_GREY} />
            </AccordionButton>
          </h2>
          <AccordionPanel
            width={'290px'}
            zIndex={'9999'}
            position={'absolute'}
            background={'white'}
            paddingLeft={0}
            paddingRight={0}
            paddingTop={0}
            pb={4}
          >
            <Paper overflowY="auto" maxHeight="33vh">
              <Accordion allowMultiple>
                <AccordionItem border={'none'}>
                  <h2>
                    <AccordionButton>
                      <Box fontSize={'12px'} fontWeight={500} as="span" flex="1" textAlign="left">
                        Specialty
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel border={0} pb={4}>
                    <CheckboxGroup value={specialtyFilters} onChange={(value) => setSpecialtyFilters(value)}>
                      {userSpecialtiesOptions?.map((specialty) => (
                        <Box key={specialty.id}>
                          <Checkbox iconSize="14px" value={specialty?.id?.toString()}>
                            <Box fontSize={'12px'} fontWeight={500} as="span">
                              {specialty?.name}
                            </Box>
                          </Checkbox>
                        </Box>
                      ))}
                    </CheckboxGroup>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={'none'}>
                  <h2>
                    <AccordionButton>
                      <Box fontSize={'12px'} fontWeight={500} as="span" flex="1" textAlign="left">
                        Role
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <CheckboxGroup value={roleFilters} onChange={(value) => setRoleFilters(value)}>
                      {userRoleOptions?.map((role) => (
                        <Box fontSize={'12px'} fontWeight={500} key={role.id}>
                          <Checkbox iconSize="14px" value={role?.id?.toString()}>
                            <Box fontSize={'12px'} fontWeight={500} as="span">
                              {role?.name}
                            </Box>
                          </Checkbox>
                        </Box>
                      ))}
                    </CheckboxGroup>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Paper>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Button
        fontSize={'14px'}
        fontWeight={500}
        color={TITLE_GREY}
        borderColor={TITLE_GREY}
        height={'36px'}
        onClick={handleReset}
        variant="outline"
      >
        Reset
      </Button>
    </Flex>
  );
};

export default UserListSearchAndFilters;
