/* eslint-disable max-lines-per-function */
const clickFacility = require('./clickFacility');
const clickSpeciality = require('./clickSpeciality');
const clickLdapGroup = require('./clickLdapGroup');
const clickUser = require('./clickUser');
const clickLicense = require('./clickLicense');
const clickExamTypeCPT = require('./clickExamTypeCPT');
const clickUserGroup = require('./clickUserGroup');
const clickExamType = require('./clickExamType');
const clickWorksheet = require('./clickWorksheet');

module.exports = async ({ id, card, page, operation, parentId, groupId, specialtyId, worksheetId }) => {
  if (operation === 'view') {
    switch (card) {
      case 'facilities': {
        clickFacility({
          id,
          page,
        });
        break;
      }
      case 'specialities': {
        clickSpeciality({
          facilityId: parentId,
          id,
          page,
        });
        break;
      }
      case 'lgroup': {
        clickLdapGroup({
          id,
        });
        break;
      }
      case 'users': {
        clickUser({
          groupId,
          id,
          page,
        });
        break;
      }
      case 'licenses': {
        await clickLicense({
          id,
        });
        break;
      }
      case 'examTypeCPT': {
        clickExamTypeCPT({
          id,
        });
        break;
      }
      case 'ugroup':
      case 'ugroups': {
        clickUserGroup({
          id,
          page,
          specialtyId,
        });
        break;
      }
      case 'exams': {
        clickExamType({
          id,
          page,
        });
        break;
      }
      case 'worksheets': {
        clickWorksheet({
          examTypeId: id,
          specialtyId,
          parentId,
          id: worksheetId,
        });
        break;
      }
    }
  }
};
