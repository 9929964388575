const APIService = require('../APIService');

class CredentialedExamTypes extends APIService {
  constructor({ userId, id }) {
    super();
    this._url = `/access-control/users/${userId}/credentialed-exam-types/${id ? `/${id}` : ''}`;
  }

  async get() {
    try {
      const { data } = await this.httpClient.get(this._url);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async grant({ examTypesIds }) {
    try {
      const payload = {
        exam_type_ids: examTypesIds,
      };
      const { data } = await this.httpClient.post(`${this._url}grant`, payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async revoke({ examTypesIds }) {
    try {
      const payload = {
        exam_type_ids: examTypesIds,
      };
      const { data } = await this.httpClient.post(`${this._url}revoke`, payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async grantAll() {
    try {
      const creds = await this.get();
      const examTypesIds = creds.map(({ examType }) => examType.id);
      await this.grant({
        examTypesIds,
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  async revokeAll() {
    try {
      const creds = await this.get();
      const examTypesIds = creds.map(({ examType }) => examType.id);
      await this.revoke({
        examTypesIds,
      });
    } catch (error) {
      throw new Error(error);
    }
  }
}

module.exports = CredentialedExamTypes;
