/* eslint @typescript-eslint/camelcase: 0 */
const eventBus = require('../../utils/EventBus');
const { FETCH_SPECIALITIES, UPDATE_SELECTED_FACILITY, UPDATE_SELECTED_SPECIALTY } = require('../../events');
const FacilitiesAPI = require('../../services/api/facilities');
const facilitiesAPI = new FacilitiesAPI();

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');

const clinicDefinitionService = new ClinicDefinitionAPI();
module.exports = async ({ id, page }) => {
  const mockedPages = [];
  const onlyEnabled = ['tags', 'workflow', 'exams'];
  const resetSelectedSpecialtyForPages = ['tags'];
  let parameters = {};

  if (onlyEnabled.includes(page)) {
    parameters = {
      is_enabled: true,
    };
  }
  if (mockedPages.includes(page)) {
    eventBus.fire(FETCH_SPECIALITIES, {
      data: facilitiesAPI.getSpecialities(id),
    });
  } else {
    eventBus.fire(FETCH_SPECIALITIES, {
      data: await clinicDefinitionService
        .facilities({
          facilityId: id,
        })
        .specialties()
        .get({ parameters }),
    });
  }

  eventBus.fire(UPDATE_SELECTED_FACILITY, {
    facilityId: id,
  });

  if (resetSelectedSpecialtyForPages.includes(page)) {
    eventBus.fire(UPDATE_SELECTED_SPECIALTY, {
      specialtyId: null,
    });
  }
};
