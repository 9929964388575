const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const eventBus = require('../../utils/EventBus');

const clinicDefinitionService = new ClinicDefinitionAPI();
const { FETCH_SPECIALITIES, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ name, enabled, facilityId }) => {
  try {
    await clinicDefinitionService
      .facilities({
        facilityId,
      })
      .specialties()
      .create({
        payload: {
          name,
          isEnabled: enabled,
          code: 'CCC',
        },
      });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Specialty Created',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_SPECIALITIES, {
    data: await clinicDefinitionService
      .facilities({
        facilityId,
      })
      .specialties()
      .get(),
  });
};
