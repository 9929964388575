const AccessControlAPI = require('../../services/api/accesscontrol');
const eventBus = require('../../utils/EventBus');

const accessControlService = new AccessControlAPI();
const { FETCH_USER_GROUPS, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ name, enabled }) => {
  try {
    await accessControlService.userGroups().create({
      payload: {
        name,
        enabled,
        defaultQaPercentageRequired: 0,
      },
    });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'User Group Created',
        },
      ],
    });
    eventBus.fire(FETCH_USER_GROUPS, {
      data: await accessControlService.userGroups().get(),
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
};
