const BaseService = require('./base');
const APIService = require('./APIService');
const formatError = require('../../utils/formatError');

const adminUsersUrl = 'access-control/admin-users';

class UserAPI extends APIService {
  constructor() {
    super();
    this._baseService = new BaseService();
    this.getAccessControl = this.getAccessControl.bind(this);
  }

  get baseService() {
    return this._baseService;
  }

  async getUserDetails(id) {
    try {
      const { data = {} } = await this.httpClient.get(`${adminUsersUrl}/${id}`);
      return { userDetails: data, success: true };
    } catch (error) {
      return { errors: error?.response?.data, success: false };
    }
  }

  async createUser(payload) {
    try {
      await this.httpClient.post(`${adminUsersUrl}`, payload, {
        'Content-Type': 'multipart/form-data',
      });
      return { success: true };
    } catch (error) {
      return { errors: error?.response?.data, success: false };
    }
  }

  async editUser(id,payload) {
    try {
      await this.httpClient.patch(`${adminUsersUrl}/${id}`, payload, {
        'Content-Type': 'multipart/form-data',
      });
      return { success: true };
    } catch (error) {
      return { errors: error?.response?.data, success: false };
    }
  }

  async getSpecialties() {
    try {
      const { data } = await this.httpClient({
        method: 'GET',
        url: 'clinic-definition/specialties',
      });
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async getUserCategories() {
    try {
      const { data } = await this.httpClient({
        method: 'GET',
        url: `access-control/user-categories`,
      });
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async getAllUser(limit, offset) {
    try {
      const { data } = await this.httpClient({
        method: 'GET',
        url: `${adminUsersUrl}?limit=${limit}&offset=${offset}`,
      });
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async getAutoCompleteSearchResults(searchParams = '') {
    try {
      const { data } = await this.httpClient({
        method: 'GET',
        url: `${adminUsersUrl}/autocomplete/?search=${searchParams}`,
      });
      return { success: true, data: data?.results ?? [] };
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async getFilteredUserList(itemsPerPage = 12, offset = 0, search = [], specialties = [], roles = []) {
    const payload = { search, specialties, roles };
    try {
      const { status = 500, data = [] } = await this.httpClient.post(
        `${adminUsersUrl}/searchuser?limit=${itemsPerPage}&offset=${offset}`,
        payload,
        {
          'Content-Type': 'application/json',
        },
      );
      const success = status === 200;
      return { success, data };
    } catch (error) {
      return { errors: error?.response?.data, success: false };
    }
  }

  async getCurrentUser() {
    const { data } = await this.httpClient({
      method: 'GET',
      url: `access-control/users/current`,
    });
    return data;
  }

  async getAccessControl({ id }) {
    return await this._baseService.httpClient({
      method: 'GET',
      url: `/access-control/users/${id}`,
    });
  }
}

module.exports = UserAPI;
