/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const eventBus = require('../utils/EventBus');
const { TOAST } = require('../events.js');

class ExoToast extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
    eventBus.register(TOAST, ({ detail }) => {
      this.hide = false;
      this.items = JSON.stringify(detail.items);

      setTimeout(() => {
        this.hide = true;
      }, (detail.seconds && detail.seconds * 1000) | 2000);
    });
  }

  static get observedAttributes() {
    return ['hide', 'items'];
  }

  styles() {
    return `
      .container {
        position: absolute;
        right: 3%;
        width: 25%;
        bottom: 3%;
        display: flex;
        flex-direction: column;
      }

      .toast {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        min-height: 50px;
        box-sizing: border-box;
        color: white;
        margin-bottom: 25px;
        padding: 5px;
      }

      .hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 5s, opacity 5s linear;
      }

      .visible {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s 5s, opacity 5s linear;
      }

      .error {
        background-color: #ff5a5a
      }

      .warning {
        background-color: #ffbf00;
      }

      .success {
        background-color: #04AC7D;
      }
    `;
  }

  set hide(newHide) {
    this.setAttribute('hide', newHide);
  }

  get hide() {
    const hideAttr = this.getAttribute('hide');
    return hideAttr === 'true' || hideAttr === '';
  }

  set items(newItems) {
    this.setAttribute('items', newItems);
  }

  get items() {
    return this.getAttribute('items');
  }

  render() {
    return `
    <div class="container">
      ${
        this.items
          ? JSON.parse(this.items)
              .map(
                item => `<div class="toast ${this.hide ? 'hidden' : 'visible'} ${item.type.toLowerCase()}">
                       <span>${item.message}</span>
                     </div>`,
              )
              .join('')
          : ''
      }
    </div>`;
  }
}

customElements.define('exo-toast', ExoToast);
