const APIService = require('../APIService');
const sortBy = require('../../../utils/sort');
const formatError = require('../../../utils/formatError');

class UserSecutirySettings extends APIService {
  constructor({ id }) {
    super();
    this._id = id;
    this._url = `/clinic-definition/user-security-settings${id ? `/${id}` : ''}`;
  }

  async get() {
    try {
      const { data } = await this.httpClient.get(this._url);
      const sortedResults = sortBy(data.results, 'id');
      return sortedResults.length ? sortedResults[0] : {};
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async save({ payload }) {
    try {
      const { id } = payload;
      const idAsInt = parseInt(id);
      let data = {};
      if (!idAsInt) {
        data = await this.httpClient.post(this._url, payload).data;
      } else {
        data = await this.httpClient.patch(`${this._url}/${idAsInt}`, payload);
      }
      return data;
    } catch (error) {
      throw new Error(error.response?.data.autoLogoffMinutes[0]);
    }
  }
}

module.exports = UserSecutirySettings;
