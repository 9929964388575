const AccessControlAPI = require('../../services/api/accesscontrol');
const eventBus = require('../../utils/EventBus');

const accessControlService = new AccessControlAPI();
const { FETCH_ALL_USERS, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ id }) => {
  try {
    await accessControlService
      .userGroups()
      .users({ id })
      .delete();
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'User Deleted',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error,
        },
      ],
    });
  }
  eventBus.fire(FETCH_ALL_USERS, {
    data: await accessControlService
      .userGroups()
      .users()
      .get(),
  });
};
