const eventBus = require('../../utils/EventBus');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const clinicDefinitionService = new ClinicDefinitionAPI();
const { FETCH_EXAM_TYPES, ERROR, TOAST, SUCCESS } = require('../../events');

module.exports = async ({ name, enabled, abbreviation, category }) => {
  try {
    await clinicDefinitionService
      .examTypes()
      .create({ payload: { name, isEnabled: enabled, abbrev: abbreviation, examTypeCategory: category } });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Exam Type Created',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_EXAM_TYPES, {
    data: await clinicDefinitionService.examTypes().get(),
  });
};
