import React from 'react';
import { Flex, Heading, Spacer, Button, Stack, ButtonProps } from '@chakra-ui/react';

/**
 *
 */
type UserTabHeaderProps = {
  pageTitle: string;
  actionButtons?: ButtonProps[] | any;
};
const UserTabHeader: React.FC<UserTabHeaderProps> = ({ pageTitle, actionButtons }) => {
  return (
    <Flex py="4">
      <Heading fontSize={34} fontWeight={700}>
        {pageTitle}
      </Heading>
      <Spacer />
      <Stack spacing={4} direction="row">
        {actionButtons?.map((button) => (
          <Button key={button.title} {...button}>
            {button.title}
          </Button>
        ))}
      </Stack>
    </Flex>
  );
};
// #endregion

export default UserTabHeader;
