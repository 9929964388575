const axios = require('axios').default;
const INVALID_CODE = 'token_not_valid';
const FORBIDDEN = 'You do not have permission to perform this action.';
const NO_CREDENTIALS = 'Authentication credentials were not provided.';
const INVALID_SESSION = 'invalid session.';
const { getAdfsSettings, getAuthMethod, getAdminLogin, removeAdminLogin } = require('./authStorage');
const getWebUrl = require('./getWebUrl');
const checkIfFromEmail = require('./checkIfFromEmail');

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PATH || ''}`,
  timeout: 100000,
  withCredentials: true,
});

const SSO = 'ADFS_OAUTH2';
const SAML = 'SAML';
const adminLogin = getAdminLogin();
let isRefreshing = false;
let failedQueue = [];
let refreshTokenPromise;

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

/* eslint-disable max-lines-per-function */
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;

    if (
      error.response?.data.detail === NO_CREDENTIALS ||
      error.response?.data.detail === FORBIDDEN ||
      error.response?.data.error === INVALID_SESSION
    ) {
      if (checkIfFromEmail()) {
        window.location.href = `${getWebUrl()}?fromemail=true`;
      } else {
        if (adminLogin) {
          removeAdminLogin();
        }
        window.location.href = getWebUrl();
      }
    }

    if (error.response?.data.code === INVALID_CODE && originalRequest && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function(resolve, reject) {
        if (!refreshTokenPromise) {
          const authMethod = getAuthMethod();
          const adfsSettingsString = getAdfsSettings();
          const adfsSettings = JSON.parse(adfsSettingsString);
          // Change to SSO when endpoint fixed
          if (authMethod === SAML && !adminLogin) {
            window.location.href = `https://cloud-hcs.${process.env.REACT_APP_ENV}.${process.env.REACT_APP_URL_SUFFIX}/api/v1/auth/saml/slo`;
          } else if (authMethod === SSO && adfsSettings && !adminLogin) {
            const body = {
              grant_type: 'refresh_token',
              client_id: adfsSettings.clientId,
              redirect_uri: getRedirectURI(),
              refresh_token: '',
              url: `https://${adfsSettings.server}/adfs/oauth2/token`,
            };
            refreshTokenPromise = instance
              .post('/adfs-access-token-proxy', body)
              .then(result => {
                const token = result.data.accessToken;
                processQueue(null, token);
                resolve(instance(originalRequest));
                refreshTokenPromise = null;
              })
              .catch(error => {
                refreshTokenPromise = null;
                processQueue(error, null);
                reject(error);
              })
              .finally(() => {
                isRefreshing = false;
              });
          } else {
            refreshTokenPromise = instance
              .post('/auth/token/refresh/')
              .then(result => {
                const token = result.data.access;
                processQueue(null, token);
                resolve(instance(originalRequest));
                refreshTokenPromise = null;
              })
              .catch(error => {
                refreshTokenPromise = null;
                processQueue(error, null);
                reject(error);
              })
              .finally(() => {
                isRefreshing = false;
              });
          }
        }
      });
    }
    return Promise.reject(error);
  },
);

module.exports = instance;
