const eventBus = require('../../utils/EventBus');
const { FETCH_WORKSHEETS, FETCH_SPECIALTY_EXAM_TYPES, CACHE_WORKSHEET_UPDATE } = require('../../events');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const clinicDefinitionService = new ClinicDefinitionAPI();

module.exports = async ({ id, examTypeId, specialtyId, parentId }) => {
  const msg = {
    worksheet: {
      facilityId: parentId,
      specialtyId: specialtyId,
      id: id,
      examTypeId: examTypeId,
    },
  };
  eventBus.fire(CACHE_WORKSHEET_UPDATE, msg);

  eventBus.fire(FETCH_WORKSHEETS, {
    data: await clinicDefinitionService.examWorksheets().getAll({ parameters: { isPublished: true } }),
  });
  eventBus.fire(FETCH_SPECIALTY_EXAM_TYPES, {
    data: await clinicDefinitionService.examTypes({ facilityId: parentId, specialtyId }).get(),
  });
};
