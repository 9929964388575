const eventBus = require('../../utils/EventBus');

const { FETCH_SYSTEMS, TOAST, ERROR, SUCCESS } = require('../../events');
const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const clinicDefinitionAPI = new ClinicDefinitionAPI();
module.exports = async ({ systemId }) => {
  try {
    await clinicDefinitionAPI.ultrasoundSystems().delete({ id: systemId });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Deleted Successfully',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_SYSTEMS, {
    data: await clinicDefinitionAPI.ultrasoundSystems().get(),
  });
};
