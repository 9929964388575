/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const ITServiceAPI = require('../services/api/itsetup');
const UserAPI = require('../services/api/user');
const { FETCH_LICENSES, TOAST, ERROR, SUCCESS } = require('../events');
const eventBus = require('../utils/EventBus');
const itServiceAPI = new ITServiceAPI();
const userAPI = new UserAPI();

const LICENSE_EDIT_PERMISSIONS = ['LICENSE_ACCESS', 'LICENSE_ALL_ACCESS'];

class Licenses extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
  }

  attachEvents() {
    const getInputByName = name => {
      return this.shadowRoot.querySelector(`input[name="${name}"]`);
    };

    const getInputValueByName = name => {
      const el = getInputByName(name);
      return el && el.value;
    };

    const thirdParty = getInputByName('thirdParty');

    thirdParty.addEventListener('focusout', async () => {
      try {
        await itServiceAPI.licenses().patch({
          id: this.selectedLicense().id,
          thirdPartySystems: getInputValueByName('thirdParty'),
        });
        eventBus.fire(TOAST, {
          items: [
            {
              type: SUCCESS,
              message: 'Configuration change saved',
            },
          ],
        });
      } catch (error) {
        eventBus.fire(TOAST, {
          items: [
            {
              type: ERROR,
              message: 'There has been an error saving this configuration.',
            },
          ],
        });
      }
    });
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      input {
        border:none;
        margin-left: 5%;
        border-bottom: 1px solid #DBDBDB;
        padding: 5px 10px;
        width: 80%;
        outline: none;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }

      label {
        text-align: left;
        margin-left: 5%;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #dbdbdb;
      }

      .buttons {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }

      .button-icon {
        margin: 30% 5%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .card-container {
        display: flex;
      }

      exo-card {
        width: 25%;
        margin-right: 2%;
      }

      exo-card-button {
        position: relative;
      }

      exo-card-list-item:hover {
        cursor: pointer;
      }

      .blur {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }
    `;
  }

  async connectedCallback() {
    this.listenForEvents([{ event: FETCH_LICENSES, key: 'licenses' }], eventBus);
    try {
      eventBus.fire(FETCH_LICENSES, {
        data: await itServiceAPI.licenses().fetch(),
      });
    } catch (error) {
      this.connectionError = true;
    }

    const currentUser = await userAPI.getCurrentUser();
    this.currentUser = currentUser;
  }

  static get observedAttributes() {
    return ['selectedcard', 'operation', 'connectionerror'];
  }

  set connectionError(newConnectionError) {
    this.setAttribute('connectionError', newConnectionError);
  }

  get connectionError() {
    return this.getAttribute('connectionError');
  }

  set selectedCard(newSelectedCard) {
    this.setAttribute('selectedCard', newSelectedCard);
  }

  get selectedCard() {
    return this.getAttribute('selectedCard') || 'facilities';
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  selectedLicense() {
    return this._data && this._data.licenses && this._data.licenses.find(license => license.isActive);
  }

  async userCanEdit() {
    if (this.currentUser) {
      return LICENSE_EDIT_PERMISSIONS.some(permissionName => this.currentUser.permissionNames.includes(permissionName));
    }
  }

  renderExoCard({ title, divider, items, card, operation, disabled }) {
    return `
      <exo-card class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}">
        <div class="head-section">
          <exo-card-title title=${title}></exo-card-title>
        </div>

      
        <div class="divider">
          ${divider.map(d => `<span>${d}</span>`).join('')}
        </div>

        <ul class="items">
          ${items
            .map(
              item =>
                `<exo-card-list-item
              name="${item.name}
              operation="${operation}"
              id="${item.id}"
              card="${card}"
              enabled="${item.isActive}"
              disabled=${disabled}
              radio
            >
              ${item.name}
            </exo-card-list-item>`,
            )
            .join('')}
        </ul>
      </exo-card>
    `;
  }

  render() {
    const selectedLicense = this.selectedLicense();
    const userCanEdit = this.userCanEdit();
    return `
      <div>
        <div class="title">Licenses</div>
        <div class="card-container">
          ${
            this._data && this._data.licenses
              ? this.renderExoCard({
                  card: 'licenses',
                  title: 'Configuration',
                  divider: ['License Package', 'Enable'],
                  operation: 'view',
                  items: this._data.licenses,
                  disabled: !userCanEdit,
                })
              : `<span> ${
                  this.connectionError
                    ? 'There has been a connection error to the service, please try again later.'
                    : 'Loading...'
                } </span>`
          }
          ${
            selectedLicense
              ? `
              <exo-card>
                <div class="head-section">
                  <exo-card-title title="U/S Systems">
                  </exo-card-title>
                </div>
                 <label for="thirdParty">3rd party U/S Systems</label>
                 <input name="thirdParty" type="number" ${userCanEdit ? '' : 'disabled'} autocomplete="false" value="${
                  selectedLicense.thirdPartySystems
                }">
              </exo-card>
            `
              : ''
          }
        </div>
      </div>
    `;
  }
}
customElements.define('licenses-page', Licenses);
