const APIService = require('../APIService');

const createErrorMessage = error => {
  let errorMessage = '';
  if (error.response && error.response.data) {
    Object.keys(error.response.data).forEach(key => {
      errorMessage = `${errorMessage} ${key}: ${error.response.data[key]} \n`;
    });
  } else {
    errorMessage = 'There has been an error. Please make sure the values are correct.';
  }
  return errorMessage;
};

class Interfaces extends APIService {
  constructor() {
    super();
    this._url = '/it-setup/interface-settings';
    this._ldapUrl = '/it-setup/exo-link-settings/ldap/';
  }

  ldap() {
    return {
      get: async () => {
        try {
          const { data } = await this.httpClient.get(`${this._ldapUrl}`);
          return data;
        } catch (error) {
          throw new Error(error);
        }
      },
      save: async ({ ldap }) => {
        try {
          const { data } = await this.httpClient.post(`${this._ldapUrl}`, ldap);
          return data;
        } catch (error) {
          throw new Error(createErrorMessage(error));
        }
      },
    };
  }

  exoLink() {
    return {
      get: async () => {
        try {
          const { data } = await this.httpClient.get(`${this._url}/exo-link`);
          return data;
        } catch (error) {
          throw new Error(error);
        }
      },
      save: async ({ exoLink }) => {
        try {
          const { data } = await this.httpClient.post(`${this._url}/exo-link`, exoLink);
          return data;
        } catch (error) {
          throw new Error(createErrorMessage(error));
        }
      },
    };
  }

  sso(id = null) {
    return {
      get: async () => {
        try {
          const { data } = await this.httpClient.get(`${this._url}/sso-adfs`);
          return data;
        } catch (error) {
          throw new Error(error);
        }
      },
      save: async ({ sso }) => {
        try {
          const { data } = await this.httpClient.patch(`${this._url}/sso-adfs/${id}`, sso);
          return data;
        } catch (error) {
          throw new Error(createErrorMessage(error));
        }
      },
    };
  }

  emr(id = null) {
    return {
      get: async () => {
        try {
          const { data } = await this.httpClient.get(`${this._url}/emr`);
          return data;
        } catch (error) {
          throw new Error(error);
        }
      },
      save: async ({ emr }) => {
        try {
          const { data } = await this.httpClient.patch(`${this._url}/emr/${id}`, emr);
          return data;
        } catch (error) {
          throw new Error(createErrorMessage(error));
        }
      },
    };
  }
}

module.exports = Interfaces;
