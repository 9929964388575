const APIService = require('../APIService');
const urlParams = require('../../../utils/urlParams');
const formatError = require('../../../utils/formatError');

class Specialties extends APIService {
  constructor({ facilityId }) {
    super();
    this._url = `/clinic-definition/facilities/${facilityId}/specialties`;
  }

  async get({ parameters } = { parameters: {} }) {
    try {
      const { data } = await this.httpClient.get(`${this._url}${urlParams(parameters)}`);
      return data.results;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async patch({ id, payload }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/${id}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async create({ payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async delete({ id }) {
    try {
      const { data } = await this.httpClient.delete(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async enableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/enable-all`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async disableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/disable-all`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }
}

module.exports = Specialties;
