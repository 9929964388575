const eventBus = require('../../utils/EventBus');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const clinicDefinitionService = new ClinicDefinitionAPI();
const { FETCH_EXAM_TYPES, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ id }) => {
  try {
    await clinicDefinitionService.examTypes().delete({ examTypeId: id });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Exam Type Deleted',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_EXAM_TYPES, {
    data: await clinicDefinitionService.examTypes().get(),
  });
};
