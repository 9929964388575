const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const eventBus = require('../../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();

const { FETCH_FACILITIES, TOAST, ERROR, SUCCESS } = require('../../events');
const DISABLED = 'DISABLED';

module.exports = async ({ facilityId }) => {
  try {
    const result = await clinicDefinitionService.facilities({ facilityId }).delete();
    if (result.message?.code === DISABLED) {
      eventBus.fire(TOAST, {
        items: [
          {
            type: SUCCESS,
            message: 'Facility has been disabled because it has one or more dependencies in the system',
          },
        ],
      });
    } else {
      eventBus.fire(TOAST, {
        items: [
          {
            type: SUCCESS,
            message: 'Facility Deleted',
          },
        ],
      });
    }
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_FACILITIES, {
    data: await clinicDefinitionService.facilities().get(),
  });
};
