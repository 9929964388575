const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');

const eventBus = require('../../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();

const { FETCH_FACILITIES, ERROR, TOAST, SUCCESS } = require('../../events');

module.exports = async ({ name, enabled, timezone }) => {
  try {
    await clinicDefinitionService.facilities().create({
      payload: {
        name,
        customerId: 'SHSH',
        isEnabled: enabled,
        timezone,
      },
    });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Facility Created',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_FACILITIES, {
    data: await clinicDefinitionService.facilities().get(),
  });
};
