import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import React from 'react';

const ErrorReport = ({ errors, isErrorModalOpen, setIsErrorModalOpen }) => {
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <Modal isOpen={isErrorModalOpen} size={'xl'} scrollBehavior={'inside'} onClose={handleCloseErrorModal} isCentered>
        <ModalOverlay />
        <ModalContent maxW="40vw" maxH="50vh">
          <ModalHeader
            sx={{
              fontFamily: 'Helvetica',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '23.52px',
              letterSpacing: '0.23px',
              color: 'rgba(44, 44, 46, 1)',
            }}
          >
            Error Report
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {errors.map((error, index) => (
                <Text
                  sx={{
                    fontFamily: 'Helvetica',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '20.58px',
                    letterSpacing: '0.2px',
                    color: 'rgba(44, 44, 46, 0.6)',
                  }}
                  key={index}
                >
                  {error}
                </Text>
              ))}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ErrorReport;
