// eslint-disable-next-line
const ValidateUser = require('../components/ValidateUser');

module.exports = () => `
  <div id="container">
    <div id="sidebar">
      <div class="sidebar-title"></div>
    </div>
    <div id="content">
      <validate-user />
    </div>
  </div>
`;
