const axios = require('axios').default;
let instance = null;

class BaseService {
  constructor() {
    if (!instance) {
      instance = this;
      const baseURL = `${process.env.REACT_APP_API_URL || ''}${process.env.REACT_APP_API_PATH || ''}`;
      instance._httpClient = axios;
      instance._httpClient.defaults.baseURL = baseURL || '';
    }
    return instance;
  }

  setAuthToken({ token }) {
    instance._httpClient.defaults.headers.common['Authorization'] = token;
  }

  removeAuthToken() {
    delete instance._httpClient.defaults.headers.common['Authorization'];
  }

  get httpClient() {
    return this._httpClient;
  }
}

module.exports = BaseService;
