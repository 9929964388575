const eventBus = require('../../utils/EventBus');
const { FETCH_CREDENTIALS, UPDATE_SELECTED_USER } = require('../../events');

const AccessControlAPI = require('../../services/api/accesscontrol');
const accessControlService = new AccessControlAPI();

module.exports = async ({ id, page }) => {
  if (page === 'profile') {
    eventBus.fire(FETCH_CREDENTIALS, {
      data: await accessControlService
        .users({ id })
        .credentialedExamTypes()
        .get(),
    });
  }

  eventBus.fire(UPDATE_SELECTED_USER, {
    userId: id,
  });
};
