import React from 'react';
import { Flex, Heading, Spacer, useDisclosure, Button } from '@chakra-ui/react';
import AddCsvUsers from './AddCsvUsers';

/**
 *
 */
const UserHeader = ({ pageTitle, actionButtons }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleButtonClick = (onClick) => {
    if (onClick) {
      if (onClick === 'AddCsvUsers') {
        onOpen();
      } else if (typeof onClick === 'function') {
        onClick();
      }
    }
  };
  return (
    <Flex py="4">
      <Heading
        as="h2"
        size="md"
        fontWeight="bold"
        sx={{ marginRight: '20px', fontFamily: 'Helvetica', fontWeight: '700', fontSize: '30px' }}
      >
        {pageTitle}
      </Heading>
      <Spacer />
      {actionButtons?.map((button) => (
        <Button
          sx={{
            marginLeft: '20px',
            marginRight: '20px',
            fontFamily: 'Helvetica',
            fontWeight: '400',
            fontSize: '16px',
            alignContent: 'center',
            lineHeight: '23.52px',
            letterSpacing: '0.27',
            color: '#007AFF',
            outline: '1px solid #007AFF',
          }}
          key={button.title}
          onClick={() => handleButtonClick(button.onClick)}
        >
          {button.title}
        </Button>
      ))}
      <AddCsvUsers isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};
// #endregion

export default UserHeader;
