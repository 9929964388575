module.exports = error => {
  let message = '';
  if (error.response && error.response.data && typeof error.response.data === 'object') {
    const keyError = Object.keys(error.response.data)[0];
    message = `${keyError}: ${error.response.data[keyError]}`;
  } else {
    message = error.message;
  }
  return message;
};
