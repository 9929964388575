const ITServiceAPI = require('../../services/api/itsetup');

const { FETCH_AUTHENTICATION_METHODS, TOAST, ERROR } = require('../../events');

const eventBus = require('../../utils/EventBus');

const itServiceAPI = new ITServiceAPI();

module.exports = async ({ id }) => {
  try {
    await itServiceAPI.authenticationMethods().multiPatch({
      authenticationMethods: [
        {
          id,
          is_active: true,
        },
      ],
    });

    eventBus.fire(FETCH_AUTHENTICATION_METHODS, {
      data: await itServiceAPI.authenticationMethods().fetch(),
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
};
