/* eslint max-lines-per-function: 0 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import UserlistApp from './UserList/UserlistApp';

const BaseComponent = require('./Base');
const eventBus = require('../utils/EventBus');
const { getMenu } = require('../utils/storage');
const { setAuthMethod, setAdfsSettings, setAutoLogoffMinutes, setAdminLogin } = require('../utils/authStorage');
const sessionTimeout = require('../utils/sessionTimeout');
const handleRedirectLogout = require('../utils/handleRedirectLogout');

const { UPDATE_CONTAINER_VIEW } = require('../events');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Facilities = require('./Facilities');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USSystems = require('./Systems');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExamTypes = require('./ExamTypes');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TagsAnnotations = require('./TagsAnnotations');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Workflow = require('./Workflow');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Members = require('./Members');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Credentials = require('./Credentials');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const QA = require('./QA');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Licenses = require('./Licenses');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Interfaces = require('./Interfaces');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoginParameters = require('./LoginParameters');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Groups = require('./Groups');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExamTypesCPT = require('./ExamTypesCPT');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tags = require('./Tags');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Worksheets = require('./Worksheets');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Insights = require('./Insights');

const UserAPI = require('../services/api/user');
const AuthSettingsAPI = require('../services/api/auth');
const userAPI = new UserAPI();
const authAPI = new AuthSettingsAPI();

class ExoContainer extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
  }

  getView(view) {
    const contentNode = document.getElementById('content');

    switch (view) {
      case 'Facilities & Specialities': {
        contentNode.innerHTML = '<facilities-page></facilities-page>';
        break;
      }
      case 'U/S Systems': {
        contentNode.innerHTML = '<systems-page></systems-page>';
        break;
      }
      case 'Map Exam Types & Worksheets': {
        contentNode.innerHTML = '<exam-types-page></exam-types-page>';
        break;
      }
      case 'Map Tags': {
        contentNode.innerHTML = '<tags-annotations-page></tags-annotations-page>';
        break;
      }
      case 'Workflow': {
        contentNode.innerHTML = '<workflow-page></workflow-page>';
        break;
      }
      case 'Users per Facility & Specialty': {
        contentNode.innerHTML = '<members-page></members-page>';
        break;
      }
      case 'Credentials': {
        contentNode.innerHTML = '<credentials-page></credentials-page>';
        break;
      }
      case 'QA/Training': {
        contentNode.innerHTML = '<qa-page></qa-page>';
        break;
      }
      case 'Licenses': {
        contentNode.innerHTML = '<licenses-page></licenses-page>';
        break;
      }
      case 'Interfaces': {
        contentNode.innerHTML = '<interfaces-page></interfaces-page>';
        break;
      }
      case 'Authentication & Login': {
        contentNode.innerHTML = '<login-parameters-page></login-parameters-page>';
        break;
      }
      case 'User Groups': {
        contentNode.innerHTML = '<groups-page></groups-page>';
        break;
      }
      case 'Exam Types & CPT Codes': {
        contentNode.innerHTML = '<exam-types-cpt-page></exam-types-cpt-page>';
        break;
      }
      case 'Tags': {
        contentNode.innerHTML = '<tags-page></tags-page>';
        break;
      }
      case 'Worksheets': {
        contentNode.innerHTML = '<worksheets-page></worksheets-page';
        break;
      }
      case 'Insights': {
        contentNode.innerHTML = '<insights-page></insights-page>';
        break;
      }
      case 'User List': {
        contentNode.innerHTML = ''; // Clear the content of the contentNode
        // Create a new container for the React component
        const portalContainer = document.createElement('div');
        contentNode.appendChild(portalContainer);
        // Mount the React component in the new container
        const root = createRoot(portalContainer);
        root.render(<UserlistApp />);

        break;
      }
      default: {
        contentNode.innerHTML = '<facilities-page></facilities-page>';
      }
    }
  }

  async connectedCallback() {
    const currentUser = await userAPI.getCurrentUser();
    setAutoLogoffMinutes(JSON.stringify(currentUser.autoLogoffMinutes));
    sessionTimeout();
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('adminLogin');
    if (!Boolean(myParam)) {
      const authSettings = await authAPI.get();

      if (authSettings) {
        setAuthMethod(authSettings.method);
        if (authSettings.adfs) {
          setAdfsSettings(JSON.stringify(authSettings.adfs));
        }
      }
    } else {
      setAdminLogin('true');
      setAuthMethod('EXO');
    }
    handleRedirectLogout();

    eventBus.register(UPDATE_CONTAINER_VIEW, ({ detail }) => {
      const childView = this.getView(detail.view);
      const style = `<style> ${this.styles()}</style>`;
      this.shadowRoot.innerHTML = `
        ${style}
        <div class='container'>
          ${childView}
        </div>
      `;
    });
  }

  styles() {
    return `
      .container {}
    `;
  }

  render() {
    const { option } = getMenu();
    const urlParams = new URLSearchParams(window.location.search);
    return `
      <div class='container'>
        ${this.getView(urlParams.get('redirect') === 'ussystems' ? 'U/S Systems' : option)}
      </div>
    `;
  }
}

customElements.define('exo-container', ExoContainer);
