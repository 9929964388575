const eventBus = require('../../utils/EventBus');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const { FETCH_BILLING_CODES, ERROR, TOAST, SUCCESS } = require('../../events');

const clinicDefinitionAPI = new ClinicDefinitionAPI();

module.exports = async ({ examTypeId, id }) => {
  try {
    const { data } = await clinicDefinitionAPI
      .examTypes({ examTypeId })
      .billingCodes({ billingCodeId: id })
      .delete();
    if (data && data.usedBy) {
      let message = `Code disabled. Can't delete because is being used by `;
      const usedByLength = data.usedBy.length;
      data.usedBy.forEach((examType, index) => {
        let separator = '';
        if (index !== usedByLength - 1) {
          separator = index === usedByLength - 2 ? 'and' : ',';
        }
        message = `${message} ${examType.name} ${separator} `;
      });
      eventBus.fire(TOAST, {
        items: [
          {
            type: ERROR,
            message,
          },
        ],
      });
    } else {
      eventBus.fire(TOAST, {
        items: [
          {
            type: SUCCESS,
            message: 'Code Deleted',
          },
        ],
      });
    }
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: 'Error: Please make sure this code is not assigned to an Exam',
        },
      ],
    });
  }
  eventBus.fire(FETCH_BILLING_CODES, {
    data: await clinicDefinitionAPI
      .examTypes({ examTypeId })
      .billingCodes()
      .getAll(),
  });
};
