const eventBus = require('../../utils/EventBus');
const sort = require('../../utils/sort');
const AccessControlAPI = require('../../services/api/accesscontrol');
const {
  UPDATE_SELECTED_CATEGORY,
  FETCH_PERMISSIONS,
  FETCH_LDAP_GROUP_USERS,
  UPDATE_SELECTED_USER_GROUP,
} = require('../../events');

const accessControlService = new AccessControlAPI();

module.exports = async ({ id, page, specialtyId, offset, limit }) => {
  eventBus.fire(UPDATE_SELECTED_CATEGORY, {
    categoryId: id,
  });

  if (page === 'members') {
    eventBus.fire(FETCH_LDAP_GROUP_USERS, {
      data: await accessControlService
        .userGroups({ groupId: id })
        .users()
        .get({ parameters: { specialtyId, offset, limit } }),
    });

    eventBus.fire(UPDATE_SELECTED_USER_GROUP, {
      groupId: id,
    });
  } else if (page === 'permissions') {
    eventBus.fire(FETCH_PERMISSIONS, {
      data: sort(
        await accessControlService
          .userGroups({ groupId: id })
          .permissions()
          .get(),
        'permissionsProfile',
      ),
    });
  }
};
