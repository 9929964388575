/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const { FETCH_WORKSHEETS } = require('../events');
const WarningSVG = require('../../assets/warning.svg');
const eventBus = require('../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();

class Worksheets extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this.attachEvents();
  }

  async attachEvents() {
    const queryButton = this.shadowRoot.querySelector(`.query-button`);
    const modal = this.shadowRoot.querySelector(`.modal`);
    const background = this.shadowRoot.querySelector('.background');
    const cancelButton = this.shadowRoot.querySelector('.btn-cancel');
    const confirmButton = this.shadowRoot.querySelector('.btn-confirm');

    queryButton.addEventListener('click', () => {
      modal.classList.remove('hidden');
      background.classList.remove('hidden');
    });

    const closeModal = () => {
      modal.classList.add('hidden');
      background.classList.add('hidden');
    };

    background.addEventListener('click', () => {
      closeModal();
    });

    cancelButton.addEventListener('click', () => {
      closeModal();
    });

    confirmButton.addEventListener('click', async () => {
      eventBus.fire(FETCH_WORKSHEETS, {
        data: await clinicDefinitionService.examWorksheets().getAll({ parameters: { isPublished: true } }),
      });
      closeModal();
    });
  }

  async connectedCallback() {
    this.listenForEvents([{ event: FETCH_WORKSHEETS, key: 'worksheets' }], eventBus);
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .card-container {
        display: flex;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
        margin-bottom: 20px;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .background {
        background: rgba(0, 0, 0, 0.5);
        width: 100vw;
        position: absolute;
        left: 0px;
        top: 0;
        display: flex;
        height: 100vh;
      }

      .hidden {
        display: none !important;
      }

      .modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        background-color: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 330px;
        height: 230px;
        border: none;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .modal-text {
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: #2C2C2E;
      }

      .button {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
  
      }

      button {
        border-radius: 10px;
        width: 140px;
        height: 50px;
        background: transparent;
        cursor: pointer;
      }

      .query-button {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 1px solid #007AFF;
        box-sizing: border-box;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        color: #007AFF;

      }

      .buttons {
        display: flex;
        justify-content: space-between;
        width: 90%;
        padding: 0px 20px 20px 20px;
      }

      .btn-confirm {
        border: 1px solid #007AFF;
        color: #007AFF;
        margin-left: 0px;
      }

      .btn-cancel {
        border: 1px solid #FB5654;
        color: #FB5654;
        margin-right: 0px;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }

      exo-card {
        width: 50%;
        margin-right: 2%;
        overflow: hidden;
      }

      .item-wrapper {
        overflow: auto;
        height: calc(100% - 137px);
      }

      .blur {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
      }

      svg {
        margin-top: 32px;
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }

      @media only screen and (max-width: 1195px) {
        button {
          font-size: 10px;
          height: 35px;
        }
      }
    `;
  }

  renderExoCard({ items, divider, card }) {
    return `
      <exo-card>
        <div class="head-section">
          <exo-card-title title="Worksheets"></exo-card-title>
          <div class="button">
            <button class="query-button">
              Query Worksheets
            </button>
          </div>
        </div>

        <div class="divider">
          ${divider.map((d) => `<span>${d}</span>`).join('')}
        </div>
    
        <div class="item-wrapper">
          <ul class="items">
            ${items
              .map(
                (item) =>
                  `<exo-card-list-item
                    name="${item.name}"
                    id="${item.id}"
                    card="${card}"
                    viewOnly
                    selectedItem=${false}
                  >
                    ${item.name}
                  </exo-card-list-item>`,
              )
              .join('')}
          </ul>
        </div>

        <div class="background hidden"></div>
        <div class="modal hidden">
          <svg width="33" height="33" class="button-icon">
            <image xlink:href="${WarningSVG}" width="33" height="33" />
          </svg>
          <div class="modal-text">Query Worksheets?</div>
          <div class="buttons">
            <button class="btn-cancel">Cancel</button>
            <button class="btn-confirm">Confirm</button>
          </div>
        </div>
      </exo-card>
    `;
  }

  render() {
    return `
      <div>
        <div class="title">Worksheets</div>
        <div class="card-container">
          ${this.renderExoCard({
            card: 'worksheets',
            title: 'Worksheets',
            divider: ['Exam Type'],
            items: this._data && this._data.worksheets ? this._data.worksheets : [],
          })}
        </div>
      </div>
    `;
  }
}

customElements.define('worksheets-page', Worksheets);
