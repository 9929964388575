const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');

const eventBus = require('../../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();

const { FETCH_TAGS, TOAST, ERROR, SUCCESS } = require('../../events');

module.exports = async ({ tag, specialtyId, isEnabled }) => {
  try {
    await clinicDefinitionService
      .facilities()
      .specialties({ specialtyId: specialtyId })
      .mapTags()
      .create({
        payload: {
          tag,
          isEnabled,
        },
      });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'Tag Created',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_TAGS, {
    data: await clinicDefinitionService
      .facilities()
      .specialties({ specialtyId: specialtyId })
      .mapTags()
      .getAll(),
  });
};
