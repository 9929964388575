/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const ITServiceAPI = require('../services/api/itsetup');
const { monthsToSeconds, secondsToMonths } = require('../utils/time');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');

const { FETCH_AUTHENTICATION_METHODS, FETCH_LOGIN_PARAMETERS, TOAST, SUCCESS, ERROR } = require('../events');
const eventBus = require('../utils/EventBus');
const radioOnSVG = require('../../assets/authRadioOn.svg');
const saveSVG = require('../../assets/save.svg');

const itServiceAPI = new ITServiceAPI();
const clinicDefinitionAPI = new ClinicDefinitionAPI();

class LoginParameters extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this.itemId = null;
    this.logoffMinutes = null;
    this.modalItems = [];
    this.authFlag = false;
    this.loginFlag = false;
  }

  getInputValueByName = (name) => {
    if (this.shadowRoot.querySelector(`input[name="${name}"]`)) {
      return this.shadowRoot.querySelector(`input[name="${name}"]`).value;
    }
    return undefined;
  };

  async logOffTimeSet(autoLogoffMinutes) {
    try {
      await clinicDefinitionAPI.userSecuritySettings().save({
        payload: {
          id: this.getInputValueByName('id'),
          autoLogoffMinutes: autoLogoffMinutes,
          ...(this.getInputValueByName('length') && { passwordMinLength: this.getInputValueByName('length') }),
          ...(this.getInputValueByName('length') && { passwordMinFromCharsets: this.getInputValueByName('length') }),
          ...(this.shadowRoot.querySelector('.strength-select') && {
            passwordStrength: this.shadowRoot.querySelector('.strength-select').value,
          }),
          ...(this.getInputValueByName('passwordDuration') && {
            passwordDurationSecs: monthsToSeconds(parseInt(this.getInputValueByName('passwordDuration'))),
          }),
          ...(this.getInputValueByName('lockoutDuration') && {
            lockoutDurationMins: this.getInputValueByName('lockoutDuration'),
          }),
          ...(this.getInputValueByName('attempts') && { numFailsTriggerLockout: this.getInputValueByName('attempts') }),
        },
      });
      this.loginFlag = false;
      this.modalItems = [
        ...this.modalItems,
        {
          type: SUCCESS,
          message: 'Login parameters saved successfully',
        },
      ];
    } catch (error) {
      this.modalItems = [
        ...this.modalItems,
        {
          type: ERROR,
          message: error.message,
        },
      ];
    }
  }

  async setAuthenticationMethod(id) {
    try {
      await itServiceAPI.authenticationMethods().multiPatch({
        authenticationMethods: [
          {
            id: id,
            is_active: true,
          },
        ],
      });
      this.authFlag = false;
      eventBus.fire(FETCH_AUTHENTICATION_METHODS, {
        data: await itServiceAPI.authenticationMethods().fetch(),
      });
      this.modalItems = [
        ...this.modalItems,
        {
          type: SUCCESS,
          message: 'Authentication method saved successfully',
        },
      ];
    } catch (error) {
      this.modalItems = [
        ...this.modalItems,
        {
          type: ERROR,
          message: error.message,
        },
      ];
    }
  }

  attachEvents() {
    const saveBtn = this.shadowRoot.querySelector('.save');
    const radioButtons = this.shadowRoot.querySelectorAll('.radio-button-wrapper');
    let logoff = this.shadowRoot.getElementById('logoff');
    if (logoff) {
      logoff.addEventListener('change', () => {
        this.logoffMinutes = this.getInputValueByName('logoff');
        logoff.setAttribute('value', this.logoffMinutes);
        this.loginFlag = true;
      });
    }

    radioButtons.forEach((button) => {
      button.addEventListener('click', () => {
        this.itemId = button.getAttribute('value');
        this.selectedAuthMethod = this.itemId;
        this.authFlag = true;
      });
    });
    if (saveBtn) {
      saveBtn.addEventListener('click', async () => {
        this.modalItems = [];
        if (this.logoffMinutes && this.loginFlag) {
          await this.logOffTimeSet(this.logoffMinutes);
        }
        if (this.itemId && this.authFlag) {
          await this.setAuthenticationMethod(this.itemId);
        }
        eventBus.fire(TOAST, {
          items: this.modalItems,
        });
      });
    }
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        margin-right: -14.5%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }
      .save {
        float:right;
        cursor: pointer;
      }
      .value {
        height: 35px;
        width: 90%;
        background-color: #ffffff;
        border-radius: 5px;
        margin: 0 auto;
        padding: 2%;
        margin-bottom: 2%;
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: #2C2C2E;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border: 1px solid #007AFF;
         
      }
      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }
      input, select {
        border:none;
        margin-left: 5%;
        border-bottom: 1px solid #DBDBDB;
        padding: 5px 10px 5px 0px;
        width: 80%;
        margin-bottom: 5%;
        outline: none;
        box-sizing: content-box;
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    }
      input:disabled {
        background: none;
      }
      .input-container, .select-wrapper {
        position: relative;
      }
      .floating-label {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.2px;
        color: #969696;
        position: absolute;
        right: 12%;
        opacity: 0.5;
      }
     
      label {
        text-align: left;
        margin-left: 5%;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #969696;
      }
      input:focus, textarea:focus,
      keygen:focus, select:focus {
          outline-offset: -2px;
      }
      .card-container {
        display: flex;
      }
      exo-card {
        width: 25%;
        margin-right: 2%;
      }
      exo-card-list-item:hover {
        cursor: pointer;
      }
      input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
      button {
        border: 2px solid black;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
      }
      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }
      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }
      .radio-button-wrapper {
        height: 35px;
        width: 90%;
        background-color: #ffffff;
        border-radius: 5px;
        margin: 0 auto;
        padding: 2%;
        margin-bottom: 2%;
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: #2C2C2E;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border: 1px solid #007AFF;
      }
  
      .radio-label {
        margin-top: 1%;
      }
      .selected {
        background: #007AFF;
        color: #FFFFFF
      }
      .radio-label:hover {
        cursor: pointer;
      }
      
      input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
      }
      input[type="radio"]:not(:checked) ~ label > .radio-off {
        display: block;
      }
      input[type="radio"]:not(:checked) ~ label > .radio-on {
        display: none;
      }
      input[type="radio"]:checked ~ label > .radio-on {
        display: block;
      }
      input[type="radio"]:checked ~ label > .radio-off {
        display: none;
      }
      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }
      }
      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }
    `;
  }

  async connectedCallback() {
    this.listenForEvents([{ event: FETCH_AUTHENTICATION_METHODS, key: 'methods' }], eventBus);
    this.listenForEvents([{ event: FETCH_LOGIN_PARAMETERS, key: 'params' }], eventBus);
    eventBus.fire(FETCH_AUTHENTICATION_METHODS, {
      data: await itServiceAPI.authenticationMethods().fetch(),
    });

    eventBus.fire(FETCH_LOGIN_PARAMETERS, {
      data: await clinicDefinitionAPI.userSecuritySettings().get(),
    });
  }

  static get observedAttributes() {
    return ['operation', 'selectedauthmethod'];
  }

  get selectedAuthMethod() {
    return this.getAttribute('selectedAuthMethod');
  }

  set selectedAuthMethod(newSelectedAuthMethod) {
    this.setAttribute('selectedAuthMethod', newSelectedAuthMethod);
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  renderLoginFields(params) {
    const {
      id,
      passwordMinLength,
      passwordStrength,
      passwordDurationSecs,
      numFailsTriggerLockout,
      lockoutDurationMins,
      autoLogoffMinutes,
    } = params;

    const NonLDAPOrSOOSelected = this._data.methods.some((method) => method.isActive && method.name === 'EXO');

    return `
    <div>
      ${
        NonLDAPOrSOOSelected
          ? `
            <label for="length">
              Min. Password Length
            </label>
            <div class="input-container">
              <input
                name="length"
                type="number"
                min="8"
                max="25"
                autocomplete="false"
                value="${passwordMinLength}"
              >
              <span class="floating-label"> Characters </span>
            </div>
      <label for="strength">
        Password Strength
      </label>
      <div class="select-wrapper">
        <select class="strength-select" name="strength" disabled>
          <option value="LOW" ${passwordStrength === 'LOW' && 'selected'}>Low</option>
          <option value="MODERATE" ${passwordStrength === 'MODERATE' && 'selected'}>Moderate</option>
          <option value="STRONG" ${passwordStrength === 'STRONG' && 'selected'}>Strong</option>
        </select>
      </div>
          <div class="input-container">
            <label for="passwordDuration">
              Password Duration
            </label>
            <input min="3" max="12" name="passwordDuration" type="number" value="${secondsToMonths(
              passwordDurationSecs,
            )}">
            <span class="floating-label"> Month(s) </span>
            </div>
      <label for="attempts">
        Failed Login Attempts
      </label>
      <input name="attempts"  min="3" max="12" type="number" value="${numFailsTriggerLockout}">
      <div class="input-container">
        <label for="lockoutDuration">
          Lockout Duration
        </label>
        <input  min="1" max="10" name="lockoutDuration" type="number" value="${lockoutDurationMins}">
        <span class="floating-label"> Mins </span>
      </div> `
          : ''
      }
      <div class="input-container">
        <label for="logoff">
          Auto Logoff
        </label>
        <input  min="5" max="60" name="logoff" id="logoff" type="number" value="${
          this.logoffMinutes === null ? autoLogoffMinutes : this.logoffMinutes
        }">
        <span class="floating-label"> Min </span>
      </div>
      <input type="hidden" value="${id}" name="id">
    </div>`;
  }

  renderExoCard({ title, card, divider, items, params }) {
    return `
      <exo-card page="${card}" class='view'>
        <div class="head-section">
          <exo-card-title title="${title}"></exo-card-title>
        </div>
        ${
          card === 'authentications'
            ? `
        <div class="divider">
          ${divider.map((d) => `<span>${d}</span>`).join('')}
        </div>
        <ul class="items">
          ${items
            .map(
              (item) =>
                `<div class='radio-button-wrapper ${
                  item.id === Number(this.selectedAuthMethod) ? 'selected' : ''
                }' value=${item.id}>
                    ${item.name}
                    <input type="radio" name="based" value=${item.id} ${
                  item.id === Number(this.selectedAuthMethod) ? 'checked' : ''
                }>
                    <label class="radio-label">
                      <div class="circle radio-off"></div>
                      <svg width="24" height="24" class="radio-on">
                        <image xlink:href="${radioOnSVG}" width="24" height="24" />
                      </svg>
                    </label>
                  </div>`,
            )
            .join('')}
        </ul>`
            : this.renderLoginFields(params)
        }
      </exo-card>
    `;
  }

  render() {
    if (this._data.methods && !this.selectedAuthMethod) {
      this.selectedAuthMethod = this._data.methods.find((method) => method.isActive)?.id;
    }
    return `
      <div>
        <div class="title">Authentication & Login
        <svg class="save">
          <image xlink:href="${saveSVG}" />
        </svg>
        </div>
        <div class="card-container">
          ${
            this._data && this._data.methods
              ? this.renderExoCard({
                  card: 'authentications',
                  title: 'Authentication',
                  divider: ['Method'],
                  operation: 'view',
                  items: this._data.methods.sort((a, b) => a.id - b.id),
                })
              : ``
          }
          ${
            this._data && this._data.methods && this._data.params
              ? this.renderExoCard({
                  card: 'loginParams',
                  title: 'Login Parameters',
                  operation: 'view',
                  params: this._data.params,
                })
              : `<span> Loading... </span>`
          }
        </div>
      </div>
    `;
  }
}
customElements.define('login-parameters-page', LoginParameters);
