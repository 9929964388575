/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
/* @typescript-eslint/naming-convention: camelCase */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');

const sortObjectsAlphabetically = require('../utils/sortObjectsAlphabetically');

const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const saveSVG = require('../../assets/save.svg');
const clinicDefinitionService = new ClinicDefinitionAPI();

const {
  UPDATE_CRUD_OPERATION,
  FETCH_FACILITIES,
  FETCH_SPECIALITIES,
  FETCH_EXAM_TYPES,
  FETCH_SPECIALTY_EXAM_TYPES,
  FETCH_WORKSHEETS,
  UPDATE_SELECTED_FACILITY,
  UPDATE_SELECTED_SPECIALTY,
  UPDATE_SELECTED_EXAM_TYPE,
  UPDATE_SELECTED_WORKSHEET,
  CACHE_EXAMTYPE_UPDATE,
  CACHE_WORKSHEET_UPDATE,
  CACHE_SAVE,
  TOAST,
  SUCCESS,
  ERROR,
  UPDATE_CONTAINER_VIEW,
} = require('../events');

const eventBus = require('../utils/EventBus');
const setScrollPosition = require('../utils/setScrollPosition');

class ExamTypes extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this.displayExamTypes = false;
    this._useScroll = true;

    this.cache = {};
  }

  initCache(facilityId, specialtyId) {
    if (this.cache.facilities === undefined) this.cache.facilities = {};
    if (this.cache.facilities[facilityId] === undefined) this.cache.facilities[facilityId] = { specialties: {} };
    if (this.cache.facilities[facilityId].specialties[specialtyId] === undefined)
      this.cache.facilities[facilityId].specialties[specialtyId] = {
        examTypes: {},
        worksheetExamTypes: {},
      };
  }

  async connectedCallback() {
    eventBus.register(UPDATE_CRUD_OPERATION, ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    eventBus.register(UPDATE_SELECTED_FACILITY, ({ detail }) => {
      this.displayExamTypes = false;
      this.selectedFacility = detail.facilityId;
    });

    eventBus.register(UPDATE_SELECTED_SPECIALTY, ({ detail }) => {
      this.selectedSpecialty = detail.specialtyId;
      this.selectedExamType = null;
      this.displayExamTypes = true;
    });

    eventBus.register(UPDATE_SELECTED_EXAM_TYPE, ({ detail }) => {
      this.selectedExamType = detail.examTypeId;
    });

    eventBus.register(UPDATE_SELECTED_WORKSHEET, ({ detail }) => {
      this.selectedWorksheet = detail.worksheetId;
    });

    this.listenForEvents(
      [
        { event: FETCH_FACILITIES, key: 'facilities' },
        { event: FETCH_SPECIALITIES, key: 'specialities' },
        { event: FETCH_EXAM_TYPES, key: 'exams' },
        { event: FETCH_SPECIALTY_EXAM_TYPES, key: 'specialtyexams' },
        { event: FETCH_WORKSHEETS, key: 'worksheets' },
      ],
      eventBus,
    );

    eventBus.register(UPDATE_CONTAINER_VIEW, ({ detail }) => {
      if (detail.view !== 'Map Exam Types & Worksheets') {
        this.cache = {};
      }
    });

    eventBus.fire(FETCH_FACILITIES, {
      data: await clinicDefinitionService.facilities().get({
        parameters: {
          is_enabled: true,
        },
      }),
    });

    eventBus.register(CACHE_EXAMTYPE_UPDATE, ({ detail }) => {
      this.initCache(detail.examType.facilityId, detail.examType.specialtyId);
      const specialty = this.cache.facilities[detail.examType.facilityId].specialties[detail.examType.specialtyId];

      if (detail.examType.examTypeId !== undefined) {
        specialty.examTypes[detail.examType.examTypeId] = detail.examType;
      } else {
        for (const exam of this._data.exams) {
          specialty.examTypes[exam.id] = JSON.parse(JSON.stringify(detail.examType));
        }
      }
    });

    eventBus.register(CACHE_WORKSHEET_UPDATE, ({ detail }) => {
      this.initCache(detail.worksheet.facilityId, detail.worksheet.specialtyId);
      const specialty = this.cache.facilities[detail.worksheet.facilityId].specialties[detail.worksheet.specialtyId];
      specialty.worksheetExamTypes[detail.worksheet.examTypeId] = detail.worksheet.id;
    });

    eventBus.register(CACHE_SAVE, ({}) => {
      this.saveCache();
    });
  }

  static get observedAttributes() {
    return ['selectedcard', 'operation', 'selectedfacility', 'selectedspecialty', 'selectedexamtype', 'showenabled'];
  }

  set selectedCard(newSelectedCard) {
    this.setAttribute('selectedCard', newSelectedCard);
  }

  get selectedCard() {
    return this.getAttribute('selectedCard') || 'facilities';
  }

  set selectedFacility(newSelectedFacility) {
    this.setAttribute('selectedFacility', newSelectedFacility);
  }

  get selectedFacility() {
    return this.getAttribute('selectedFacility');
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  get selectedSpecialty() {
    return this.getAttribute('selectedSpecialty');
  }

  set selectedSpecialty(newSelectedSpecialty) {
    this.setAttribute('selectedSpecialty', newSelectedSpecialty);
  }

  set selectedExamType(newSelectedExamType) {
    this.setAttribute('selectedExamType', newSelectedExamType);
  }

  get selectedExamType() {
    return this.getAttribute('selectedExamType');
  }

  set examListPosition(newExamListPosition) {
    this.setAttribute('examListPosition', newExamListPosition);
  }

  get examListPosition() {
    return this.getAttribute('examListPosition');
  }

  set procedureListPosition(newProcedureListPosition) {
    this.setAttribute('procedureListPosition', newProcedureListPosition);
  }

  get procedureListPosition() {
    return this.getAttribute('procedureListPosition');
  }

  set worksheetListPosition(newWorksheetListPosition) {
    this.setAttribute('worksheetListPosition', newWorksheetListPosition);
  }

  get worksheetListPosition() {
    return this.getAttribute('worksheetListPosition');
  }

  set showEnabled(newShowEnabled) {
    this.setAttribute('showEnabled', newShowEnabled);
  }

  get showEnabled() {
    return this.getAttribute('showEnabled');
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .exams-divider {
        margin-left: 14px;
        width: 83%; 
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
        padding-bottom: 5%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }

      .show-enabled-container {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }

      .show-enabled-label { 
        font-size: 12px;
        font-weight: 400;
        color: rgba(44, 44, 46, 0.503087);
        margin-right: 4px;
      }

      .save {
        float:right;
      }

      #save:hover {
        cursor: pointer;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .card-container {
        display: flex;
      }

      exo-card {
        width: 25%;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
      }

      exo-card-list-item:hover {
        cursor: pointer;
      }

      .exams-divider {
        width: 80%;
      }

      .exams-container {
        display: flex;
        justify-content: center;
        width: 100%;
        max-height: 280px;
      }

      .exam-list {
        width: 90%;
        padding: 8px 0;
        border: 1px solid #CDCDCD;
        border-radius: 10px;
        overflow-y: scroll;
        scroll-padding-right: 100px;
      }

      .worksheets {
        height: 80%;
        overflow-y: scroll;
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }
    `;
  }

  async saveCache() {
    let saveSuccessful = true;
    if (this.cache.facilities) {
      for (const facilityId of Object.keys(this.cache.facilities)) {
        const facility = this.cache.facilities[facilityId];
        if (facility.specialties) {
          for (const specialtyId of Object.keys(facility.specialties)) {
            const specialty = facility.specialties[specialtyId];
            if (specialty.examTypes) {
              for (const examTypeId of Object.keys(specialty.examTypes)) {
                const examType = specialty.examTypes[examTypeId];
                try {
                  if (examType.enabled) {
                    await clinicDefinitionService
                      .examTypes({
                        facilityId: facilityId,
                        specialtyId: specialtyId,
                        examTypeId: examTypeId,
                      })
                      .enable({ examTypeId: examTypeId });
                  } else {
                    await clinicDefinitionService
                      .examTypes({
                        facilityId: facilityId,
                        specialtyId: specialtyId,
                        examTypeId: examTypeId,
                      })
                      .disable({ examTypeId: examTypeId });
                  }
                  delete specialty.examTypes[examTypeId];
                } catch (e) {
                  saveSuccessful = false;
                } finally {
                }
              }
            }
            if (specialty.worksheetExamTypes) {
              for (const examTypeId of Object.keys(specialty.worksheetExamTypes)) {
                const worksheetId = specialty.worksheetExamTypes[examTypeId];
                try {
                  await clinicDefinitionService.examTypes({ facilityId: facilityId, specialtyId, worksheetId }).patch({
                    payload: {
                      id: examTypeId,
                      worksheetTemplate: worksheetId,
                    },
                  });
                  delete specialty.worksheetExamTypes[examTypeId];
                } catch (e) {
                  saveSuccessful = false;
                } finally {
                }
              }
            }
          }
        }
      }
    }
    if (saveSuccessful) {
      eventBus.fire(TOAST, {
        items: [
          {
            type: SUCCESS,
            message: 'Update saved',
          },
        ],
      });
      this.cache = {};
    } else {
      eventBus.fire(TOAST, {
        items: [
          {
            type: ERROR,
            message: 'Save failed. Please try again.',
          },
        ],
      });
    }
  }

  attachEvents() {
    const showEnabledToggle = this.shadowRoot.querySelector('.show-enabled');
    if (showEnabledToggle) {
      showEnabledToggle.addEventListener('click', () => {
        this.showEnabled = this.showEnabled === 'true' ? 'false' : 'true';
      });
    }
    const saveBtn = this.shadowRoot.querySelector('#save');
    saveBtn.addEventListener('click', async () => {
      eventBus.fire(CACHE_SAVE, true);
    });
  }

  handleScroll() {
    const examList = this.shadowRoot.querySelector('#exam-list');

    if (examList) {
      examList.addEventListener('scroll', (event) => {
        this.examListPosition = setScrollPosition(event);
      });

      examList.scrollTo({ top: this.examListPosition });
    }

    const procedureList = this.shadowRoot.querySelector('#procedure-list');

    if (procedureList) {
      procedureList.addEventListener('scroll', (event) => {
        this.procedureListPosition = setScrollPosition(event);
      });

      procedureList.scrollTo({ top: this.procedureListPosition });
    }

    const worksheetList = this.shadowRoot.querySelector('#worksheet-list');

    if (worksheetList) {
      worksheetList.addEventListener('scroll', (event) => {
        this.worksheetListPosition = setScrollPosition(event);
      });

      worksheetList.scrollTo({ top: this.worksheetListPosition });
    }
  }

  checkWorksheetSelected(worksheetId) {
    const selectedExamObject = this._data.specialtyexams.find(
      (exam) => exam.examType.id === parseInt(this.selectedExamType),
    );
    if (selectedExamObject && selectedExamObject.worksheetTemplate) {
      return selectedExamObject.worksheetTemplate.id === worksheetId;
    }
    return false;
  }

  isExamTypeActive(examTypeId) {
    const specialtyExamType = this._data.specialtyexams.find((exam) => exam.examType.id === examTypeId);
    return specialtyExamType && specialtyExamType.isActive;
  }

  renderExoCard({ title, divider, items, card, operation, nameKey, selectedItem }) {
    let exams = [];
    let procedures = [];

    const filterItems = (filter) =>
      sortObjectsAlphabetically(
        items.filter((item) => item.examTypeCategory === filter),
        ['name'],
      );

    if (card === 'exams') {
      exams = filterItems('EXAM');
      procedures = filterItems('PROCEDURE');
    }

    return `
      <exo-card
        class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}">
        <div class="head-section">
          <exo-card-title title="${title}"></exo-card-title>
          ${
            card === 'exams'
              ? `<div class="show-enabled-container">
              <span class="show-enabled-label">Show Enabled</span>
              <exo-toggle
                enabled="${this.showEnabled}"
                class="show-enabled"
              ></exo-toggle>
            </div>`
              : ''
          }
        </div>
      
        <div class="divider ${card === 'exams' ? 'exams-divider' : ''}">
          ${divider.map((d) => `<span>${d}</span>`).join('')}
        </div>

        ${card === 'exams' ? '<div class="exams-container">' : ''}

        ${
          card !== 'worksheets'
            ? `<ul class="items ${card === 'exams' ? 'exam-list' : ''}" ${card === 'exams' ? "id='exam-list'" : ''}>
          ${(card === 'exams' ? exams : items)
            .map(
              (item) =>
                `<exo-card-list-item
                  page="exams"
                  name="${item.name}"
                  operation="${operation}"
                  id="${item.id}"
                  ${card === 'exams' ? '' : 'viewOnly'}
                  card="${card}"
                  model="${item.model}"
                  enabled="${card === 'exams' ? this.isExamTypeActive(item.id) : 'false'}"
                  facilityId="${this.selectedFacility}"
                  specialtyId="${this.selectedSpecialty}"
                  selectedItem="${selectedItem}"
                  clickable="true"
                >
                  ${card === 'exams' ? item.name : item[nameKey]}
                </exo-card-list-item>`,
            )
            .join('')}
        </ul>`
            : ''
        }

        ${card === 'exams' ? '</div>' : ''}     

        ${
          card === 'exams'
            ? `
          <div class="divider exams-divider">
            <span> Procedures </span>
            <span> Enabled </span>
          </div>
          
          <div class="exams-container">
            <ul class="items procedures exam-list" id='procedure-list'>
              ${procedures
                .map(
                  (procedure) => `
                  <exo-card-list-item
                    page="exams"
                    name="${procedure.name}"
                    operation="${operation}"
                    id="${procedure.id}"
                    card="${card}"
                    model="${procedure.model}"
                    enabled="${this.isExamTypeActive(procedure.id)}"
                    facilityId="${this.selectedFacility}"
                    specialtyId="${this.selectedSpecialty}"
                    selectedItem="${selectedItem}"
                    clickable="true"
                  >
                    ${procedure.name}
                  </exo-card-list-item>`,
                )
                .join('')}
            </ul>
          </div>
        `
            : ''
        }

        ${
          card === 'exams' && operation === 'view' && items.length > 0
            ? `<div class="enable-all" style="${this.showEnabled === 'true' ? 'display: none;' : 'display:;'}" >
               <span class="enable-all-label">${
                 items.every((item) => this.isExamTypeActive(item.id)) ? 'Disable All' : 'Enable All'
               }</span>
               <exo-toggle
                 card="${card}"
                 facilityId="${this.selectedFacility}"
                 specialtyId="${this.selectedSpecialty}"
                 all
                 enabled="${items.every((item) => this.isExamTypeActive(item.id))}"
                ></exo-toggle>
              </div>`
            : ''
        }

        
        ${
          card === 'worksheets' && operation === 'view' && items.length > 0
            ? `<ul class="items worksheets" id='worksheet-list'>
            ${items
              .map(
                (item) =>
                  `<exo-card-list-item
                name="${item.name}"
                operation="${operation}"
                id="${this.selectedExamType}"
                card="${card}"
                enabled="${this.checkWorksheetSelected(item.id)}"
                facilityId="${this.selectedFacility}"
                specialtyId="${this.selectedSpecialty}"
                examTypeId="${this.selectedExamType}"
                worksheetId="${item.id}"
                radio
              >
                ${item.name}
              </exo-card-list-item>`,
              )
              .join('')}
          </ul>`
            : ''
        }
      </exo-card>
    `;
  }

  render() {
    const exams = this._data.exams;

    if (
      this.cache !== undefined &&
      this.cache.facilities &&
      this.cache.facilities[this.selectedFacility] &&
      this.cache.facilities[this.selectedFacility].specialties &&
      this.cache.facilities[this.selectedFacility].specialties[this.selectedSpecialty]
    ) {
      const specialty = this.cache.facilities[this.selectedFacility].specialties[this.selectedSpecialty];

      if (specialty.examTypes !== undefined) {
        for (const key of Object.keys(specialty.examTypes)) {
          let specialtyExamType = this._data.specialtyexams.find((exam) => exam.examType.id == key);
          if (specialtyExamType !== undefined) {
            specialtyExamType.isActive = specialty.examTypes[key].enabled;
          } else {
            const linkedExamType = this._data.exams.find((exam) => exam.id == key);
            specialtyExamType = {
              isActive: specialty.examTypes[key].enabled,
              worksheetTemplate: null,
              examType: linkedExamType,
            };
            this._data.specialtyexams.push(specialtyExamType);
          }
        }
      }

      if (specialty.worksheetExamTypes !== undefined) {
        for (const key of Object.keys(specialty.worksheetExamTypes)) {
          const selectedExamObject = this._data.specialtyexams.find((exam) => exam.examType.id === parseInt(key));
          const newWorksheet = this._data.worksheets.find((ws) => ws.id == specialty.worksheetExamTypes[key]);

          newWorksheet.examType = key;
          if (selectedExamObject !== undefined) {
            selectedExamObject.worksheetTemplate = newWorksheet;
          } else {
            const linkedExamType = this._data.exams.find((exam) => exam.id == key);
            selectedExamObject = {
              isActive: false,
              worksheetTemplate: newWorksheet,
              examType: linkedExamType,
            };
            this._data.specialtyexams.push(selectedExamObject);
          }
        }
      }
    }
    const displayedExamsItems =
      this.showEnabled === 'true' ? exams.filter((item) => this.isExamTypeActive(item.id)) : exams;

    return `
      <div>
        <div class="title">Map Exam Types & Worksheets
        <svg class="save">
          <image id="save" xlink:href="${saveSVG}" />
        </svg>
        </div>
        <div class="card-container">
          ${
            this._data && this._data.facilities
              ? this.renderExoCard({
                  card: 'facilities',
                  title: 'Facility',
                  divider: ['Facility'],
                  nameKey: 'name',
                  operation: this.selectedCard === 'facilities' ? this.operation : 'view',
                  items: this._data.facilities,
                  selectedItem: this.selectedFacility,
                })
              : `<span> Loading... </span>`
          }
          ${
            this._data && this._data.specialities
              ? this.renderExoCard({
                  card: 'specialities',
                  title: 'Specialities',
                  nameKey: 'name',
                  divider: ['Specialities'],
                  operation: this.selectedCard === 'specialities' ? this.operation : 'view',
                  items: this._data.specialities,
                  selectedItem: this.selectedSpecialty,
                })
              : ''
          }
          ${
            this.displayExamTypes &&
            this._data &&
            this._data.exams &&
            this._data.specialtyexams &&
            this._data.exams.length
              ? this.renderExoCard({
                  card: 'exams',
                  title: 'Exam Type',
                  nameKey: 'name',
                  divider: ['Exams', 'Enabled'],
                  operation: this.selectedCard === 'exams' ? this.operation : 'view',
                  items: displayedExamsItems,
                  selectedItem: this.selectedExamType,
                })
              : ''
          }

          ${
            this.displayExamTypes && this._data && this._data.worksheets && JSON.parse(this.selectedExamType)
              ? this.renderExoCard({
                  card: 'worksheets',
                  title: 'Worksheet',
                  divider: ['Worksheet', 'Select One'],
                  nameKey: 'name',
                  operation: this.selectedCard === 'worksheets' ? this.operation : 'view',
                  items: sortObjectsAlphabetically(this._data.worksheets, ['name']),
                })
              : ``
          }
        </div>
      </div>
    `;
  }
}

customElements.define('exam-types-page', ExamTypes);
