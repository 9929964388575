// Auth Method
const setAuthMethod = authMethod => localStorage.setItem('auth', authMethod);
const getAuthMethod = () => localStorage.getItem('auth');
const removeAuthMethod = () => localStorage.removeItem('auth');

// Token Expiration
const setAutoLogoffMinutes = expToken => localStorage.setItem('autoLogoffMinutes', expToken);
const getAutoLogoffMinutes = () => localStorage.getItem('autoLogoffMinutes');
const removeAutoLogoffMinutes = () => localStorage.removeItem('autoLogoffMinutes');

// ADFS Settings
const setAdfsSettings = adfsSettings => sessionStorage.setItem('adfsSettings', adfsSettings);
const getAdfsSettings = () => sessionStorage.getItem('adfsSettings');
const removeAdfsSettings = () => sessionStorage.removeItem('adfsSettings');

// Admin Login
const setAdminLogin = adminLogin => localStorage.setItem('adminLogin', adminLogin);
const getAdminLogin = () => JSON.parse(localStorage.getItem('adminLogin'));
const removeAdminLogin = () => localStorage.removeItem('adminLogin');

module.exports = {
  setAuthMethod,
  getAuthMethod,
  removeAuthMethod,
  setAutoLogoffMinutes,
  getAutoLogoffMinutes,
  removeAutoLogoffMinutes,
  setAdfsSettings,
  getAdfsSettings,
  removeAdfsSettings,
  setAdminLogin,
  getAdminLogin,
  removeAdminLogin,
};
