/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { Table, Tbody, Tr, Td, Box, Text, Tooltip } from '@chakra-ui/react';
import UserAPI from '../../services/api/user';
import { TableHeader } from './TableHeader';
import UserListFooter from './UserListFooter';
import { User } from '../../stores/Users/types.user';
import UserHeader from './UserHeader';
import * as Icons from '../../../assets';
import UserListSearchAndFilters from './UserListSearchAndFilters';

export const getTruncatedText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export default function UserList() {
  const TABLE_COLUMNS = [
    {
      title: 'Username',
      getValue: ({ username }: User) => <Tooltip label={username?.toString()}>{username?.toString() || 'NA'}</Tooltip>,
    },
    {
      title: 'First Name',
      getValue: ({ firstName }: User) => (
        <Tooltip label={firstName?.toString()}>{firstName?.toString() || 'NA'}</Tooltip>
      ),
    },
    {
      title: 'Last Name',
      getValue: ({ lastName }: User) => <Tooltip label={lastName?.toString()}>{lastName?.toString() || 'NA'}</Tooltip>,
    },
    {
      title: 'Email',
      getValue: ({ email }: User) => <Tooltip label={email?.toString()}>{email?.toString() || 'NA'}</Tooltip>,
    },
    {
      title: 'Phone',
      getValue: ({ phoneNumber }: User) => (
        <Tooltip label={phoneNumber?.toString()}>{phoneNumber?.toString() || 'NA'}</Tooltip>
      ),
    },
    {
      title: 'Facility',
      getValue: ({ userRoles }: User) => {
        if (!!userRoles?.length) {
          return userRoles?.map((userRole, index) =>
            !!userRole.facilityId ? (
              <Tooltip label={userRole.facility} key={userRole.facilityId}>
                <div>{userRole.facility}</div>
              </Tooltip>
            ) : (
              <div key={index}>NA</div>
            ),
          );
        }
        return 'NA';
      },
    },
    {
      title: 'Specialty',
      getValue: ({ userRoles }: User) => {
        if (!!userRoles?.length) {
          return userRoles?.map((userRole, index) =>
            !!userRole.specialtyId ? (
              <Tooltip label={userRole.specialty} key={userRole.specialtyId}>
                <div>{userRole.specialty}</div>
              </Tooltip>
            ) : (
              <div key={index}>NA</div>
            ),
          );
        }
        return 'NA';
      },
    },
    {
      title: 'Role',
      getValue: ({ userRoles }: User) => {
        if (!!userRoles?.length) {
          return userRoles?.map((userRole, index) =>
            !!userRole.roleId ? (
              <Tooltip label={userRole.role} key={userRole.roleId}>
                <div>{userRole.role}</div>
              </Tooltip>
            ) : (
              <div key={index}>NA</div>
            ),
          );
        }
        return 'NA';
      },
    },
    {
      title: 'Authentication',
      getValue: ({ authentication }: User) => (
        <Tooltip label={authentication?.toString()}>{authentication?.toString() || 'NA'}</Tooltip>
      ),
    },
    {
      title: 'Edit',
      getValue: ({ id }: User) => {
        return (
          <Image
            _hover={{ cursor: 'pointer' }}
            src={Icons.editIcon}
            w="13px"
            h="12px"
            onClick={() => navigate(`/edit-user/${id}`)}
          />
        );
      },
    },
  ];
  const userAPI = new UserAPI();
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState([0]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const itemsPerPage = 12;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const navigate = useNavigate();

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await userAPI.getAllUser(itemsPerPage, (currentPage - 1) * itemsPerPage);
      setUsers(fetchedUsers?.results || []);
      setTotalCount(fetchedUsers?.count || 0);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching users:', error);
    }
  };

  React.useEffect(() => {
    !isFiltered && fetchUsers();
  }, [currentPage, isFiltered]);

  const actionButtons = [
    {
      title: 'Add Users from .CSV',
      variant: 'outline',
      onClick: 'AddCsvUsers',
    },
    {
      title: 'Add Exo User',
      variant: 'outline',
      onClick: () => navigate('/create-user'),
    },
  ];

  return (
    <Flex direction={'column'} onClick={() => setShowSuggestions(false)}>
      <Box
        overflowX="auto"
        maxW="100%"
        minHeight="705px"
        width="96%"
        sx={{ backgroundColor: 'white', marginLeft: '20px', marginRight: '20px', marginBottom: '25px' }}
        overflowY="auto"
      >
        <Box position={'fixed'} width={'75%'} background={'aliceblue'} zIndex={'999'}>
          <UserHeader pageTitle="User List" actionButtons={actionButtons} />
          <UserListSearchAndFilters
            setUsers={setUsers}
            setIsFiltered={setIsFiltered}
            setTotalCount={setTotalCount}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
            isFiltered={isFiltered}
            showSuggestions={showSuggestions}
            setShowSuggestions={setShowSuggestions}
          />
        </Box>
        <Box pt={'20px'}>
          <Table position={'relative'} top={'170px'} variant="simple" width="100%" overflowX="auto" minWidth="710px">
            <TableHeader columns={TABLE_COLUMNS} />
            <Tbody>
              <Tr borderBottom="1px solid rgba(44, 44, 46, 0.5)"></Tr>

              {users?.map((user) => (
                <Tr key={`user-row-${user.id}`} borderBottom="1px solid rgba(44, 44, 46, 0.5)">
                  {TABLE_COLUMNS.map((column) => (
                    <Td
                      key={`user-column-${user.id}-${column.title}`}
                      fontFamily="Helvetica"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="17.64px"
                      letterSpacing="0.2px"
                      color="rgba(44, 44, 46, 1)"
                      textAlign="left"
                    >
                      <Text width="max-content" cursor="pointer">
                        {column.getValue(user)}
                      </Text>
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <UserListFooter
        itemsPerPage={itemsPerPage}
        totalCount={Number(totalCount)}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Flex>
  );
}
