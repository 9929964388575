/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
/* @typescript-eslint/naming-convention: camelCase */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HorizontalCard = require('./HorizontalCard');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const dropDownSVG = require('../../assets/dropdown.svg');
const radioOffSVG = require('../../assets/radioOff.svg');
const radioOnSVG = require('../../assets/radioOn.svg');
const successSVG = require('../../assets/success.svg');
const errorSVG = require('../../assets/error.svg');
const saveSVG = require('../../assets/save.svg');
const FacilitiesAPI = require('../services/api/facilities');
const ITServiceAPI = require('../services/api/itsetup');

const { TOAST, ERROR, SUCCESS, VERIFY_DICOM } = require('../events');

const eventBus = require('../utils/EventBus');
const facilitiesAPI = new FacilitiesAPI();
const itServiceAPI = new ITServiceAPI();

class Interfaces extends BaseComponent {
  constructor() {
    super({ isAsync: true });
    this._useEvents = true;
  }

  attachEvents() {
    const tabs = this.shadowRoot.querySelectorAll('.tab');
    const saveBtn = this.shadowRoot.querySelector('.save');
    const verifyBtn = this.shadowRoot.querySelector('.verify-dicom-store');
    const toggle = this.shadowRoot.querySelector('exo-toggle');

    if (toggle) {
      toggle.addEventListener('click', () => {
        const sslCertificateInput = this.shadowRoot.querySelector('textarea[name="sslCertificate"]');
        sslCertificateInput.disabled = toggle.enabled;
      });
    }

    tabs.forEach(tab =>
      tab.addEventListener('click', () => {
        const text = tab.textContent;
        this.selectedTab = text.trim();
      }),
    );

    saveBtn.addEventListener('click', async () => {
      const getInputValueByName = name => {
        return this.shadowRoot.querySelector(`input[name="${name}"]`).value;
      };

      switch (saveBtn.getAttribute('for')) {
        case 'ldapServers':
          {
            const tlsEnabled = this.shadowRoot.querySelector('exo-toggle').enabled;

            const ldap = {
              host: getInputValueByName('host'),
              port: getInputValueByName('port') === '' ? null : getInputValueByName('port'),
              userSearchBase: getInputValueByName('userSearchBase'),
              groupSearchBase: getInputValueByName('groupSearchBase'),
              serviceUserDn: getInputValueByName('serviceUserDn'),
              serviceUserPassword: getInputValueByName('serviceUserPassword'),
              tlsEnabled: tlsEnabled,
              ...(tlsEnabled && {
                sslCertificate: this.shadowRoot.querySelector('textarea[name="sslCertificate"]').value,
              }),
            };

            try {
              await itServiceAPI
                .interfaces()
                .ldap(parseInt(getInputValueByName('id')))
                .save({ ldap });
              eventBus.fire(TOAST, {
                items: [{ type: SUCCESS, message: 'LDAP configuration saved!' }],
              });
            } catch (error) {
              eventBus.fire(TOAST, {
                items: [
                  {
                    type: ERROR,
                    message: error,
                  },
                ],
              });
            }
          }
          break;
        case 'sso': {
          const sso = {
            clientId: getInputValueByName('clientId'),
            relyingPartyId: getInputValueByName('relyingPartyId'),
            server: getInputValueByName('server'),
          };

          try {
            await itServiceAPI
              .interfaces()
              .sso(parseInt(getInputValueByName('id')))
              .save({ sso });
            eventBus.fire(TOAST, {
              items: [
                {
                  type: SUCCESS,
                  message: 'SSO configuration saved!',
                },
              ],
            });
          } catch (error) {
            eventBus.fire(TOAST, {
              items: [
                {
                  type: ERROR,
                  message: error,
                },
              ],
            });
          }
          break;
        }
        case 'exoLink': {
          const exoLink = {
            dicomHost: getInputValueByName('dicomIpAddress'),
            dicomPort: getInputValueByName('dicomPort'),
            dicomDefaultPatientIdType: getInputValueByName('hl7PatientId'),
            scannedPatientIdType: getInputValueByName('wristbandPatientId'),
          };

          try {
            await itServiceAPI
              .interfaces()
              .exoLink()
              .save({ exoLink });
            eventBus.fire(TOAST, {
              items: [
                {
                  type: SUCCESS,
                  message: 'DICOM configuration saved!',
                },
              ],
            });
          } catch (error) {
            eventBus.fire(TOAST, {
              items: [
                {
                  type: ERROR,
                  message: error,
                },
              ],
            });
          }

          break;
        }
      }
    });

    if (verifyBtn) {
      verifyBtn.addEventListener('click', async () => {
        try {
          const res = await itServiceAPI.exoLinkSettings().fetch();
          eventBus.fire(VERIFY_DICOM, {
            data: res.data.validatedField,
          });
        } catch (error) {
          eventBus.fire(TOAST, {
            items: [
              {
                type: ERROR,
                message: error,
              },
            ],
          });
        }
      });
    }
  }

  async connectedCallback() {
    this.listenForEvents([{ event: VERIFY_DICOM, key: 'verify_dicom' }], eventBus);

    this.shadowRoot.innerHTML = `
      <style>
        ${this.styles()}
      </style>
      ${await this.render()}
    `;

    this.attachEvents();
  }

  static get observedAttributes() {
    return ['selectedtab', 'operation'];
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  get selectedSpecialty() {
    return this.getAttribute('selectedSpecialty');
  }

  set selectedSpecialty(newSelectedSpecialty) {
    this.setAttribute('selectedSpecialty', newSelectedSpecialty);
  }

  get selectedTab() {
    return this.getAttribute('selectedTab') || 'ADFS_Oauth2';
  }

  set selectedTab(newTab) {
    this.setAttribute('selectedTab', newTab);
  }

  styles() {
    return `
      .interfaces-wrapper {
        display: flex;
        flex-direction: column;  
      }

      .title {
        margin-bottom: 3%;
        margin-right: -14.5%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .save {
        float:right;
        cursor: pointer;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .ldap-form {
        width: 30%;
        padding: 2%;
      }

      .secure-ldap {
        margin-left: 5%;
        display: flex;
        align-items: center;
      }

      input {
        border:none;
        margin-left: 5%;
        border-bottom: 1px solid #DBDBDB;
        padding: 5px 10px;
        width: 80%;
        margin-bottom: 5%;
        outline: none;
        font-family: HelveticaNowDisplay;
      }

      .input-container {
        position: relative;
      }

      .floating-label {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.2px;
        color: rgba(44, 44, 46, 0.5);
        position: absolute;
        right: 12%;
      }
     
      input:focus, textarea:focus,
      keygen:focus, select:focus {
          outline-offset: -2px;
      }
      
      .card-container {
        display: flex;
      }

      .ldap-container-content {
        display: flex;
      }

      textarea {
        flex-grow: 1;
        margin: 0px 25px;
        border: 1px solid #959596;
        border-radius: 5px;
        resize: none;
        font-family: HelveticaNowDisplay;
        margin-top: 5px;
      }

      .ssl-wrapper {
        flex-grow: 1;
        flex-direction: column;
        display: flex;
        padding: 2% 0px;
      }

      .toggle-label {
        display: flex;
        flex-direction: column;
      }

      .label-primary {
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #2C2C2E;
      }

      .label-secondary {
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #2C2C2E;
        mix-blend-mode: normal;
        opacity: 0.5;
      }

      label {
        text-align: left;
        margin-left: 5%;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #969696;
      }

      .card-container {
        margin-top: 20px;
      }

      horizontal-card {
        position: relative;
        width: 100%;
      }

      button {
        border: 1px solid black;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
      }


      .buttons {
        min-height: 50px;
        box-sizing: border-box;
        padding: 0% 3%;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      .confirm {
        float: right;
        border-color: #007AFF;
        color: #007AFF;
        margin-left: auto;
      }

      .feedback {
        margin-left: 5px;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.183333px
      }
    
      .error {
        color: #FB5654;
      }

      .success {
        color: #04AC7D;
      }

      .verify {
        border-color: #6236FF;
        color: #6236FF;
      }

      .radio-label {
        margin-top: 1%;
      }

      .radio-label:hover {
        cursor: pointer;
      }

      .tabs {
        list-style: none;
        margin: 0 auto;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        padding: 0;
      }

      .tab {
        margin: 0 10px;
        background-color: white;
        width: 178px;
        height: 45px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 10px rgba(74, 74, 139, 0.100524);
        border-radius: 5px;
        position: relative;
      }

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 10px;
        top: 43%;
        position: absolute;
        left: 84%;
      }

      .green {
        background-color: #04AC7D;
      }

      .yellow {
        background-color: #F7B500;
      }

      .red {
        background-color: #FB5654;
      }

      .selected {
        border: 1px solid #007AFF;
        box-shadow: 0px 0px 10px #007AFF;
      }

      .tab:hover {
        cursor: pointer;
      }
      
      input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
      }

      input[type="radio"]:not(:checked) ~ label > .radio-off {
        display: block;
      }

      input[type="radio"]:not(:checked) ~ label > .radio-on {
        display: none;
      }

      input[type="radio"]:checked ~ label > .radio-on {
        display: block;
      }

      input[type="radio"]:checked ~ label > .radio-off {
        display: none;
      }

      input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }

      input:disabled, .label-disabled {
        background: none;
        color: #2C2C2E;
        opacity: 0.5;
      }

      .dicom-store, .dicom-worklist {
        width: 33%;
        display: inline-block;
        vertical-align: top;
        padding-left: 2%;
      }

      .dicom-title {
        font-style: normal;
        padding: 5px 20px;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 0.333333px;
      }

      select {
        border: none;
        background-color: #f7f7f7;
        border-radius: 5px;
        width: 95%;
        float: left;
        padding: 15px 20px;
        margin: 0% 0% 3% 5%;
        outline: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .select-wrapper {
        width: 90%;
        margin-top: 3%;
        background-color: #FFF;
        display: inline-block;
        position: relative;
      }

      .feedback-container {
        display: inline-flex;
        margin-left: 10px;
      }
      
      .select-wrapper::before {
        content: url(${dropDownSVG});
        position: absolute;
        right: 3%;
        top: 15%;
      }

      @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
        .confirm {
          margin: 0;
        }
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }

        .confirm {
          margin: 0 0 0 auto;

        }
      }

      @media only screen and (min-height: 1000px) {
        .confirm {
          margin: 0 0 0 auto;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }
    `;
  }

  renderEfficiencyCard({ efficiency }) {
    return `
      <exo-card>
        <div class="head-section">
          <exo-card-title title="Efficiency"></exo-card-title>
        </div>
        <form>

          <label for="performing">Performing</label>
          <div class="select-wrapper">
            <select name="performing">
              ${facilitiesAPI
                .getHours()
                .map(
                  hour => `
                <option value="${hour}" ${hour == efficiency.performing ? 'selected' : ''}>
                  ${hour}
                </option>
              `,
                )
                .join('')}
            </select>
          </div>

          <label for="attending">Attending</label>
          <div class="select-wrapper">
            <select name="attending">
              ${facilitiesAPI
                .getHours()
                .map(
                  hour => `
                <option value="${hour}"  ${hour == efficiency.attending ? 'selected' : ''}>
                  ${hour}
                </option>
              `,
                )
                .join('')}
            </select>
          </div>

          <label for="Unassigned">Unassigned</label>
          <div class="select-wrapper">
            <select name="unassigned">
              ${facilitiesAPI
                .getHours()
                .map(
                  hour => `
                <option value="${hour}"  ${hour == efficiency.unassigned ? 'selected' : ''}>
                  ${hour}
                </option>
              `,
                )
                .join('')}
            </select>
          </div>

          <fieldset>
            <label>Choose one</label>
            <div class="radio-container">
              Encounter Based
              <input type="radio" class="encounter" name="based" value="Encounter" ${
                efficiency.encounterBase ? 'checked' : ''
              }>
              <label class="radio-label encounter">
                <svg width="15" height="15" class="radio-off">
                  <image xlink:href="${radioOffSVG}" width="15" height="15" />
                </svg>
                <svg width="15" height="15" class="radio-on">
                  <image xlink:href="${radioOnSVG}" width="15" height="15" />
                </svg>
             </label>
            </div>
            <div class="radio-container">
              Order Based
              <input type="radio" class="order" name="based" value="Order" ${efficiency.orderBase ? 'checked' : ''}>
              <label class="radio-label order">
                <svg width="15" height="15" class="radio-off">
                  <image xlink:href="${radioOffSVG}" width="15" height="15" />
                </svg>
                <svg width="15" height="15" class="radio-on">
                  <image xlink:href="${radioOnSVG}" width="15" height="15" />
                </svg>
             </label>
            </div>
          </fieldset>

          <button class="confirm"> Save Settings</button>
        </form>
      </exo-card>
    `;
  }

  renderExoCard({ title, divider, items, card, operation, nameKey }) {
    return `
      <exo-card
        page="workflow"
        class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}">
        <div class="head-section ${card}">
          <exo-card-title title="${title}"></exo-card-title>
        </div>

      
        <div class="divider">
          ${divider.map(d => `<span>${d}</span>`).join('')}
        </div>

        <ul class="items">
          ${items
            .map(
              item =>
                `<exo-card-list-item
                  page="workflow"
                  name="${item.name}"
                  operation="${operation}"
                  id="${item.id}"
                  ${card === 'workflow' ? '' : 'viewOnly'}
                  card="${card}"
                  model="${item.model}"
                  enabled="${item.enabled}"
                  facilityId="${this.selectedFacility}"
                  specialtyId="${this.selectedSpecialty}"
                >
                  ${item[nameKey]}
                </exo-card-list-item>`,
            )
            .join('')}
        </ul>
      </exo-card>
    `;
  }

  renderTabList() {
    return `
      <ul class="tabs">
      <li class="tab ${this.selectedTab === 'ADFS_Oauth2' && 'selected'}">
          <span> ADFS_Oauth2 </span>
          <div class="dot yellow"></div>
        </li>
        <li class="tab ${this.selectedTab === 'Exo Link' && 'selected'}">
          <span> Exo Link </span>
          <div class="dot red"></div>
        </li>
        <li class="tab ${this.selectedTab === 'LDAP Servers' && 'selected'}">
          <span> LDAP Servers </span>
          <div class="dot green"></div>
        </li>
      </ul>
    `;
  }

  async ldap() {
    const ldapResult = await itServiceAPI
      .interfaces()
      .ldap()
      .get();
    return `
      <div class="ldap-container">
        <div class="ldap-container-content">
          <div class="ldap-form">
            <label for="host">
              Host
            </label>
            <input name="host" type="text" value="${ldapResult.host || ''}">
            <label for="port">
              Port (optional)
            </label>
            <input name="port" type="text" placeholder="Port" value="${ldapResult.port || ''}">
            <label for="userSearchBase">
              User search base
            </label>
            <input name="userSearchBase" type="text" value="${ldapResult.userSearchBase || ''}">
            <label for="groupSearchBase">
              Group search base
            </label>
            <input name="groupSearchBase" type="text" value="${ldapResult.groupSearchBase || ''}">
            <label for="serviceUserDn">
              Service user dn
            </label>
            <input name="serviceUserDn" type="text" placeholder="User DN" value="${ldapResult.serviceUserDn || ''}">
            <label for="password">
              Service user password
            </label>
            <input name="serviceUserPassword" type="text" placeholder="Admin Password" value="${
              ldapResult.serviceUserPassword
            }">
            <div class="secure-ldap">
              <exo-toggle 
                card="ldap"
                enabled="${ldapResult.tlsEnabled}"
              ></exo-toggle>
              <div class="toggle-label">
                <span class="label-primary">TLS enabled</span>
                <span class="label-secondary">User authenticated secure TLS connection</span>
              </div>
            </div>
          </div>        
          <input name="id" type="hidden" value="${ldapResult.id}">
        <div class="ssl-wrapper">
          <label for="sslCertificate">
            SSL Certificate
          </label>
          <textarea name="sslCertificate" disabled=${!ldapResult.tlsEnabled}>${ldapResult.sslCertificate}</textarea>
        </div>
        </div>
      </div>
    `;
  }

  async sso() {
    const [ssoResult] = await itServiceAPI
      .interfaces()
      .sso()
      .get();
    const { server, clientId, relyingPartyId } = ssoResult;
    return `
      <div class="sso-container">
        <div class="ldap-form">
          <div class="input-container">
            <label for="server">
              Server
            </label>
            <input name="server" type='text' autocomplete="false" value="${server || ''}">
          </div>
          <div class="input-container">
            <label for="clientId">
              Client ID
            </label>
            <input name="clientId" value="${clientId || ''}">
          </div>
          <div class="input-container">
            <label for="relyingPartyId">
              Relying Party ID
            </label>
            <input name="relyingPartyId" value="${relyingPartyId || ''}">
          </div>
        </div>
        <input name="id" name="id" type="hidden" value="${ssoResult.id}">
      </div>
    `;
  }

  renderRequestIcon(message) {
    return `<div class="feedback-container">${
      this._data.verify_dicom === undefined
        ? ``
        : this._data.verify_dicom
        ? `<svg width="15" height="15" class="radio-off">
    <image xlink:href="${successSVG}" width="15" height="15" />
  </svg> ${message ? '<span class="feedback success">Success</span>' : ''}`
        : ` 
  <svg width="15" height="15" class="radio-off">
    <image xlink:href="${errorSVG}" width="15" height="15" />
  </svg> ${message ? '<span class="feedback error">Failure</span>' : ''}`
    }</div>`;
  }

  async exoLink() {
    const exoLink = await itServiceAPI
      .interfaces()
      .exoLink()
      .get();

    return `
      <div class="dicom-container">
        <div class="dicom-store">
          <div class="dicom-title">Exo Link</div>
          <div>
            <label for="dicomIpAddress" class="label-disabled">
              DICOM IP Address
            </label>
            <br />
            <input name="dicomIpAddress" value="${exoLink.dicomHost}" disabled>
            ${this.renderRequestIcon()}
            <br />
            <label for="dicomPort" class="label-disabled">
              DICOM Port
            </label>
            <br />
            <input type="number" name="dicomPort" value="${exoLink.dicomPort}" disabled>
            ${this.renderRequestIcon()}
            <br />
            <label for="hl7PatientId">
              HL7 Patient ID Type Code (PID-3.5)
            </label>
            <br />
            <input type="string" name="hl7PatientId" value="${exoLink.dicomDefaultPatientIdType}">
            <br />
            <label for="wristbandPatientId">
              Barcode/Wristband Patient ID Type
            </label>
            <br />
            <input type="string" name="wristbandPatientId" value="${exoLink.scannedPatientIdType}">
            <br />
            ${
              /* taking this out until integration is complete */
              false
                ? `<svg width="15" height="15" class="radio-off">
              <image xlink:href="${successSVG}" width="15" height="15" />
            </svg>
            <span class="feedback success"> Success </span>
            <svg width="15" height="15" class="radio-off">
              <image xlink:href="${errorSVG}" width="15" height="15" />
            </svg>
            <span class="feedback error"> Error </span>`
                : ''
            }
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="verify verify-dicom-store">
          DICOM verify Exo Link
        </button>
        ${this.renderRequestIcon(true)}
      </div>
    `;
  }

  async renderSaveButton() {
    try {
      switch (this.selectedTab) {
        case 'LDAP Servers':
          return `<svg class="save" for="ldapServers">
          <image xlink:href="${saveSVG}" />
        </svg>`;
        case 'ADFS_Oauth2':
          return `<svg class="save" for="sso">
          <image xlink:href="${saveSVG}" />
        </svg>`;
        case 'Exo Link':
          return `<svg class="save" for="exoLink">
          <image xlink:href="${saveSVG}" />
        </svg>`;
        default:
          return '';
      }
    } catch {
      return 'There has been an error connecting to the server. Please try again later';
    }
  }

  async renderTabContent() {
    try {
      switch (this.selectedTab) {
        case 'LDAP Servers':
          return await this.ldap();
        case 'ADFS_Oauth2':
          return await this.sso();
        case 'Exo Link':
          return await this.exoLink();
        default:
          return '';
      }
    } catch {
      return 'There has been an error connecting to the server. Please try again later';
    }
  }

  async render() {
    return `
      <div class='interfaces-wrapper'>
        <div class="title">Interfaces
         ${await this.renderSaveButton()}
        </div>
        ${this.renderTabList()}
        <div class="card-container">
          <horizontal-card page="interfaces">
             ${await this.renderTabContent()}
          </horizontal-card>
        </div>
      </div>
    `;
  }
}

customElements.define('interfaces-page', Interfaces);
