const APIService = require('./APIService');
const formatError = require('../../utils/formatError');

class CSVImporter extends APIService {
  constructor() {
    super();
    this._url = `csv-import/`;
  }

  async process(formData) {
    try {
      return await this.httpClient.post(`${this._url}`, formData, {
        'Content-Type': 'multipart/form-data',
      });
    } catch (error) {
      throw new Error(formatError(error));
    }
  }
}

module.exports = CSVImporter;
