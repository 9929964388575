const eventBus = require('../../utils/EventBus');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const { FETCH_SYSTEMS, ERROR, TOAST, SUCCESS } = require('../../events');
const clinicDefinitionAPI = new ClinicDefinitionAPI();

module.exports = async ({ aeTitle, enabled, specialtyId, dicomTag }) => {
  try {
    await clinicDefinitionAPI.ultrasoundSystems().create({
      payload: {
        aeTitle,
        enabled,
        ...(specialtyId ? { specialtyId } : {}),
        ...(dicomTag ? { dicomTag } : {}),
      },
    });
    eventBus.fire(TOAST, {
      items: [
        {
          type: SUCCESS,
          message: 'System Created',
        },
      ],
    });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_SYSTEMS, {
    data: await clinicDefinitionAPI.ultrasoundSystems().get(),
  });
};
