const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const eventBus = require('../../utils/EventBus');

const clinicDefinitionService = new ClinicDefinitionAPI();
const { FETCH_SPECIALITIES, TOAST, ERROR } = require('../../events');

module.exports = async ({ facilityId, specialtyId }) => {
  try {
    await clinicDefinitionService
      .facilities({
        facilityId,
      })
      .specialties({ specialtyId })
      .patch({
        payload: {
          isEnabled: false,
        },
      });
  } catch (error) {
    eventBus.fire(TOAST, {
      items: [
        {
          type: ERROR,
          message: error.message,
        },
      ],
    });
  }
  eventBus.fire(FETCH_SPECIALITIES, {
    data: await clinicDefinitionService
      .facilities({
        facilityId,
      })
      .specialties()
      .get(),
  });
};
