import React from 'react';
import { useParams } from 'react-router-dom';
import CreateUser from './CreateUser';
/**
 *
 */
const EditUser = () => {
  const { userId } = useParams();

  return (
    <div>
      <CreateUser isEdit userId={userId} />
    </div>
  );
};

// #endregion

export default EditUser;
