const UPDATE_MENU_TREE = 'UPDATE_MENU_TREE';
const UPDATE_CONTAINER_VIEW = 'UPDATE_CONTAINER_VIEW';
const UPDATE_CRUD_OPERATION = 'UPDATE_CRUD_OPERATION';
const UPDATE_SELECTED_FACILITY = 'UPDATE_SELECTED_FACILITY';
const UPDATE_SELECTED_SPECIALTY = 'UPDATE_SELECTED_SPECIALTY';
const UPDATE_SELECTED_USER_GROUP = 'UPDATE_SELECTED_USER_GROUP';
const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER';
const UPDATE_SELECTED_TAG = 'UPDATE_SELECTED_TAG';
const UPDATE_SELECTED_EXAM_TYPE = 'UPDATE_SELECTED_EXAM_TYPE';
const UPDATE_SELECTED_CATEGORY = 'UPDATE_SELECTED_CATEGORY';
const UPDATE_SELECTED_WORKSHEET = 'UPDATE_SELECTED_WORKSHEET';
const UPDATE_FACILITY = 'UPDATE_FACILITY';
const UPDATE_SPECIALITY = 'UPDATE_SPECIALITY';
const TOGGLE_MODAL = 'TOGGLE_MODAL';
const TOGGLE_CONTEXTUAL_MENU = 'TOGGLE_CONTEXTUAL_MENU';

const FETCH_FACILITIES = 'FETCH_FACILITES';
const FETCH_EXAM_TYPES = 'FETCH_EXAM_TYPES';
const FETCH_SPECIALTY_EXAM_TYPES = 'FETCH_SPECIALTY_EXAM_TYPES';
const FETCH_ANNOTATIONS = 'FETCH_ANNOTATIONS';
const FETCH_TAGS = 'FETCH_TAGS';
const FETCH_EFFICIENCY = 'FETCH_EFFICIENCY';
const UPDATE_EFFICIENCY = 'UPDATE_EFFICIENCY';
const FETCH_SPECIALITIES = 'FETCH_SPECIALITIES';
const FETCH_SYSTEMS = 'FETCH_SYSTEMS';
const FETCH_CREDENTIALS = 'FETCH_CREDENTIALS';
const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS';
const FETCH_LDAP_GROUPS = 'FETCH_LDAP_GROUPS';
const FETCH_LDAP_GROUP_USERS = 'FETCH_LDAP_GROUP_USERS';
const FETCH_LICENSES = 'FETCH_LICENSES';
const FETCH_AUTHENTICATION_METHODS = 'FETCH_AUTHENTICATION_METHODS';
const FETCH_LOGIN_PARAMETERS = 'FETCH_LOGIN_PARAMETERS';
const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
const FETCH_BILLING_CODES = 'FETCH_BILLING_CODES';
const FETCH_SPECIALTY_USER_GROUPS = 'FETCH_SPECIALTY_USER_GROUPS';
const FETCH_SPECIALTY_TAGS = 'FETCH_SPECIALTY_TAGS';
const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
const FETCH_WORKSHEETS = 'FETCH_WORKSHEETS';
const FETCH_FACILITY_TIMEZONES = 'FETCH_FACILIY_TIMEZONES';
const FETCH_INSIGHTS = 'FETCH_INSIGHTS';
const UPDATE_INSIGHT = 'UPDATE_INSIGHT';
const PAGINATE = 'PAGINATE';
const PAGINATE_MEMBERS_USERS = 'PAGINATE_MEMBERS_USERS';
const PAGINATE_CREDENTIALS_USERS = 'PAGINATE_CREDENTIALS_USERS';
const PAGINATE_QA_USERS = 'PAGINATE_QA_USERS';
const VERIFY_DICOM = 'VERIFY_DICOM';
const TOAST = 'TOAST';
const ERROR = 'ERROR';
const WARNING = 'WARNING';
const SUCCESS = 'SUCCESS';
const IMPORT_CSV = 'IMPORT_CSV';
const CACHE_US_UPDATE = 'CACHE_US_UPDATE';
const CACHE_EXAMTYPE_UPDATE = 'CACHE_EXAMTYPE_UPDATE';
const CACHE_WORKSHEET_UPDATE = 'CACHE_WORKSHEET_UPDATE';
const CACHE_SAVE = 'CACHE_SAVE';

module.exports = {
  UPDATE_MENU_TREE,
  UPDATE_CONTAINER_VIEW,
  UPDATE_CRUD_OPERATION,
  UPDATE_SELECTED_USER_GROUP,
  UPDATE_SELECTED_FACILITY,
  UPDATE_SELECTED_USER,
  UPDATE_SELECTED_SPECIALTY,
  UPDATE_SELECTED_CATEGORY,
  UPDATE_EFFICIENCY,
  UPDATE_SELECTED_TAG,
  UPDATE_SPECIALITY,
  UPDATE_FACILITY,
  UPDATE_SELECTED_EXAM_TYPE,
  UPDATE_SELECTED_WORKSHEET,
  TOGGLE_MODAL,
  TOGGLE_CONTEXTUAL_MENU,
  FETCH_FACILITIES,
  FETCH_SPECIALITIES,
  FETCH_USER_GROUPS,
  FETCH_LDAP_GROUPS,
  FETCH_LDAP_GROUP_USERS,
  FETCH_ANNOTATIONS,
  FETCH_PERMISSIONS,
  FETCH_EFFICIENCY,
  FETCH_TAGS,
  FETCH_BILLING_CODES,
  FETCH_CREDENTIALS,
  FETCH_SYSTEMS,
  FETCH_EXAM_TYPES,
  FETCH_SPECIALTY_EXAM_TYPES,
  FETCH_LICENSES,
  FETCH_AUTHENTICATION_METHODS,
  FETCH_LOGIN_PARAMETERS,
  FETCH_SPECIALTY_USER_GROUPS,
  FETCH_SPECIALTY_TAGS,
  FETCH_ALL_USERS,
  FETCH_WORKSHEETS,
  FETCH_FACILITY_TIMEZONES,
  FETCH_INSIGHTS,
  UPDATE_INSIGHT,
  IMPORT_CSV,
  PAGINATE,
  PAGINATE_MEMBERS_USERS,
  PAGINATE_CREDENTIALS_USERS,
  PAGINATE_QA_USERS,
  VERIFY_DICOM,
  TOAST,
  ERROR,
  WARNING,
  SUCCESS,
  CACHE_US_UPDATE,
  CACHE_EXAMTYPE_UPDATE,
  CACHE_WORKSHEET_UPDATE,
  CACHE_SAVE,
};
