const mainView = require('./views/main');
const validateView = require('./views/validate');

const routes = {
  '/': mainView,
  '/create-user': mainView,
  '/verify': validateView,
};

const editUserRegex = /\/edit-user\/([a-zA-Z0-9_]+)\/?$/;
if (window.location.pathname.includes('edit-user') && !!editUserRegex.exec(window.location.pathname))
  routes[editUserRegex.exec(window.location.pathname)[0]] = mainView;

module.exports = routes;
