const APIService = require('../APIService');
const formatError = require('../../../utils/formatError');

class DicomVerify extends APIService {
  constructor() {
    super();
    this._url = `/it-setup/exo-link-settings/pacs/current?validate&tls`;
  }

  async fetch() {
    try {
      return await this.httpClient.get(this._url);
    } catch (error) {
      throw new Error(formatError(error));
    }
  }
}

module.exports = DicomVerify;
