import React from 'react';
import { Box } from '@chakra-ui/react';

// HOC for styles library
const Paper = ({ children, borderRadius = 'lg', overflow = 'hidden', ...rest }) => {
  return (
    <Box borderWidth="1px" borderRadius={borderRadius} overflow={overflow} p={6} {...rest}>
      {children}
    </Box>
  );
};

export default Paper;
