const disableSpecialty = require('./disableSpecialty');

module.exports = ({ values, card }) => {
  switch (card) {
    case 'specialities': {
      disableSpecialty({
        facilityId: values.facilityId,
        specialtyId: values.specialtyId,
      });
      break;
    }
  }
};
