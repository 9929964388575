const { ITEMS_PER_PAGE } = require('../../../utils/constants');
const APIService = require('../APIService');

class Users extends APIService {
  constructor({ groupId, id }) {
    super();
    this._usingGroup = groupId;
    this._url = `/access-control/user-categories/${groupId}/users${id ? `/${id}` : ''}`;
    this._usersUrl = `/access-control/users${id ? `/${id}` : ''}`;
  }

  async create({ payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._usersUrl}`, payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async addToGroup({ specialtyId, id }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}`, {
        specialty_id: specialtyId,
        user_id: id,
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async update({ payload }) {
    try {
      const { data } = await this.httpClient.patch(this._usersUrl, payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async get({ parameters }) {
    try {
      const { data } = await this.httpClient.get(
        this._usingGroup
          ? `${this._url}/?ordering=last_name&specialtyId=${parameters.specialtyId}&offset=${parameters.offset ||
              0}&limit=${parameters.limit || ITEMS_PER_PAGE}`
          : this._usersUrl,
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async delete() {
    try {
      const { data } = await this.httpClient.delete(this._usingGroup ? this._url : this._usersUrl);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAll({ parameters }) {
    try {
      const { data } = await this.httpClient.get(
        `${this._usersUrl}?offset=${parameters.offset || 0}&limit=${parameters.limit || ITEMS_PER_PAGE}`,
      );
      return { results: data.results, count: data.count };
    } catch (error) {
      throw new Error(error);
    }
  }
}

module.exports = Users;
