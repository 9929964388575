const jwtDecode = require('jwt-decode');

module.exports = async ({ token, APIClient }) => {
  try {
    const jwtObj = jwtDecode(token);
    return await APIClient.getAccessControl({ id: jwtObj.user_id });
  } catch (error) {
    throw new Error(`Error verifying user: ${error}`);
  }
};
