const checkIfSafari = require('./isSafari');
const REFRESHED = 'reload';
const BACK_FORWARD = 'back_forward';
const NAVIGATE = 'navigate';

const isSafari = checkIfSafari();
const instance = require('./axios');
const getWebUrl = require('./getWebUrl');
const checkIfFromEmail = require('./checkIfFromEmail');
const eventBus = require('./EventBus');
const { UPDATE_CONTAINER_VIEW } = require('../events');
const { getAuthMethod, getAdfsSettings, getAdminLogin, removeAdminLogin } = require('./authStorage');

const checkIfRefreshed = () => {
  if (isSafari) {
    switch (window.performance.navigation.type) {
      case 0:
        return NAVIGATE;
      case 1:
        return REFRESHED;
      case 2:
        return BACK_FORWARD;
    }
  } else {
    const performanceObject = performance.getEntriesByType('navigation')[0];
    switch (performanceObject.type) {
      case REFRESHED:
        return REFRESHED;
      case BACK_FORWARD:
        return BACK_FORWARD;
      case NAVIGATE:
        return NAVIGATE;
    }
  }
};

const getSSOLogoutURL = (server, token) =>
  `https://${server}/adfs/oauth2/logout?post_logout_redirect_uri=${getRedirectURI()}&id_token_hint=${token}`;

const handleRedirectLogout = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('navigate');
  const adminLogin = getAdminLogin();
  if (checkIfRefreshed() !== REFRESHED) {
    if (checkIfRefreshed() === BACK_FORWARD || (checkIfRefreshed() === NAVIGATE && !Boolean(myParam))) {
      const authMethod = getAuthMethod();
      const adfs = JSON.parse(getAdfsSettings());
      const SSO = 'ADFS_OAUTH2';
      const SAML = 'SAML';
      if (authMethod === SAML && !adminLogin) {
        window.location.href = `https://cloud-hcs.${process.env.REACT_APP_ENV}.${process.env.REACT_APP_URL_SUFFIX}/api/v1/auth/saml/slo`;
      } else if (authMethod === SSO && adfs && !adminLogin) {
        const token = localStorage.getItem('id_token');
        localStorage.removeItem('id_token');
        instance
          .post('auth/logout/')
          .then(res => {
            if (token) {
              window.location.href = getSSOLogoutURL(adfsSettings.server, token);
            }
            return res;
          })
          .catch(err => err);
      } else {
        removeAdminLogin();
        instance
          .post('auth/logout/')
          .then(res => {
            if (checkIfFromEmail()) {
              window.location.href = `${getWebUrl()}?fromemail=true`;
            } else {
              window.location.href = getWebUrl();
            }
            return res;
          })
          .catch(err => err);
      }
    } else if (Boolean(myParam)) {
      if (urlParams.get('redirect') === 'ussystems') {
        eventBus.fire(UPDATE_CONTAINER_VIEW, { view: 'U/S Systems' });
      }
      history.pushState(null, '', location.href.split('?')[0]);
    }
  }
};

module.exports = handleRedirectLogout;
