import { Thead, Th, Tr } from '@chakra-ui/react';
import React from 'react';

export const TableHeader = ({ columns }) => {
  return (
    <Thead>
      <Tr>
        {columns.map((column) => (
          <Th
            key={`table-header-${column.title}`}
            sx={{
              height: '21px',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '17.64px',
              letterSpacing: '0.23px',
              color: 'rgba(44, 44, 46, 0.5)',
              textTransform: 'none',
              borderBottom: 'none',
              backgroundColor: 'transparent',
              paddingLeft: '20px',
              fontFamily: 'Helvetica',
            }}
          >
            {column.title}
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};
